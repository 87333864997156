import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { apiResetPassword } from "../../../datasource/contactRepo";
import Swal from 'sweetalert2'
import { useFormik } from "formik";
import * as Yup from "yup";

function ResetPassword(props) {
  const [password, setPassword] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  let history = useHistory()

  const savePassword = (values, setStatus, setSubmitting) => {
    const token = props.match.params.token
    console.log(values)
    const payload = {
      newPassword : values.password
    }
    setIsLoading(true);
    apiResetPassword(payload, token)
      .then((res) => {
        console.log(res)
        Swal.fire(
          'Success',
          'password has been reset!',
          'success'
        )
        history.push('/auth/login')
      })
      .catch((err) => {
        Swal.fire(
          'Reset password Error!',
          'message:'+err.response?.data?.error,
          'error'
        )
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initialValues = {
    password: "",
    cPassword: "",
  };

  const Schema = Yup.object().shape({
    password: Yup.string().required("New Password is required"),
    cPassword: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      savePassword(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot shadow-sm p-3 mb-5 bg-body rounded" 
            style={{ 
              display: "block",
              background: "#dddd",
              padding: "20px",
              boxSizing: "border-box",
              borderRadius: "5px",
            }}>
          <div className="text-center mb-7">
            <h3 className="font-size-h1">Reset your Password</h3>
            <div className="">
              Enter your new Password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group row mb-1">
            <label className="col-lg-12 col-form-label text-alert">
              New Password
            </label>
            <div className="col-lg-12">
              <input
                type="password"
                placeholder="New Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-12 col-form-label text-alert">
              Verify Password
            </label>
            <div className="col-lg-12">
              <input
                type="password"
                placeholder="Verify Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "cPassword"
                )}`}
                name="cPassword"
                {...formik.getFieldProps("cPassword")}
              />
              {formik.touched.cPassword && formik.errors.cPassword ? (
                <div className="invalid-feedback">
                  {formik.errors.cPassword}
                </div>
              ) : null}
            </div>
          </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isLoading}
              >
                Submit
                {isLoading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
