import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  postalcode: null,
  country: null
}

const postalcodeRequest = state => ({
  ...state,
  isLoading: true
})
const postalcodeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  postalcode: payload
})
const postalcodeError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const postalcodeReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  postalcode: null
})

const countryRequest = state => ({
  ...state,
  isLoading: true
})
const countrySuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  country: payload
})
const countryError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const countryReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  country: null
})

export const contactReducer = (state = initialState, action) => {
  switch (action.type ) {
  
    case actionType.CONTACT.POSTALCODE_REQUEST:
      return postalcodeRequest(state, action.payload);
    case actionType.CONTACT.POSTALCODE_SUCCESS:
      return postalcodeSuccess(state, action.payload);
    case actionType.CONTACT.POSTALCODE_ERROR:
      return postalcodeError(state, action.payload);
    case actionType.CONTACT.POSTALCODE_RESET:
      return postalcodeReset(state, action.payload);

    case actionType.CONTACT.COUNTRY_REQUEST:
      return countryRequest(state, action.payload);
    case actionType.CONTACT.COUNTRY_SUCCESS:
      return countrySuccess(state, action.payload);
    case actionType.CONTACT.COUNTRY_ERROR:
      return countryError(state, action.payload);
    case actionType.CONTACT.COUNTRY_RESET:
      return countryReset(state, action.payload);
  
    default:
      return state;
  }
}