import React from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

export function ModalConfirmTimesheet({show, onHide, onConfirm, onCancel}){

  function handleSubmit(event){
    event.preventDefault();
    onConfirm()
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header 
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <label style={{textAlign: 'center', display: 'flex'}}>Confirmation</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="text-center">You are not registered for this session, click Sign Up to register or click Cancel to correct your email address</Form.Label>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'col-lg-12 d-flex justify-content-center'}>
            <Col md='auto'>
              <Button type="submit" style={{minWidth: '10rem'}} variant="primary">Sign Up</Button>
            </Col>
            <Col md='auto'>
              <Button onClick={onHide} style={{minWidth: '10rem'}} variant="danger">Cancel</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}