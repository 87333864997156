import { methodService, apiService } from './apiService';

export const URL = {
  AUTH_LOGIN: '/contacts/login',
  AUTH_FORGOT: '/contacts/resetPasswordRequest'
}

export function apiLogin(data) {
  return apiService(
    URL.AUTH_LOGIN,
    methodService.POST,
    data,
    null
  )
}

export function apiForgot(data) {
  return apiService(
    URL.AUTH_FORGOT,
    methodService.PATCH,
    data,
    null
  )
}