import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from 'react-bootstrap';
import { BasicInfoForm } from "../form/BasicInfoForm";
import * as peAction from "../redux/peAction";
import { LoadingCard } from "../../../component/LoadingCard";
import moment from "moment";

// import { ModalInfoPE } from "../modal/ModalInfoPE";

export function ProgrammeEventsDetailPage(){
  const dispatch = useDispatch();
  const peReducer = useSelector(state => state.pe, shallowEqual);
  const dataPE = useSelector(state => state.pe.peDetail?.data?.programmeEvent);
  const dataParticipant = useSelector(state => state.pe.peDetail?.data?.participant);
  const [assignmentStatus, setAssignmentStatus] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    type: "",
    message: "",
    show: false,
    onConfirm: ()=>null
  })
  
  useEffect(() => {
    let payload = {
      Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)
    }
    dispatch(peAction.peDetailRequest(payload));

    return(
      ()=> {
        dispatch(peAction.peDetailReset());
      }
    )
  }, [])

  useEffect(() => {
    if(peReducer.isLoading === false && (peReducer.peRegister !== null || peReducer.peCancel !== null) ){
      if(peReducer.peRegister !== null){
        setModalInfo({
          ...modalInfo,
          type: "info",
          message: "You are successfully registered! We will contact you soon.",
          show: true,
          onConfirm: ()=>null
        })
      }
      // dispatch(peAction.peRegisterReset());
      // dispatch(peAction.peCancelReset());
    }
  }, [peReducer])

  function registerProgramme(){
    // dispatch(peAction.peRegisterRequest({Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)}));
  }

  function cancelWithdrawProgramme(isWithdraw){
    setModalInfo({
      ...modalInfo,
      type: "confirm",
      message: "We are sorry that you are not able to join us for the activity. We hope to see you again soon.",
      show: true,
      onConfirm: ()=>{
        setModalInfo({
          ...modalInfo,
          show: false
        })
        // dispatch(peAction.peCancelRequest({Id: dataParticipant[0]?.Id}));
      }
    })
  }

  function isAbleToRegis(eventDate){
    function addWeekdays(date, days) {
      date = moment(date); // use a clone
      while (days > 0) {
        date = date.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return date;
    }
    const minimalEventDate = addWeekdays(moment(), 3).toString();
    return moment(eventDate).isAfter(minimalEventDate, 'day')
  }

  
  

  return(
    <>
      <Card>
        {
          dataPE ?
          <CardBody>
            {/* {
              <div style={{
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center'
              }}>
                <Button 
                  variant={
                    dataParticipant.length === 0 ? 'success' : 
                    dataParticipant[0]?.Status__c === "Selected" ||
                    dataParticipant[0]?.Status__c === "Invitation Sent" ||
                    dataParticipant[0]?.Status__c === "Cancel" ?
                    "success" : "secondary"
                  } 
                  style={{ width: '14%', marginRight: 10 }}
                  onClick={()=>{
                    dataParticipant[0]?.Status__c === "Selected" || 
                    dataParticipant[0]?.Status__c === "Invitation Sent" ||
                    dataParticipant[0]?.Status__c === "Cancel" ||
                    dataParticipant[0]?.Id === undefined ?
                    registerProgramme() :
                    dataPE.Programme_Stage__c === "Started" ?
                    cancelWithdrawProgramme(true) : cancelWithdrawProgramme(false)
                  }}
                  disabled={peReducer.isLoading}
                >
                    {
                      dataParticipant.length === 0 ? 'Register' : 
                      dataParticipant[0]?.Status__c === "Selected" || 
                      dataParticipant[0]?.Status__c === "Invitation Sent" ||
                      dataParticipant[0]?.Status__c === "Cancel" ? "Register" : 
                      dataPE.Programme_Stage__c === "Started" ? "Withdraw" : "Cancel"
                    }
                    {
                      peReducer.isLoading &&
                      <span className="ml-3 spinner spinner-white"></span>
                    }
                </Button>
                {
                  peReducer.errorMsg !== null &&
                  <span className="text-danger">{peReducer.errorMsg?.message}</span>
                }
              </div>
            } */}
            <div style={{
              flexDirection: 'row',
              justifyContent: 'center',
              display: 'flex',
              alignSelf: 'center',
              alignItems: 'center'
            }}>
              <Button 
                variant={
                  assignmentStatus === null || assignmentStatus === 'Withdraw' ? 'primary' : 'secondary'
                } 
                style={{ width: '14%', marginRight: 10 }}
                onClick={()=>{
                  if(assignmentStatus === null || assignmentStatus === 'Withdraw'){
                    setAssignmentStatus('Accepted');
                  } else if(assignmentStatus === 'Accepted'){
                    setAssignmentStatus('Withdraw');
                  }
                }}
                disabled={!isAbleToRegis(assignmentStatus === null || assignmentStatus === 'Withdraw' && dataPE?.Start_Date_Time__c)}
              >
                {
                  assignmentStatus === null || assignmentStatus === 'Withdraw' ? "Register" : "Withdraw"
                }
              </Button>
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={()=>null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#basic-info"
                >
                  Basic Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <BasicInfoForm Assignment_Status__c={assignmentStatus}/>
            </div>
          </CardBody>
          : 
          <LoadingCard/>
        }
        {/* <ModalInfoPE 
          show={modalInfo.show}
          message={modalInfo.message}
          type={modalInfo.type}
          onHide={()=>setModalInfo({...modalInfo, show: false})}
          onConfirm={modalInfo.onConfirm}
        /> */}
      </Card>
    </>
  )
}