import React, { useEffect, useState } from 'react';
import { Card } from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import styled from "@emotion/styled";
import moment from "moment";
import * as peAction from "../../ProgrammeEvents/redux/peAction";
import * as vrAction from "../../VolunteerRequest/redux/vrAction";
import "./Calendar.css";

export function CalendarCard(){
  const dispatch = useDispatch();
  const history = useHistory();
  const peReducer = useSelector(state => state.pe, shallowEqual);
  const authReducer = useSelector(state => state.auth);
  const vrReducer = useSelector(state => state.vr, shallowEqual);
  const [dataCalendar, setDataCalendar] = useState([]);

  const StyleWrapper = styled.div`
  .fc-header-toolbar {
    margin-bottom: 100 !important;
    z-index: 100;
  }
  .fc-today-button {
    visible: hidden;
  }
`

  useEffect(() => {
    dispatch(peAction.peTableRequest());
    dispatch(vrAction.vrSessionCalendarRequest({
      volunteerType: authReducer.user.volunteerType
    }));
    dispatch(vrAction.vrTableRequest({
      volunteerType: authReducer.user.volunteerType
    }));
  }, [])

  useEffect(() => {
    let dataEvent = [];
    let dataPe = [];
    let dataPeTable = [];
    let dataVrTable = [];
    let dataSession = [];
    let dataSessionRegistered = [];
    let dataSessionUnregistered = [];
    let finalData = [];
    if(
        vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrSessionCalendar !== null && vrReducer.vrTable !== null &&
        peReducer.isLoading === false && peReducer.isError === false && peReducer.peTable !== null
      ){
        dataVrTable = [...vrReducer.vrTable.data.volunteerRequests].map((item) => {
          if(item.RecordType.Name === "Regular"){
            let _newData = {
              id: moment().valueOf() + getRandomInt(0, 1000),
              sfId: item.Id,
              date: item.Start_Date__c,
              title: item.Name,
              session: null,
              numberSession: 0,
              start_time: moment.utc(item.Start_Date__c+' '+item.Start_Time__c),
              end_time: moment.utc(item.End_Date__c+' '+item.End_Time__c),
              backgroundColor: '#036668',
              calendarType: 'VR'
            }
            return _newData;
          }
        }).filter(ar => ar !== undefined);
        dataSessionRegistered = vrReducer?.vrSessionCalendar?.data?.registred === null ? [] : [...vrReducer?.vrSessionCalendar?.data?.registred].map((item) => {
          let _newData = {
            id: moment().valueOf() + getRandomInt(0, 1000),
            sfId: item.Id,
            date: item.Session_Date__c,
            title: item.Session_Title__c,
            session: item.Session_Shift__c === "Morning" ? "morning" : "afternoon",
            numberSession: `${item.Session_Shift__c === "Morning" ? 0 : 1}`,
            start_time: moment.utc(item.Session_Date__c+' '+item.Start_Time__c),
            end_time: moment.utc(item.Session_Date__c+' '+item.End_Time__c),
            schedule_type: item.Session_Type__c === "Regular" ? 'regular' : 'adhoc',
            volunteer_type: item.Volunteer_Type__c === "Individual" ? 'individual' : 'organisation',
            volunteer_loc: item.Volunteer_Location__c,
            textColor: "white",
            assignedVolunteer: 0,
            borderColor: 'red',
            numberVolunteer: item.Max_Num_of_Volunteer__c,
            backgroundColor: '#036668',
            calendarType: 'VS',
            className: 'moreBorder'
          }
          return _newData;
        })
        dataSessionUnregistered = vrReducer?.vrSessionCalendar?.data?.unregistred === null ? [] : [...vrReducer?.vrSessionCalendar?.data?.unregistred].map((item) => {
          let _newData = {
            id: moment().valueOf() + getRandomInt(0, 1000),
            sfId: item.Id,
            date: item.Session_Date__c,
            title: item.Session_Title__c,
            session: item.Session_Shift__c === "Morning" ? "morning" : "afternoon",
            numberSession: `${item.Session_Shift__c === "Morning" ? 0 : 1}`,
            start_time: moment.utc(item.Session_Date__c+' '+item.Start_Time__c),
            end_time: moment.utc(item.Session_Date__c+' '+item.End_Time__c),
            schedule_type: item.Session_Type__c === "Regular" ? 'regular' : 'adhoc',
            volunteer_type: item.Volunteer_Type__c === "Individual" ? 'individual' : 'organisation',
            volunteer_loc: item.Volunteer_Location__c,
            textColor: "white",
            assignedVolunteer: 0,
            numberVolunteer: item.Max_Num_of_Volunteer__c,
            backgroundColor: '#036668',
            calendarType: 'VS'
          }
          return _newData;
        })
        dataPeTable = [...peReducer.peTable.data.programmeEvents].map((item) => {
          let _newData = {
            id: moment().valueOf() + getRandomInt(0, 1000),
            sfId: item.Id,
            date: moment(item.Start_Date_Time__c).format("YYYY-MM-DD"),
            title: item.Name,
            session: item.Session_Shift__c === "Morning" ? "morning" : "afternoon",
            textColor: "white",
            backgroundColor: `blue`,
            calendarType: 'PE'
          }
          return _newData;
        });
        finalData = dataSession.concat(dataVrTable, dataPeTable, dataSessionRegistered, dataSessionUnregistered);
        setDataCalendar(finalData);
    }
  }, [vrReducer, peReducer])

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleTooltipOpen(props) {
    if(props?.extendedProps?.calendarType === 'VR'){
      history.push(`/volunteer-request/detail/regular/${props?.extendedProps?.sfId}`)
    } else if(props?.extendedProps?.calendarType === 'VS'){
      history.push(`/volunteer-request/detail/fp/session/${props?.extendedProps?.sfId}`);
    } else if(props?.extendedProps?.calendarType === 'PE'){
      history.push(`/programme-events/detail/${props?.extendedProps?.sfId}`);
    }
  }

  const LegendCalendar = ({color, name, borderColor}) => {
    return(
      <div className="row d-flex align-items-center" style={{width: "30%"}}>
        <div 
          style={{
            width: 10,
            height: 10,
            borderRadius: 3,
            backgroundColor: color,
            marginRight: 5,
            border: borderColor ? `1px solid ${borderColor}` : ''
          }}
        />
        {name}
      </div>
    )
  }

  return(
    <>
      <Card>
        <div className="p-10">
          <div className="ml-5 xl-12 lg-12 d-none d-sm-block d-sm-none d-md-block" style={{position: "absolute", left: 230, width: "100%"}}>
            <div className="row" style={{width: "100%"}}>
              <LegendCalendar name={"Registered Activities"} color={"#036668"} borderColor={"red"}/>
              <LegendCalendar name={"Upcoming Activities"} color={"blue"}/> 
            </div>
            <div className="row" style={{width: "100%"}}>
              <LegendCalendar name={"Volunteer Opportunities"} color={"#036668"}/>  
            </div>
          </div>
          <StyleWrapper>
            <FullCalendar
              plugins={[ dayGridPlugin ]}
              initialView="dayGridMonth"
              firstDay={1}
              events={dataCalendar}
              views={{
                dayGridMonth: {
                  dayMaxEventRows: 3,
                },
              }}
              headerToolbar={{
                left: 'title',
                right: 'prev next'
              }}
              eventClick={(arg) => {
                handleTooltipOpen(arg.event);
              }}
              // datesSet={(d)=>{
              //   getSessionCalendar(moment(d.start).format('YYYY'), parseInt(moment(d.start).format("M"))+1);
              // }}
              eventOrder={["status"]}
            />
          </StyleWrapper>
        </div>
      </Card>
    </>
  )
}