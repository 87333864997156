import * as actionType from './actionType';

export const vrTableRequest = data => ({
  type: actionType.VR.TABLE_REQUEST,
  payload: data
})
export const vrTableSuccess = data => ({
  type: actionType.VR.TABLE_SUCCESS,
  payload: data
})
export const vrTableError = data => ({
  type: actionType.VR.TABLE_ERROR,
  payload: data
})
export const vrTableReset = data => ({
  type: actionType.VR.TABLE_RESET,
  payload: data
})

export const vrTableAdminRequest = data => ({
  type: actionType.VR.TABLE_ADMIN_REQUEST,
  payload: data
})
export const vrTableAdminSuccess = data => ({
  type: actionType.VR.TABLE_ADMIN_SUCCESS,
  payload: data
})
export const vrTableAdminError = data => ({
  type: actionType.VR.TABLE_ADMIN_ERROR,
  payload: data
})
export const vrTableAdminReset = data => ({
  type: actionType.VR.TABLE_ADMIN_RESET,
  payload: data
})

export const vrDetailRequest = data => ({
  type: actionType.VR.DETAIL_REQUEST,
  payload: data
})
export const vrDetailSuccess = data => ({
  type: actionType.VR.DETAIL_SUCCESS,
  payload: data
})
export const vrDetailError = data => ({
  type: actionType.VR.DETAIL_ERROR,
  payload: data
})
export const vrDetailReset = data => ({
  type: actionType.VR.DETAIL_RESET,
  payload: data
})

export const vrRegisterRequest = data => ({
  type: actionType.VR.REGISTER_REQUEST,
  payload: data
})
export const vrRegisterSuccess = data => ({
  type: actionType.VR.REGISTER_SUCCESS,
  payload: data
})
export const vrRegisterError = data => ({
  type: actionType.VR.REGISTER_ERROR,
  payload: data
})
export const vrRegisterReset = data => ({
  type: actionType.VR.REGISTER_RESET,
  payload: data
})

export const vrUploadAssignedOrgRequest = data => ({
  type: actionType.VR.UPLOAD_ASSIGNED_ORG_REQUEST,
  payload: data
})
export const vrUploadAssignedOrgSuccess = data => ({
  type: actionType.VR.UPLOAD_ASSIGNED_ORG_SUCCESS,
  payload: data
})
export const vrUploadAssignedOrgError = data => ({
  type: actionType.VR.UPLOAD_ASSIGNED_ORG_ERROR,
  payload: data
})
export const vrUploadAssignedOrgReset = data => ({
  type: actionType.VR.UPLOAD_ASSIGNED_ORG_RESET,
  payload: data
})

export const vrDeleteAssignedOrgRequest = data => ({
  type: actionType.VR.DELETE_ASSIGNED_ORG_REQUEST,
  payload: data
})
export const vrDeleteAssignedOrgSuccess = data => ({
  type: actionType.VR.DELETE_ASSIGNED_ORG_SUCCESS,
  payload: data
})
export const vrDeleteAssignedOrgError = data => ({
  type: actionType.VR.DELETE_ASSIGNED_ORG_ERROR,
  payload: data
})
export const vrDeleteAssignedOrgReset = data => ({
  type: actionType.VR.DELETE_ASSIGNED_ORG_RESET,
  payload: data
})

export const vrWithdrawRequest = data => ({
  type: actionType.VR.WITHDRAW_REQUEST,
  payload: data
})
export const vrWithdrawSuccess = data => ({
  type: actionType.VR.WITHDRAW_SUCCESS,
  payload: data
})
export const vrWithdrawError = data => ({
  type: actionType.VR.WITHDRAW_ERROR,
  payload: data
})
export const vrWithdrawReset = data => ({
  type: actionType.VR.WITHDRAW_RESET,
  payload: data
})

export const vrSessionRequest = data => ({
  type: actionType.VR.SESSION_REQUEST,
  payload: data
})
export const vrSessionSuccess = data => ({
  type: actionType.VR.SESSION_SUCCESS,
  payload: data
})
export const vrSessionError = data => ({
  type: actionType.VR.SESSION_ERROR,
  payload: data
})
export const vrSessionReset = data => ({
  type: actionType.VR.SESSION_RESET,
  payload: data
})

export const vrSessionCalendarRequest = data => ({
  type: actionType.VR.SESSION_CALENDAR_REQUEST,
  payload: data
})
export const vrSessionCalendarSuccess = data => ({
  type: actionType.VR.SESSION_CALENDAR_SUCCESS,
  payload: data
})
export const vrSessionCalendarError = data => ({
  type: actionType.VR.SESSION_CALENDAR_ERROR,
  payload: data
})
export const vrSessionCalendarReset = data => ({
  type: actionType.VR.SESSION_CALENDAR_RESET,
  payload: data
})

export const vrSessionCalendarAdminRequest = data => ({
  type: actionType.VR.SESSION_CALENDAR_ADMIN_REQUEST,
  payload: data
})
export const vrSessionCalendarAdminSuccess = data => ({
  type: actionType.VR.SESSION_CALENDAR_ADMIN_SUCCESS,
  payload: data
})
export const vrSessionCalendarAdminError = data => ({
  type: actionType.VR.SESSION_CALENDAR_ADMIN_ERROR,
  payload: data
})
export const vrSessionCalendarAdminReset = data => ({
  type: actionType.VR.SESSION_CALENDAR_ADMIN_RESET,
  payload: data
})

export const vrSessionDetailRequest = data => ({
  type: actionType.VR.SESSION_DETAIL_REQUEST,
  payload: data
})
export const vrSessionDetailSuccess = data => ({
  type: actionType.VR.SESSION_DETAIL_SUCCESS,
  payload: data
})
export const vrSessionDetailError = data => ({
  type: actionType.VR.SESSION_DETAIL_ERROR,
  payload: data
})
export const vrSessionDetailReset = data => ({
  type: actionType.VR.SESSION_DETAIL_RESET,
  payload: data
})

export const vrSessionRegisterRequest = data => ({
  type: actionType.VR.SESSION_REGISTER_REQUEST,
  payload: data
})
export const vrSessionRegisterSuccess = data => ({
  type: actionType.VR.SESSION_REGISTER_SUCCESS,
  payload: data
})
export const vrSessionRegisterError = data => ({
  type: actionType.VR.SESSION_REGISTER_ERROR,
  payload: data
})
export const vrSessionRegisterReset = data => ({
  type: actionType.VR.SESSION_REGISTER_RESET,
  payload: data
})

export const vrSessionUploadAssignedOrgRequest = data => ({
  type: actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_REQUEST,
  payload: data
})
export const vrSessionUploadAssignedOrgSuccess = data => ({
  type: actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_SUCCESS,
  payload: data
})
export const vrSessionUploadAssignedOrgError = data => ({
  type: actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_ERROR,
  payload: data
})
export const vrSessionUploadAssignedOrgReset = data => ({
  type: actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_RESET,
  payload: data
})

export const vrSessionDeleteAssignedOrgRequest = data => ({
  type: actionType.VR.SESSION_DELETE_ASSIGNED_ORG_REQUEST,
  payload: data
})
export const vrSessionDeleteAssignedOrgSuccess = data => ({
  type: actionType.VR.SESSION_DELETE_ASSIGNED_ORG_SUCCESS,
  payload: data
})
export const vrSessionDeleteAssignedOrgError = data => ({
  type: actionType.VR.SESSION_DELETE_ASSIGNED_ORG_ERROR,
  payload: data
})
export const vrSessionDeleteAssignedOrgReset = data => ({
  type: actionType.VR.SESSION_DELETE_ASSIGNED_ORG_RESET,
  payload: data
})

export const vrSessionWithdrawRequest = data => ({
  type: actionType.VR.SESSION_WITHDRAW_REQUEST,
  payload: data
})
export const vrSessionWithdrawSuccess = data => ({
  type: actionType.VR.SESSION_WITHDRAW_SUCCESS,
  payload: data
})
export const vrSessionWithdrawError = data => ({
  type: actionType.VR.SESSION_WITHDRAW_ERROR,
  payload: data
})
export const vrSessionWithdrawReset = data => ({
  type: actionType.VR.SESSION_WITHDRAW_RESET,
  payload: data
})