import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import * as vrAction from "../redux/vrAction";
import { LoadingCard } from "../../../component/LoadingCard";
import { BasicInfoForm } from "../form/BasicInfoForm";
import { UploadAssignedVolunteerForm } from "../form/UploadAssignedVolunteerForm";
import { Button } from "react-bootstrap";
import { ModalInfoVr } from "../modal/ModalInfoVr";
import { ModalConfirmVr } from "../modal/ModalConfirmVr";
import "./VRSession.css";

export function VolunteerRequestDetailPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const vrReducer = useSelector((state) => state.vr);
  const [idVr, setIdVr] = useState(null);
  const [vrType, setVrType] = useState(null);
  const [dataVr, setDataVr] = useState(null);
  const [modalInfoVr, setModalInfoVr] = useState({
    show: false,
    type: null, //register || cancel
  });
  const [modalConfirmVr, setModalConfirmVr] = useState({
    show: false,
    message: "",
    type: null,
    onConfirm: () => null,
  });

  useEffect(() => {
    let path = window.location.pathname;
    let _IdVr = path.substring(path.lastIndexOf("/") + 1);
    setIdVr(_IdVr);
    if (path.includes("/volunteer-request/detail/fp")) {
      dispatch(vrAction.vrDetailRequest({ Id: _IdVr }));
      dispatch(vrAction.vrSessionRequest({ Id: _IdVr }));
      setVrType("fp");
    } else if (path.includes("/volunteer-request/detail/regular")) {
      dispatch(vrAction.vrDetailRequest({ Id: _IdVr }));
      setVrType("regular");
    }
    return () => {
      dispatch(vrAction.vrDetailReset());
      dispatch(vrAction.vrSessionReset());
    };
  }, []);

  useEffect(() => {
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrDetail !== null
    ) {
      setDataVr(vrReducer.vrDetail.data.volunteerRequest);
    }
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrRegister !== null
    ) {
      dispatch(vrAction.vrRegisterReset());
      dispatch(vrAction.vrDetailRequest({ Id: idVr }));
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "register",
      });
    }
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrWithdraw !== null
    ) {
      dispatch(vrAction.vrWithdrawReset());
      dispatch(vrAction.vrDetailRequest({ Id: idVr }));
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "withdraw",
      });
    }
  }, [vrReducer]);

  const TableSession = () => {
    const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
      return <span>{cell === null ? "N/A" : cell}</span>;
    };

    const nameColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {cell === null
            ? "N/A"
            : `Food Packing Session ${row.Session_Shift__c} - ${
                row.Number_of_Sign_ups__c
              }/${row.Max_Num_of_Volunteer__c} ${
                row.Session_Type__c === "Adhoc" ? "A" : "R"
              }`}
        </span>
      );
    };

    const dateColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {cell === null ? "N/A" : moment(cell).format("DD-MMM-YYYY")}
        </span>
      );
    };

    const numberColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
      return (
        <span
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {cell === null ? "N/A" : cell}
        </span>
      );
    };

    const columnsVolunteer = [
      {
        dataField: "Session_Title__c",
        text: "Name",
        formatter: nameColumnFormatter,
      },
      {
        dataField: "Session_Date__c",
        text: "Date",
        formatter: dateColumnFormatter,
      },
      {
        dataField: "Max_Num_of_Volunteer__c",
        text: "Total no of required volunteers",
        formatter: numberColumnFormatter,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center" };
        },
      },
      {
        dataField: "Number_of_Sign_ups__c",
        text: "No of sign-up",
        formatter: numberColumnFormatter,
        headerStyle: (colum, colIndex) => {
          return { textAlign: "left" };
        },
      },
    ];

    const columnsAdmin = [
      {
        dataField: "Session_Title__c",
        text: "Name",
      },
      {
        dataField: "Session_Date__c",
        text: "Date",
        formatter: nullColumnFormatter,
      },
      {
        dataField: "Session_Shift__c",
        text: "Shift",
        formatter: nullColumnFormatter,
      },
      {
        dataField: "Volunteer_Location__c",
        text: "Location",
        formatter: nullColumnFormatter,
      },
      {
        dataField: "Session_Status__c",
        text: "Status",
        formatter: nullColumnFormatter,
      },
    ];

    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        history.push(`/volunteer-request/detail/fp/session/${row.Id}`);
      },
    };

    return (
      <>
        {vrReducer.isLoading ? (
          <LoadingCard />
        ) : (
          <>
            <CardHeader>
              <div className="card-title">
                <h3 className="card-label" style={{ minWidth: "9vw" }}>
                  {dataVr !== null ? dataVr[0].Name : ""}
                </h3>
              </div>
            </CardHeader>
            <CardBody>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={
                  vrReducer.vrSession === null
                    ? []
                    : vrReducer.vrSession?.data?.volunteerSessions
                }
                columns={
                  authReducer?.user?.role === "volunteer"
                    ? columnsVolunteer
                    : columnsAdmin
                }
                hover
                selectRow={selectRow}
              ></BootstrapTable>
            </CardBody>
          </>
        )}
      </>
    );
  };

  function headerImageVr() {
    let vrPicture =
      vrReducer?.vrDetail.data?.volunteerRequest[0]?.Session_Picture__c;
    return {
      __html: vrPicture,
    };
  }

  function headerIntroVr() {
    let vrIntro =
      vrReducer?.vrDetail.data?.volunteerRequest[0]?.Introduction__c;
    return {
      __html: vrIntro,
    };
  }

  function isAbleToRegis(eventDate) {
    function addWeekdays(date, days) {
      date = moment(date); // use a clone
      while (days > 0) {
        date = date.add(1, "days");
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return date;
    }
    const minimalEventDate = addWeekdays(moment(), 3).toString();
    return moment(eventDate).isAfter(minimalEventDate, "day");
  }

  const DetailVr = () => {
    return (
      <>
        {dataVr ? (
          <CardBody>
            <>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  display: "flex",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant={
                    vrReducer.vrDetail?.data?.isRegistred === false
                      ? "primary"
                      : "secondary"
                  }
                  style={{ width: "14%", marginRight: 10 }}
                  disabled={
                    vrReducer.vrDetail?.data?.isRegistred === false ||
                    vrReducer.isLoading
                  }
                  onClick={() => {
                    if (vrReducer.vrDetail?.data?.isRegistred === false) {
                      dispatch(vrAction.vrRegisterRequest({ Id: idVr }));
                    } else if (vrReducer.vrDetail?.data?.isRegistred === true) {
                      setModalConfirmVr({
                        ...modalConfirmVr,
                        show: true,
                        message: `We have received your Withdraw to volunteer for this activity/event.\n Click Confirm to continue or Cancel to revert.`,
                        type: authReducer.user?.volunteerType,
                        onConfirm: (val) => {
                          let payload = {
                            Id: idVr,
                          };
                          if (
                            authReducer.user?.volunteerType === "organisation"
                          ) {
                            payload.reason = val;
                          }
                          dispatch(vrAction.vrWithdrawRequest(payload));
                        },
                      });
                    }
                  }}
                >
                  {vrReducer.vrDetail?.data?.isRegistred === false
                    ? "Register"
                    : "Withdraw"}
                  {vrReducer.isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </Button>
              </div>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <div
                  className="dynamic-content-div"
                  dangerouslySetInnerHTML={headerImageVr()}
                />
                <div
                  className="dynamic-content-div"
                  dangerouslySetInnerHTML={headerIntroVr()}
                />
              </div>
              <BasicInfoForm />
              {authReducer.user.volunteerType === "organisation" &&
                vrReducer.vrDetail?.data?.isRegistred === true && (
                  <UploadAssignedVolunteerForm
                    idSession={idVr}
                    vrType={"regular"}
                  />
                )}
            </>
          </CardBody>
        ) : (
          <LoadingCard />
        )}
      </>
    );
  };

  return (
    <>
      <Card>
        {vrType === "fp" ? (
          <TableSession />
        ) : vrType === "regular" ? (
          <DetailVr />
        ) : null}
        <ModalInfoVr
          show={modalInfoVr.show}
          type={modalInfoVr.type}
          onHide={() => setModalInfoVr({ ...modalInfoVr, show: false })}
        />
        <ModalConfirmVr
          show={modalConfirmVr.show}
          message={modalConfirmVr.message}
          type={modalConfirmVr.type}
          onHide={() => setModalConfirmVr({ ...modalConfirmVr, show: false })}
          onConfirm={(val) => {
            setModalConfirmVr({ ...modalConfirmVr, show: false });
            modalConfirmVr.onConfirm(val);
          }}
        />
      </Card>
    </>
  );
}
