import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

export function ModalInfo({ onHide, open, message }) {
  return (
    <div>
      <Modal
        show={open}
        onHide={onHide}
        centered
        dialogClassName="modal-20w"
      >
        <Modal.Body>
          <Form>
            <div
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Form.Label>{message}</Form.Label>
            </div>
            <div style={{ paddingLeft: "0px", marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "center", paddingLeft: 10, paddingRight: 10 }}>
                <Button variant="success" size="sm" style={{ maxWidth: 100}} onClick={onHide}>
                  OK
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}