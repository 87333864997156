import * as actionType from './actionType';

export const setTokenSetupPassword = data => ({
  type: actionType.AUTH.SET_TOKEN_SETUP_PASSWORD,
  payload: data
})
export const setCredential = data => ({
  type: actionType.AUTH.SET_CREDENTIAL,
  payload: data
})
export const setChangeProfile = data => ({
  type: actionType.AUTH.SET_CHANGE_PROFILE,
  payload: data
})

export const loginRequest = data => ({
  type: actionType.AUTH.LOGIN_REQUEST,
  payload: data
})
export const loginSuccess = data => ({
  type: actionType.AUTH.LOGIN_SUCCESS,
  payload: data
})
export const loginError = data => ({
  type: actionType.AUTH.LOGIN_ERROR,
  payload: data
})
export const loginReset = data => ({
  type: actionType.AUTH.LOGIN_RESET,
  payload: data
})

export const forgotRequest = data => ({
  type: actionType.AUTH.FORGOT_REQUEST,
  payload: data
})
export const forgotSuccess = data => ({
  type: actionType.AUTH.FORGOT_SUCCESS,
  payload: data
})
export const forgotError = data => ({
  type: actionType.AUTH.FORGOT_ERROR,
  payload: data
})
export const forgotReset = data => ({
  type: actionType.AUTH.FORGOT_RESET,
  payload: data
})