import * as actionType from './actionType';

export const sessionGenerateRequest = data => ({
  type: actionType.SCHEDULE.SESSION_GENERATE_REQUEST,
  payload: data
})
export const sessionGenerateSuccess = data => ({
  type: actionType.SCHEDULE.SESSION_GENERATE_SUCCESS,
  payload: data
})
export const sessionGenerateError = data => ({
  type: actionType.SCHEDULE.SESSION_GENERATE_ERROR,
  payload: data
})
export const sessionGenerateReset = data => ({
  type: actionType.SCHEDULE.SESSION_GENERATE_RESET,
  payload: data
})