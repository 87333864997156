import { methodService, apiService } from './apiService';

export const URL = {
  BASE_TIMESHEET: '/timesheets'
}

export function apiTimesheetVrDetail(data) {
  return apiService(
    URL.BASE_TIMESHEET+`/${data.Id}`,
    methodService.GET,
    null,
    data
  );
}

export function apiTimesheetVrValidate(data) {
  return apiService(
    URL.BASE_TIMESHEET+`/${data.Id}/checkinStatus`,
    methodService.POST,
    data,
    null
  );
}

export function apiTimesheetVrCheckin(data) {
  return apiService(
    URL.BASE_TIMESHEET+`/${data.Id}/checkin`,
    methodService.PATCH,
    data,
    null
  );
}

export function apiTimesheetVrCheckout(data) {
  return apiService(
    URL.BASE_TIMESHEET+`/${data.Id}/checkout`,
    methodService.PATCH,
    data,
    null
  );
}