import React from 'react'
import {
    Route,
    Redirect
  } from 'react-router-dom';
import { useSelector } from "react-redux";
  
function SSORoute({ children, ...rest }) {
    const authReducer = useSelector(state => state.auth);
    return (
      <>
        {authReducer.user?.role === "volunteer officer"
           ? (
             <Route {...rest} />
           ) : (
            <Redirect
              to={{
                pathname: '/auth/login-admin',
                // state: { from: location }
              }}
            />
           )
        }
      </>
    );
  }

  export default SSORoute;