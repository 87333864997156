import { methodService, apiService } from './apiService';

export const URL = {
  BASE_VR: '/volunteerRequests',
  BASE_SESSION: '/volunteerSessions',
  BASE_ASSIGNED_VR: '/assignedVolunteers'
}

export function apiVRTable(data) {
  return apiService(
    URL.BASE_VR,
    methodService.GET,
    null,
    data
  );
}

export function apiVRTableAdmin(data) {
  return apiService(
    URL.BASE_VR+'/admin',
    methodService.GET,
    null,
    data
  );
}

export function apiVRDetail(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiVRRegister(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/register`,
    methodService.POST,
    data,
    null
  )
}

export function apiVrUploadAssignedOrg(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/upload`,
    methodService.POST,
    data,
    null
  )
}

export function apiVrDeleteAssignedOrg(data) {
  return apiService(
    URL.BASE_ASSIGNED_VR,
    methodService.DELETE,
    data,
    null
  )
}

export function apiVRWithdraw(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/withdraw`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiVrSession(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/sessions`,
    methodService.GET,
    null,
    null
  )
}

export function apiVrSessionCalendar(data) {
  return apiService(
    URL.BASE_SESSION,
    methodService.GET,
    null,
    data
  )
}

export function apiVrSessionCalendarAdmin(data) {
  return apiService(
    URL.BASE_SESSION+'/admin',
    methodService.GET,
    null,
    data
  )
}

export function apiVrSessionDetail(data) {
  return apiService(
    URL.BASE_SESSION+`/${data.Id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiVrSessionRegister(data) {
  return apiService(
    URL.BASE_SESSION+`/${data.Id}/register`,
    methodService.POST,
    null,
    null
  )
}

export function apiVrSessionUploadAssignedOrg(data) {
  return apiService(
    URL.BASE_SESSION+`/${data.Id}/volunteers`,
    methodService.POST,
    data,
    null
  )
}

export function apiVrSessionDeleteAssignedOrg(data) {
  return apiService(
    URL.BASE_ASSIGNED_VR,
    methodService.DELETE,
    data,
    null
  )
}

export function apiVrSessionWithdraw(data) {
  return apiService(
    URL.BASE_SESSION+`/${data.Id}/withdrawn`,
    methodService.PATCH,
    data,
    null
  )
}