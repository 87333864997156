import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { LoadingCard } from "../../../component/LoadingCard";
import { BasicInfoFormsSession } from "../form/BasicInfoFormSession";
import { UploadAssignedVolunteerForm } from "../form/UploadAssignedVolunteerForm";
import * as vrAction from "../redux/vrAction";
import { ModalInfoVr } from "../modal/ModalInfoVr";
import { ModalConfirmVr } from "../modal/ModalConfirmVr";
import moment from "moment";
import "./VRSession.css";

export function VolunteerSessionDetail() {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const vrReducer = useSelector((state) => state.vr);
  const dataSessionVr = vrReducer.vrSessionDetail;
  const [idSession, setIdSession] = useState(null);
  const [modalConfirmVr, setModalConfirmVr] = useState({
    show: false,
    message: "",
    type: null,
    onConfirm: () => null,
  });
  const [modalInfoVr, setModalInfoVr] = useState({
    show: false,
    type: null, //register || cancel
  });

  useEffect(() => {
    let path = window.location.pathname;
    let _idSession = path.substring(path.lastIndexOf("/") + 1);
    setIdSession(_idSession);
    dispatch(vrAction.vrSessionDetailRequest({ Id: _idSession }));

    return () => {
      dispatch(vrAction.vrSessionDetailReset());
    };
  }, []);

  useEffect(() => {
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrSessionRegister !== null
    ) {
      dispatch(vrAction.vrSessionRegisterReset());
      dispatch(vrAction.vrSessionDetailRequest({ Id: idSession }));
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "register",
      });
    }

    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrSessionWithdraw !== null
    ) {
      dispatch(vrAction.vrSessionWithdrawReset());
      dispatch(vrAction.vrSessionDetailRequest({ Id: idSession }));
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "withdraw",
      });
    }
  }, [vrReducer]);

  function responseAction() {
    if (dataSessionVr?.data?.isRegistred === true) {
      setModalConfirmVr({
        ...modalConfirmVr,
        show: true,
        message: `We have received your Withdraw to volunteer for this activity/event.\n Click Confirm to continue or Cancel to revert.`,
        type: authReducer.user?.volunteerType,
        onConfirm: (val) => {
          let payload = {
            Id: idSession,
          };
          if (authReducer.user?.volunteerType === "organisation") {
            payload.reason = val;
          }
          dispatch(vrAction.vrSessionWithdrawRequest(payload));
        },
      });
    } else {
      dispatch(
        vrAction.vrSessionRegisterRequest({
          Id: idSession,
        })
      );
    }
  }

  function headerImageSession() {
    let vrPicture =
      dataSessionVr?.data?.volunteerRequest[0]?.Session_Picture__c;
    let sessionPicture =
      dataSessionVr?.data?.volunteerSession?.Session_Picture__c;
    if (sessionPicture === null) {
      return {
        __html: vrPicture,
      };
    } else {
      return {
        __html: sessionPicture,
      };
    }
  }

  function headerIntroSession() {
    let vrIntro = dataSessionVr?.data?.volunteerRequest[0]?.Introduction__c;
    let sessionIntro = dataSessionVr?.data?.volunteerSession?.Introduction__c;
    if (sessionIntro === null) {
      return {
        __html: vrIntro,
      };
    } else {
      return {
        __html: sessionIntro,
      };
    }
  }

  function isAbleToRegis(eventDate) {
    function addWeekdays(date, days) {
      date = moment(date); // use a clone
      while (days > 0) {
        date = date.add(1, "days");
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return date;
    }
    const minimalEventDate = addWeekdays(moment(), 3).toString();
    return moment(eventDate).isAfter(minimalEventDate, "day");
  }

  return (
    <Card>
      <CardBody>
        {dataSessionVr === null ? (
          <LoadingCard />
        ) : (
          <>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant={
                  dataSessionVr?.data?.isRegistred === true
                    ? "secondary"
                    : "primary"
                }
                style={{ width: "14%", marginRight: 10 }}
                onClick={() => responseAction()}
                disabled={
                  vrReducer.isLoading ||
                  !isAbleToRegis(
                    dataSessionVr?.data?.volunteerSession?.Session_Date__c
                  )
                }
              >
                {dataSessionVr?.data?.isRegistred === true
                  ? "Withdraw"
                  : "Register"}
                {vrReducer.isLoading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </Button>
            </div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <div
                className="dynamic-content-div"
                dangerouslySetInnerHTML={headerImageSession()}
              />
              <div
                className="dynamic-content-div"
                dangerouslySetInnerHTML={headerIntroSession()}
              />
            </div>

            <BasicInfoFormsSession />
            {authReducer.user.volunteerType === "organisation" &&
              dataSessionVr?.data?.isRegistred === true && (
                <UploadAssignedVolunteerForm
                  idSession={idSession}
                  vrType={"fp"}
                />
              )}
          </>
        )}
        <ModalConfirmVr
          show={modalConfirmVr.show}
          message={modalConfirmVr.message}
          type={modalConfirmVr.type}
          onHide={() => setModalConfirmVr({ ...modalConfirmVr, show: false })}
          onConfirm={(val) => {
            setModalConfirmVr({ ...modalConfirmVr, show: false });
            modalConfirmVr.onConfirm(val);
          }}
        />
        <ModalInfoVr
          show={modalInfoVr.show}
          type={modalInfoVr.type}
          onHide={() => setModalInfoVr({ ...modalInfoVr, show: false })}
        />
      </CardBody>
    </Card>
  );
}
