import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  sessionGenerate: null
}

const sessionGenerateRequest = state => ({
  ...state,
  isLoading: true
})
const sessionGenerateSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  sessionGenerate: payload
})
const sessionGenerateError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const sessionGenerateReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  sessionGenerate: null
})

export const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SCHEDULE.SESSION_GENERATE_REQUEST:
      return sessionGenerateRequest(state, action.payload);
    case actionType.SCHEDULE.SESSION_GENERATE_SUCCESS:
      return sessionGenerateSuccess(state, action.payload);
    case actionType.SCHEDULE.SESSION_GENERATE_ERROR:
      return sessionGenerateError(state, action.payload);
    case actionType.SCHEDULE.SESSION_GENERATE_RESET:
      return sessionGenerateReset(state, action.payload);
  
    default:
      return state;
  }
}