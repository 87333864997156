/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {toAbsoluteUrl} from "../../../_helpers";
import * as dashboardAction from "../../../../app/modules/Dashboard/redux/dashboardAction";

export function ListAnnouncement({ className }) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector(state => state.dashboard);
  const [dataChatter, setDataChatter] = useState([]);

  useEffect(() => {
    dispatch(dashboardAction.chatterRequest());
  }, [])

  useEffect(() => {
    if(dashboardReducer.isLoading === false && dashboardReducer.isError === false && dashboardReducer.chatter !== null){
      let tmpChatter = [...dashboardReducer.chatter.data.feeds].map((item) => {
        let newData;
        if([...item.body.messageSegments].filter(ar => ar.tag === "announcement").length > 0){
          let content = "";
          [...item.body.messageSegments].map((detail) => {
            if(detail.type === "Text"){
              content = content+detail.text;
            } else if(detail.type === "Link"){
              content = content+`<a href="${detail.url}" target="_blank">${detail.text}</a>`
            }
          })
          let tmp = {
            data: item,
            content: content
          }
          newData = tmp;
        }
        return newData;
      }).filter(ar => ar !== undefined);
      setDataChatter(tmpChatter);
    }
  }, [dashboardReducer])

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Announcement
          </h3>
        </div>

        <div className="card-body pt-2">
          {
            dataChatter.map((item) => {
              return(
                <div className="d-flex align-items-center mb-10">
                  <div className="symbol symbol-40 symbol-light-warning mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-warning">
                        <SVG
                          className="h-75 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Promotion.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column font-weight-bold">
                    <div 
                      dangerouslySetInnerHTML={{__html: item.content}}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
}
