import React, { useState } from "react";
import { Col, Row, Container, Form, FormGroup } from "react-bootstrap";
import {
  Radio,
  FormControlLabel,
  Button,
  Checkbox,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import "./index.css";
import { useHistory } from "react-router-dom";

export function CardSignUp() {
  const history = useHistory();
  const [foodpackdelivery, setFoodpackdelivery] = useState(false);
  const [warehouseLogistics, setWarehouseLogistics] = useState(false);
  const [events, setEvents] = useState(false);
  const [profesional, setProfesional] = useState([]);
  const [selfInitiated, setSelfInitiated] = useState([]);

  function handleCheckProfesional(e) {
    let tmpProfesional = [...profesional];
    if(tmpProfesional.filter((el) => el === e).length === 0 ){
      tmpProfesional.push(e);
    } else {
      let indexData = tmpProfesional.indexOf(e);
      tmpProfesional.splice(indexData, 1);
    }
    setProfesional(tmpProfesional);
  }

  function handleCheckSelfInitiated(e){
    let tmpSelfInitiated = [...selfInitiated];
    if(tmpSelfInitiated.filter((el) => el === e).length === 0){
      tmpSelfInitiated.push(e);
    } else {
      let indexData = tmpSelfInitiated.indexOf(e);
      tmpSelfInitiated.splice(indexData, 1);
    }
    setSelfInitiated(tmpSelfInitiated);
  }

  function gotoNextStep(){
    history.push('/your-details')
  }

  return (
    <div className="card-signup">
      <Container>
        <Row>
          <Col className="form-card" lg={12} xs={12}>
            <div className="wrapper-content">
              <h5 className="title-card-sign-up wrapper-signup">BREAD RUN</h5>
              <div>
                <Row>
                  <Col lg={3} xs={12}>
                    <FormControlLabel
                      className="card1"
                      control={
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          disabled
                        />
                      }
                      label="I own a vehicle"
                    />
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-radio-sign-up center">
                      Once a week/Ad-hoc
                    </p>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-radio-sign-up">45 mins</p>
                  </Col>
                  <Col lg={3} xs={12}>
                    <FormControlLabel
                      className="text-radio-sign-up"
                      control={<Radio />}
                      label="I can dedicate 45 minutes every week to help deliver bread"
                      disabled
                    />

                    <div
                      className="warning"
                      style={{ paddingLeft: "30px", paddingBottom: "10px" }}
                    >
                      <p>No available routes at the moment</p>
                    </div>
                    <FormControlLabel
                      className="text-radio-sign-up"
                      control={<Radio />}
                      label="I can be on standby for urgent missions"
                      disabled
                    />
                    <div
                      className="warning"
                      style={{ paddingLeft: "30px", paddingBottom: "10px" }}
                    >
                      <p>No available routes at the moment</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            className={`${foodpackdelivery ? "checked" : "form-card"}`}
            lg={12}
            xs={12}
          >
            <div>
              <div className="wrapper-content">
                <h5
                  className={`${
                    foodpackdelivery
                      ? "checked-title"
                      : "title-card-sign-up wrapper-signup"
                  }`}
                >
                  FOOD PACK DELIVERY
                </h5>
                <div>
                  <Row>
                    <Col lg={3} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <FormControlLabel
                          style={{ paddingTop: "0px" }}
                          className={`${
                            foodpackdelivery ? "bold-label-checkbox" : "info"
                          }`}
                          label="I own a vehicle"
                          control={
                            <Checkbox
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                              onClick={() =>
                                setFoodpackdelivery(!foodpackdelivery)
                              }
                            />
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={3} xs={6}>
                      <label
                        className={`${
                          foodpackdelivery
                            ? "label-check"
                            : "text-radio-sign-up center"
                        }`}
                      >
                        Once a week/Ad-hoc
                      </label>
                    </Col>
                    <Col lg={3} xs={6}>
                      <label
                        className={`${
                          foodpackdelivery
                            ? "label-check"
                            : "text-radio-sign-up"
                        }`}
                      >
                        45 mins
                      </label>
                    </Col>
                    <Col lg={3} xs={12} style={{ paddingBottom: "30px" }}>
                      <FormControlLabel
                        disabled={!foodpackdelivery}
                        label="I can take up ad-hoc Food Pack delivery jobs during the COVID-19 Circuit Breaker period. (NOTE: you will receive an email with further instructions for this.)"
                        control={
                          <Checkbox
                            className="text-radio-sign-up"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className={`${warehouseLogistics ? "checked" : "form-card"}`}
            lg={12}
            xs={12}
          >
            <div>
              <div className="wrapper-content">
                <h5 className="title-card-sign-up wrapper-signup">
                  WAREHOUSE LOGISTICS
                </h5>
                <div>
                  <Row>
                    <Col lg={3} xs={12}>
                      <div>
                        <FormControlLabel
                          className={`${
                            warehouseLogistics ? "bold-label-checkbox" : "info"
                          }`}
                          style={{ paddingTop: "0px" }}
                          label="I can help with sorting and packing"
                          control={
                            <Checkbox
                              className="text-radio-sign-up"
                              icon={<CircleUnchecked />}
                              onClick={() =>
                                setWarehouseLogistics(!warehouseLogistics)
                              }
                              checkedIcon={<CircleCheckedFilled />}
                            />
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={3} xs={6}>
                      <p
                        className={`${
                          warehouseLogistics
                            ? "label-check"
                            : "text-radio-sign-up"
                        }`}
                      >
                        Ad-hoc
                      </p>
                    </Col>
                    <Col lg={3} xs={6}>
                      <p
                        className={`${
                          warehouseLogistics
                            ? "label-check"
                            : "text-radio-sign-up"
                        }`}
                      >
                        30 mins
                      </p>
                    </Col>
                    <Col lg={3} xs={12} style={{ paddingBottom: "30px" }}>
                      <FormControlLabel
                        className={`${
                          warehouseLogistics ? "bold-label-checkbox" : "info"
                        }`}
                        disabled={!warehouseLogistics}
                        control={
                          <Checkbox
                            className="text-radio-sign-up"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                          />
                        }
                        label="I am available on weekdays, 9am – 6pm"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className={`${events ? "checked" : "form-card"}`}
            lg={12}
            xs={12}
          >
            <div>
              <div className="wrapper-content">
                <h5 className="title-card-sign-up wrapper-signup">EVENTS</h5>
              </div>
              <Row>
                <Col lg={3} xs={12}>
                  <div>
                    <FormControlLabel
                      className={`${events ? "bold-label-checkbox" : "info"}`}
                      style={{ paddingTop: "0px" }}
                      label="I would like to help out in Food from the Heart events"
                      control={
                        <Checkbox
                          className="text-radio-sign-up"
                          icon={<CircleUnchecked />}
                          onClick={() => setEvents(!events)}
                          checkedIcon={<CircleCheckedFilled />}
                        />
                      }
                    />
                  </div>
                </Col>
                <Col lg={3} xs={6}>
                  <p
                    className={`${
                      events ? "label-check" : "text-radio-sign-up center"
                    }`}
                  >
                    Ad-hoc
                  </p>
                </Col>
                <Col lg={3} xs={6}>
                  <p
                    className={`${
                      events ? "label-check" : "text-radio-sign-up"
                    }`}
                  >
                    TBC
                  </p>
                </Col>
                <Col lg={3} xs={12} style={{ paddingBottom: "30px" }}>
                  <div>
                    <p className="info">My strengths lie in</p>
                  </div>
                  <FormControlLabel
                    disabled={!events}
                    label="Being an enthusiastic volunteer!"
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={!events}
                    label="Being pro-active"
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Being meticulous"
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="Interacting with children "
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="Interacting with the elderly "
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="Public speaking"
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="My passion/knowledge about food waste"
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="Others"
                  />

                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="I am available on weekdays, 9am – 6pm"
                  />

                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="I am available on weekends"
                  />
                  <FormControlLabel
                    disabled={!events}
                    control={
                      <Checkbox
                        className="text-radio-sign-up"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                      />
                    }
                    label="My Muscle!"
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            className={`${profesional.length === 0 ? "form-card" : "checked"}`}
            lg={12}
            xs={12}
          >
            <div className="wrapper-content">
              <h5
                className={`${
                  !profesional
                    ? "checked-title"
                    : "title-card-sign-up wrapper-signup"
                }`}
              >
                PROFESSIONAL EXPERTISE AND SUPPORT
              </h5>
            </div>
            <Row>
              <Col lg={3} xs={12}>
                <div>
                  <FormControlLabel
                    className={`${
                      !profesional ? "bold-label-checkbox" : "info"
                    }`}
                    control={
                      <Checkbox
                        onClick={(e) => handleCheckProfesional(e.target.value)}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        value="1"
                      />
                    }
                    style={{ paddingTop: "0px", maxWidth: "320px" }}
                    label="I am able to help with being a campaign ambassador (e.g. share about our cause to an audience) "
                  />
                  <FormControlLabel
                    className={`${
                      !profesional ? "bold-label-checkbox" : "info"
                    }`}
                    control={
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        onClick={(e) => handleCheckProfesional(e.target.value)}
                        value="2"
                      />
                    }
                    style={{ paddingTop: "0px", maxWidth: "320px" }}
                    label="I am able to help with photography/videography"
                  />
                  <FormControlLabel
                    className="info"
                    control={
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        onClick={(e) => handleCheckProfesional(e.target.value)}
                        value="3"
                      />
                    }
                    style={{ paddingTop: "0px", maxWidth: "320px" }}
                    label="I am able to help with graphic/multi-media design "
                  />
                  <FormControlLabel
                    className="info"
                    control={<Radio />}
                    style={{ paddingTop: "0px", maxWidth: "320px" }}
                    label="I am able to help with graphic/multi-media design "
                  />
                  <FormControlLabel
                    className="info"
                    control={
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        onClick={(e) => handleCheckProfesional(e.target.value)}
                        value="4"
                      />
                    }
                    style={{
                      paddingTop: "0px",
                      maxWidth: "320px",
                      display: "block",
                    }}
                    label="Others"
                  />
                  <Form>
                    <FormGroup>
                      <Form.Control
                        type="text"
                        style={{
                          display: "inline-block",
                          maxWidth: "200px",
                          border: "none",
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </Col>
              <Col lg={3} xs={6}>
                <p className="text-radio-sign-up center">Ad-hoc</p>
              </Col>
              <Col lg={3} xs={6}>
                <p className="text-radio-sign-up">TBC</p>
              </Col>
              <Col lg={3} xs={12} className="long">
                <FormControlLabel
                  className="text-radio-sign-up"
                  control={
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      onClick={(e) => handleCheckProfesional(e.target.value)}
                      value="option 1"
                    />
                  }
                  label="I am available on weekdays, 9am – 6pm "
                />

                <FormControlLabel
                  className="text-radio-sign-up"
                  control={<Radio />}
                  label="I am available on weekends"
                />
              </Col>
            </Row>
          </Col>
          <Col className={`${selfInitiated.length === 0 ? "form-card" : "checked"}`} lg={12}>
            <div className="wrapper-content">
              <h5 className="title-card-sign-up wrapper-signup">
                SELF-INITIATED PROJECTS
              </h5>

              <div style={{ paddingBottom: "110px" }}>
                <Row>
                  <Col lg={3} xs={12}>
                    <div>
                      <FormControlLabel
                        className="info"
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            onClick={(e) => handleCheckSelfInitiated(e.target.value)}
                            value="self-initiated1"
                          />
                        }
                        style={{ paddingTop: "0px", maxWidth: "320px" }}
                        label="I can help to organise my own food donation drives for Food from the Heart"
                      />
                      <FormControlLabel
                        className="info"
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            onClick={(e) => handleCheckSelfInitiated(e.target.value)}
                            value="self-initiated2"
                          />
                        }
                        style={{ paddingTop: "0px", maxWidth: "320px" }}
                        label="I can help to organise my own fundraising campaigns for Food from the Heart"
                      />
                    </div>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-radio-sign-up center">
                      On your own time
                    </p>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-radio-sign-up center">
                      On your own time
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Container>
            <Row className="justify-content-center">
              <Button
                style={{
                  color: "black",
                  background: "#fdd200",
                  width: "300px",
                  height: "43px",
                  fontWeight: "bold",
                }}
                onClick={gotoNextStep}
              >
                NEXT
              </Button>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
}
