import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { apiForgotPassword } from "../../../datasource/contactRepo";
import Swal from 'sweetalert2'

function ForgotPassword(props) {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  function handleSubmitForgotPassword(e) {
    e.preventDefault();
    let payload = {
      email,
    };
      setIsLoading(true);
      apiForgotPassword(payload)
        .then((res) => {
          console.log(res)
          Swal.fire(
            'Success',
            'please check your email to reset a new password!',
            'success'
          )
        })
        .catch((err) => {
          Swal.fire(
            'Forgot password Error!',
            'message:'+ err.response?.data?.error,
            'error'
          )
        })
        .finally(() => {
          setIsLoading(false);
        });
  }

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot shadow-sm p-3 mb-5 bg-body rounded" 
            style={{ 
              display: "block",
              background: "#dddd",
              padding: "20px",
              boxSizing: "border-box",
              borderRadius: "5px",
            }}>
          <div className="text-center mb-10 mb-lg-7">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={handleSubmitForgotPassword}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {/* {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )} */}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              {/* {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null} */}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isLoading}
              >
                Submit
                {isLoading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
