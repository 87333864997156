import React from 'react';
import moment from "moment-timezone";
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function DetailInfoForm(){
  const data = useSelector(state => state.attendance.attendanceDetail?.data?.attendance);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Attendance</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Name} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Volunteer Request</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Volunteer_Request__r?.Name} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Start Date</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Start_Date__c ? moment(data?.Start_Date__c).format('DD/MM/YYYY') : ''} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Start Time</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Start_Time__c ? data?.Start_Time__c?.substring(0,5) : ''} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>End Date</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.End_Date__c ? moment(data?.End_Date__c).tz("Asia/Singapore").format('DD/MM/YYYY') : ''} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>End Time</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.End_Time__c ? data?.End_Time__c?.substring(0,5) : ''} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Checked In</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Checked_In__c ? moment(data?.Checked_In__c).tz("Asia/Singapore").format('DD/MM/YYYY, hh:MM A') : ''} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Checked Out</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Checked_Out__c ? moment(data?.Checked_Out__c).tz("Asia/Singapore").format('DD/MM/YYYY, hh:MM A') : ''} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}