import React from "react";
import { Modal, Row, Button } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { FaTrash, FaCircle } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import "./Modal.css";

export function ModalConfirm({ onHide, open, data, onConfirm }) {
  return (
    <div>
      <Modal
        size="sm"
        show={open}
        onHide={onHide}
        centered
      >
        <Modal.Body>
          <div
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h4>{data.title}</h4>
          </div>
          <div style={{ paddingLeft: "0px", marginTop: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10 }}>
              <Button variant="success" size="sm" style={{ maxWidth: 100}} onClick={onConfirm}>
                Confirm
              </Button>
              <Button variant="danger" size="sm" style={{ maxWidth: 100}} onClick={onHide}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}