import * as actionType from './actionType';

export const peTableRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_REQUEST,
  payload: data
})
export const peTableSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_SUCCESS,
  payload: data
})
export const peTableError = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_ERROR,
  payload: data
})
export const peTableReset = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_RESET,
  payload: data
})

export const peDetailRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_REQUEST,
  payload: data
})
export const peDetailSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_SUCCESS,
  payload: data
})
export const peDetailError = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_ERROR,
  payload: data
})
export const peDetailReset = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_RESET,
  payload: data
})