export const TIMESHEET = {
  DETAIL_VR_REQUEST: 'TIMESHEET_DETAIL_VR_REQUEST',
  DETAIL_VR_SUCCESS: 'TIMESHEET_DETAIL_VR_SUCCESS',
  DETAIL_VR_ERROR: 'TIMESHEET_DETAIL_VR_ERROR',
  DETAIL_VR_RESET: 'TIMESHEET_DETAIL_VR_RESET',

  VALIDATE_VR_REQUEST: 'TIMESHEET_VALIDATE_VR_REQUEST',
  VALIDATE_VR_SUCCESS: 'TIMESHEET_VALIDATE_VR_SUCCESS',
  VALIDATE_VR_ERROR: 'TIMESHEET_VALIDATE_VR_ERROR',
  VALIDATE_VR_RESET: 'TIMESHEET_VALIDATE_VR_RESET',

  CHECKIN_VR_REQUEST: 'TIMESHEET_CHECKIN_VR_REQUEST',
  CHECKIN_VR_SUCCESS: 'TIMESHEET_CHECKIN_VR_SUCCESS',
  CHECKIN_VR_ERROR: 'TIMESHEET_CHECKIN_VR_ERROR',
  CHECKIN_VR_RESET: 'TIMESHEET_CHECKIN_VR_RESET',

  CHECKOUT_VR_REQUEST: 'TIMESHEET_CHECKOUT_VR_REQUEST',
  CHECKOUT_VR_SUCCESS: 'TIMESHEET_CHECKOUT_VR_SUCCESS',
  CHECKOUT_VR_ERROR: 'TIMESHEET_CHECKOUT_VR_ERROR',
  CHECKOUT_VR_RESET: 'TIMESHEET_CHECKOUT_VR_RESET',
}