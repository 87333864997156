import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import MobileStepper from "@material-ui/core/MobileStepper";
import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import "./index.css";

export function CostumizeStepper({ steps }) {
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-48% + 6px)",
      right: "calc(52% + 5px)",
    },
    active: {
      "& $line": {
        borderColor: "#fdd200",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#fdd200",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 2,
      borderRadius: 1,
    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },

    active: {
      background: "#ffffff",
      color: "#fdd200",
      borderRadius: "100%",
      border: "1.5px solid  #fdd200 ",
    },
    circle: {
      width: 20,
      height: 20,
      backgroundColor: "#ffffff",
      borderRadius: "100%",
      border: "1.5px solid  #fdd200 ",
    },
    completed: {
      color: "white",
      zIndex: 1,
      fontSize: 20,
      borderRadius: "50%",
      background: "#fdd200",
    },
  });

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <Check className={classes.completed} />
        ) : (
          <div className={classes.circle} />
        )}
      </div>
    );
  }

  const stepLabel = [
    "Food Deliveries",
    "Warehouse Logistics",
    "Events",
    "Professional Expertise and Support",
    "Self-initiated projects",
    "Volunteer Sign-Up",
    "Your Detail",
  ];

  return (
    <Container maxWidth="lg" className="container-stepper">
      <Stepper
        className="stepper"
        orientation="horizontal"
        alternativeLabel
        activeStep={steps}
        connector={<QontoConnector />}
      >
        {stepLabel.map((label) => {
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <span className="step-label">{label}</span>
              </StepLabel>
            </Step>
          );
        })}
        {/* <Step>
          <StepLabel className="step-label" StepIconComponent={QontoStepIcon}>
            <span className="step-label">Food Deliveries</span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 7 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Warehouse Logistics
            </span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 7 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Events
            </span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 7 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Professional Expertise and Support
            </span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 7 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Self-initiated projects
            </span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 7 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Volunteer Sign-Up
            </span>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <span
              style={{ color: steps === 6 ? "#1d1e23" : "#AAAAAA" }}
              className="step-label"
            >
              Your Detail
            </span>
          </StepLabel>
        </Step> */}
      </Stepper>
    </Container>
  );
}
