import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";

export function BasicInfoFormsSession(){
  const authReducer = useSelector(state => state.auth);
  const vrReducer = useSelector(state => state.vr);
  const [dataVr, setDataVr] = useState(null);
  const [assignedVolunteer, setAssginedVolunteer] = useState(null);
  const [isRegistred, setIsRegistred] = useState(false);
  
  useEffect(() => {
    if(vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrDetail !== null){
      setDataVr(vrReducer.vrDetail.data.volunteerRequest);
    }
    if(vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrSessionDetail !== null){
      setIsRegistred(vrReducer.vrSessionDetail.data.isRegistred);
      setDataVr(vrReducer.vrSessionDetail.data.volunteerSession);
      if(vrReducer.vrSessionDetail.data.assignedVolunteer){
        setAssginedVolunteer(vrReducer.vrSessionDetail.data.assignedVolunteer);
      } else {
        setAssginedVolunteer(null);
      }
    }
  }, [vrReducer])

  return(
    <>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={()=>null}>
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#basic-info"
          >
            Basic Information
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <Form>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>My Status</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={
                  authReducer.user?.volunteerType === "organisation" ?
                  isRegistred ? 'Accepted' : 
                  assignedVolunteer === null ? 'Waiting for response' : assignedVolunteer?.Assignee_Status__c
                  : assignedVolunteer?.Assignee_Status__c
                }
              />
            </Form.Group>
            {
              authReducer?.user?.role === "volunteer" ?
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Date</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment(dataVr?.Session_Date__c).format("DD-MM-YYYY")}
                />
              </Form.Group>
              :
              <Form.Group className={'col-lg-6'}>
                <Form.Label>VR Name</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Name} 
                />
              </Form.Group>
            }
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            {
              authReducer?.user?.role === "volunteer" ?
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment.utc(dataVr?.Session_Date__c+" "+dataVr?.Start_Time__c?.substring(0,5)).format("HH:mm A")} 
                />
              </Form.Group> :
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Date</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment(dataVr?.Session_Date__c).format("DD-MM-YYYY")}
                />
              </Form.Group>
            }
            {
              authReducer?.user?.role === "volunteer" ?
              <Form.Group className={'col-lg-6'}>
                <Form.Label>End Time</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment.utc(dataVr?.Session_Date__c+" "+dataVr?.End_Time__c?.substring(0,5)).format("HH:mm A")} 
                />
              </Form.Group>
              :
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Type</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Session_Type__c}
                />
              </Form.Group>
            }
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            {
              authReducer?.user?.role === "volunteer" ?
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Number of Signup</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Number_of_Sign_ups__c}
                />
              </Form.Group>
              :
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Start Time</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment.utc(dataVr?.Session_Date__c+" "+dataVr?.Start_Time__c?.substring(0,5)).format("HH:mm A")} 
                />
              </Form.Group>
            }
            {
              authReducer?.user?.role === "volunteer" ?
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Max Number of Volunteer Required</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Max_Num_of_Volunteer__c} 
                />
              </Form.Group>
              :
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Location</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Volunteer_Location__c} 
                />
              </Form.Group>
            }
          </Form.Group>
          {
            authReducer?.user?.role !== "volunteer" &&
            <Form.Group as={Row} className={'mb-0 mt-0'}>
              <Form.Group className={'col-lg-6'}>
                <Form.Label>End Time</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={moment.utc(dataVr?.Session_Date__c+" "+dataVr?.End_Time__c?.substring(0,5)).format("HH:mm A")} 
                />
              </Form.Group>
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Number of Signup</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Number_of_Sign_ups__c}
                />
              </Form.Group>
            </Form.Group>
          }
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Description</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={dataVr?.Description__c}
                as="textarea"
              />
            </Form.Group>
            {
              authReducer?.user?.role !== "volunteer" &&
              <Form.Group className={'col-lg-6'}>
                <Form.Label>Max Number of Volunteer Required</Form.Label>
                <Form.Control 
                  type="text"
                  disabled
                  value={dataVr?.Max_Num_of_Volunteer__c} 
                />
              </Form.Group>
            }
          </Form.Group>
        </Form>
      </div>
    </>
  )
}