import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "../../Donation/component/Navbar";
import TitleImage from "../../Donation/component/TitleImage";
import Footer from "../../Donation/component/Footer";
import "./SubmissionSuccessPage.css";

export function SubmissionSuccessPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <Header />
      <TitleImage
        image={
          "http://foodfromtheheart.sg/assets/getinvolved/form-mast/summary.jpg"
        }
        title={"Submission Success"}
      />
      <section style={{ marginTop: "35px", marginBottom: "25px" }}>
        <Container className="container-success" style={{ padding: 25 }}>
          <h1 className="success-title">Thank you!</h1>
          <p className="success-info">
            Kindly allow us to get back to you within 3 - 5 working days as we
            review your application. <br />
            <br />
            Curious about what we've been up to?
            <br />
            Visit our
            <a
              href="https://www.foodfromtheheart.sg/highlights/"
              className="happenings-info"
            >
              highlights
            </a>
            page! <br />
            <br />
            <br />
            <a href="https://www.foodfromtheheart.sg/" className="return-link">
              {" "}
              Return to home page
            </a>
          </p>
        </Container>
      </section>
      <Footer />
    </div>
  );
}
