import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import * as authAction from "../redux/authAction";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function LoginSso() {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { handleSubmit } = useForm({});

  useEffect(() => {
    let resultSso;
    if (window.location.search !== "") {
      resultSso = window.location.search;
      let accessToken = getParameterByName("accessToken");
      let decodeToken = jwt_decode(accessToken);
      dispatch(
        authAction.setCredential({
          authToken: accessToken,
          user: {
            Id: decodeToken.Id,
            email: decodeToken.Email,
            firstname: decodeToken.Name,
            lastname: "",
            role: decodeToken.userType,
          },
        })
      );
    }
  }, []);

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function onSubmit() {
    window.location =
      process.env.REACT_APP_ENV === "production"
        ? "https://helpnow.foodfromtheheart.sg/api/oauth/auth"
        : "https://ffth-psb.interaktiv.sg/api-volunteer/oauth/auth";
  }

  return (
    <div
      className="login-form login-signin shadow-sm p-3 mb-5 bg-body rounded"
      id="kt_login_signin_form"
      style={{
        width: "350px",
        background: "#dddd",
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      <div className="text-center mb-5 mb-lg-0">
        <Image
          src={`${toAbsoluteUrl("/media/ffth/logo.png")}`}
          fluid
          style={{
            height: 75,
            marginTop: 20,
            maxHeight: "50px",
            marginBottom: 20,
          }}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={authReducer.isLoading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            style={{ minWidth: 300, height: 70 }}
          >
            <LazyLoadImage
              src={`${toAbsoluteUrl("/media/ffth/logo-sf.png")}`}
              style={{ width: 50, height: 35, marginRight: 20 }}
            />
            <span>SIGN IN WITH SALESFORCE</span>
            {authReducer.isLoading && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
