import { methodService, apiService } from './apiService';

export const URL = {
  BASE_CHATTER: '/feed',
  BASE_ACTIVITY: '/contacts/myActivity',
  BASE_CONTACTUS: '/contactUs'
}

export function apiChatter() {
  return apiService(
    URL.BASE_CHATTER,
    methodService.GET,
    null,
    null
  );
}

export function apiMyActivity(data) {
  return apiService(
    URL.BASE_ACTIVITY,
    methodService.GET,
    null,
    data
  );
}

export function apiContactUs(data){
  return apiService(
    URL.BASE_CONTACTUS,
    methodService.POST,
    data,
    null
  )
}