import React from "react";
import { useSelector } from "react-redux";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
  ListsFacebookFeed,
  ListAnnouncement,
  ListMyActivity,
} from "../widgets";
import { Helmet } from "react-helmet";

export function Demo1Dashboard() {
  const authReducer = useSelector((state) => state.auth);
  return (
    <>
      <Helmet>
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <div className="row">
        {authReducer?.user?.role !== "volunteer officer" && (
          <div className="col-lg-6 col-xxl-4">
            <ListMyActivity className="card-stretch gutter-b" />
          </div>
        )}
        <div className="col-lg-6 col-xxl-4">
          <ListAnnouncement className="card-stretch gutter-b" />
        </div>
        {authReducer?.user?.role !== "volunteer officer" && (
          <div className="col-lg-6 col-xxl-4">
            <ListsFacebookFeed className="card-stretch gutter-b" />
          </div>
        )}
      </div>
    </>
  );
}
