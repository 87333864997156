import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: null,
  vrTableAdmin: null,
  vrDetail: null,
  vrRegister: null,
  vrUploadAssignedOrg: null,
  vrDeleteAssignedOrg: null,
  vrWithdraw: null,
  vrSession: null,
  vrSessionCalendar: null,
  vrSessionCalendarAdmin: null,
  vrSessionDetail: null,
  vrSessionRegister: null,
  vrSessionUploadAssignedOrg: null,
  vrSessionDeleteAssignedOrg: null,
  vrSessionWithdraw: null
}

const vrTableRequest = state => ({
  ...state,
  isLoading: true
})
const vrTableSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: payload
})
const vrTableError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrTableReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: null
})

const vrTableAdminRequest = state => ({
  ...state,
  isLoading: true
})
const vrTableAdminSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTableAdmin: payload
})
const vrTableAdminError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrTableAdminReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTableAdmin: null
})

const vrDetailRequest = state => ({
  ...state,
  isLoading: true
})
const vrDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDetail: payload
})
const vrDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDetail: null
})

const vrRegisterRequest = state => ({
  ...state,
  isLoading: true
})
const vrRegisterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrRegister: payload
})
const vrRegisterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrRegisterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrRegister: null
})

const vrUploadAssignedOrgRequest = state => ({
  ...state,
  isLoading: true
})
const vrUploadAssignedOrgSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrUploadAssignedOrg: payload
})
const vrUploadAssignedOrgError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrUploadAssignedOrgReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrUploadAssignedOrg: null
})

const vrDeleteAssignedOrgRequest = state => ({
  ...state,
  isLoading: true
})
const vrDeleteAssignedOrgSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDeleteAssignedOrg: payload
})
const vrDeleteAssignedOrgError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrDeleteAssignedOrgReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDeleteAssignedOrg: null
})

const vrWithdrawRequest = state => ({
  ...state,
  isLoading: true
})
const vrWithdrawSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrWithdraw: payload
})
const vrWithdrawError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrWithdrawReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrWithdraw: null
})

const vrSessionRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSession: payload
})
const vrSessionError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSession: null
})

const vrSessionCalendarRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionCalendarSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionCalendar: payload
})
const vrSessionCalendarError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionCalendarReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionCalendar: null
})

const vrSessionCalendarAdminRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionCalendarAdminSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionCalendarAdmin: payload
})
const vrSessionCalendarAdminError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionCalendarAdminReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionCalendarAdmin: null
})

const vrSessionDetailRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionDetail: payload
})
const vrSessionDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionDetail: null
})

const vrSessionRegisterRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionRegisterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionRegister: payload
})
const vrSessionRegisterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionRegisterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionRegister: null
})

const vrSessionUploadAssignedOrgRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionUploadAssignedOrgSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionUploadAssignedOrg: payload
})
const vrSessionUploadAssignedOrgError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionUploadAssignedOrgReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionUploadAssignedOrg: null
})

const vrSessionDeleteAssignedOrgRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionDeleteAssignedOrgSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionDeleteAssignedOrg: payload
})
const vrSessionDeleteAssignedOrgError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionDeleteAssignedOrgReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionDeleteAssignedOrg: null
})

const vrSessionWithdrawRequest = state => ({
  ...state,
  isLoading: true
})
const vrSessionWithdrawSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionWithdraw: payload
})
const vrSessionWithdrawError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrSessionWithdrawReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrSessionWithdraw: null
})

export const vrReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.VR.TABLE_REQUEST:
      return vrTableRequest(state, action.payload);
    case actionType.VR.TABLE_SUCCESS:
      return vrTableSuccess(state, action.payload);
    case actionType.VR.TABLE_ERROR:
      return vrTableError(state, action.payload);
    case actionType.VR.TABLE_RESET:
      return vrTableReset(state, action.payload);

    case actionType.VR.TABLE_ADMIN_REQUEST:
      return vrTableAdminRequest(state, action.payload);
    case actionType.VR.TABLE_ADMIN_SUCCESS:
      return vrTableAdminSuccess(state, action.payload);
    case actionType.VR.TABLE_ADMIN_ERROR:
      return vrTableAdminError(state, action.payload);
    case actionType.VR.TABLE_ADMIN_RESET:
      return vrTableAdminReset(state, action.payload);

    case actionType.VR.DETAIL_REQUEST:
      return vrDetailRequest(state, action.payload);
    case actionType.VR.DETAIL_SUCCESS:
      return vrDetailSuccess(state, action.payload);
    case actionType.VR.DETAIL_ERROR:
      return vrDetailError(state, action.payload);
    case actionType.VR.DETAIL_RESET:
      return vrDetailReset(state, action.payload);

    case actionType.VR.REGISTER_REQUEST:
      return vrRegisterRequest(state, action.payload);
    case actionType.VR.REGISTER_SUCCESS:
      return vrRegisterSuccess(state, action.payload);
    case actionType.VR.REGISTER_ERROR:
      return vrRegisterError(state, action.payload);
    case actionType.VR.REGISTER_RESET:
      return vrRegisterReset(state, action.payload);

    case actionType.VR.UPLOAD_ASSIGNED_ORG_REQUEST:
      return vrUploadAssignedOrgRequest(state, action.payload);
    case actionType.VR.UPLOAD_ASSIGNED_ORG_SUCCESS:
      return vrUploadAssignedOrgSuccess(state, action.payload);
    case actionType.VR.UPLOAD_ASSIGNED_ORG_ERROR:
      return vrUploadAssignedOrgError(state, action.payload);
    case actionType.VR.UPLOAD_ASSIGNED_ORG_RESET:
      return vrUploadAssignedOrgReset(state, action.payload);

    case actionType.VR.DELETE_ASSIGNED_ORG_REQUEST:
      return vrDeleteAssignedOrgRequest(state, action.payload);
    case actionType.VR.DELETE_ASSIGNED_ORG_SUCCESS:
      return vrDeleteAssignedOrgSuccess(state, action.payload);
    case actionType.VR.DELETE_ASSIGNED_ORG_ERROR:
      return vrDeleteAssignedOrgError(state, action.payload);
    case actionType.VR.DELETE_ASSIGNED_ORG_RESET:
      return vrDeleteAssignedOrgReset(state, action.payload);

    case actionType.VR.WITHDRAW_REQUEST:
      return vrWithdrawRequest(state, action.payload);
    case actionType.VR.WITHDRAW_SUCCESS:
      return vrWithdrawSuccess(state, action.payload);
    case actionType.VR.WITHDRAW_ERROR:
      return vrWithdrawError(state, action.payload);
    case actionType.VR.WITHDRAW_RESET:
      return vrWithdrawReset(state, action.payload);

    case actionType.VR.SESSION_REQUEST:
      return vrSessionRequest(state, action.payload);
    case actionType.VR.SESSION_SUCCESS:
      return vrSessionSuccess(state, action.payload);
    case actionType.VR.SESSION_ERROR:
      return vrSessionError(state, action.payload);
    case actionType.VR.SESSION_RESET:
      return vrSessionReset(state, action.payload);

    case actionType.VR.SESSION_CALENDAR_REQUEST:
      return vrSessionCalendarRequest(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_SUCCESS:
      return vrSessionCalendarSuccess(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_ERROR:
      return vrSessionCalendarError(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_RESET:
      return vrSessionCalendarReset(state, action.payload);

    case actionType.VR.SESSION_CALENDAR_ADMIN_REQUEST:
      return vrSessionCalendarAdminRequest(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_ADMIN_SUCCESS:
      return vrSessionCalendarAdminSuccess(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_ADMIN_ERROR:
      return vrSessionCalendarAdminError(state, action.payload);
    case actionType.VR.SESSION_CALENDAR_ADMIN_RESET:
      return vrSessionCalendarAdminReset(state, action.payload);

    case actionType.VR.SESSION_DETAIL_REQUEST:
      return vrSessionDetailRequest(state, action.payload);
    case actionType.VR.SESSION_DETAIL_SUCCESS:
      return vrSessionDetailSuccess(state, action.payload);
    case actionType.VR.SESSION_DETAIL_ERROR:
      return vrSessionDetailError(state, action.payload);
    case actionType.VR.SESSION_DETAIL_RESET:
      return vrSessionDetailReset(state, action.payload);

    case actionType.VR.SESSION_REGISTER_REQUEST:
      return vrSessionRegisterRequest(state, action.payload);
    case actionType.VR.SESSION_REGISTER_SUCCESS:
      return vrSessionRegisterSuccess(state, action.payload);
    case actionType.VR.SESSION_REGISTER_ERROR:
      return vrSessionRegisterError(state, action.payload);
    case actionType.VR.SESSION_REGISTER_RESET:
      return vrSessionRegisterReset(state, action.payload);

    case actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_REQUEST:
      return vrSessionUploadAssignedOrgRequest(state, action.payload);
    case actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_SUCCESS:
      return vrSessionUploadAssignedOrgSuccess(state, action.payload);
    case actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_ERROR:
      return vrSessionUploadAssignedOrgError(state, action.payload);
    case actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_RESET:
      return vrSessionUploadAssignedOrgReset(state, action.payload);

    case actionType.VR.SESSION_DELETE_ASSIGNED_ORG_REQUEST:
      return vrSessionDeleteAssignedOrgRequest(state, action.payload);
    case actionType.VR.SESSION_DELETE_ASSIGNED_ORG_SUCCESS:
      return vrSessionDeleteAssignedOrgSuccess(state, action.payload);
    case actionType.VR.SESSION_DELETE_ASSIGNED_ORG_ERROR:
      return vrSessionDeleteAssignedOrgError(state, action.payload);
    case actionType.VR.SESSION_DELETE_ASSIGNED_ORG_RESET:
      return vrSessionDeleteAssignedOrgReset(state, action.payload);

    case actionType.VR.SESSION_WITHDRAW_REQUEST:
      return vrSessionWithdrawRequest(state, action.payload);
    case actionType.VR.SESSION_WITHDRAW_SUCCESS:
      return vrSessionWithdrawSuccess(state, action.payload);
    case actionType.VR.SESSION_WITHDRAW_ERROR:
      return vrSessionWithdrawError(state, action.payload);
    case actionType.VR.SESSION_WITHDRAW_RESET:
      return vrSessionWithdrawReset(state, action.payload);
  
    default:
      return state;
  }
}