import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  attendanceTable: null,
  attendanceDetail: null
}

const attendanceTableRequest = state => ({
  ...state,
  isLoading: true
})
const attendanceTableSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  attendanceTable: payload
})
const attendanceTableError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const attendanceTableReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  attendanceTable: null
})

const attendanceDetailRequest = state => ({
  ...state,
  isLoading: true
})
const attendanceDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  attendanceDetail: payload
})
const attendanceDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const attendanceDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  attendanceDetail: null
})

export const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ATTENDANCE.TABLE_REQUEST:
      return attendanceTableRequest(state, action.payload);
    case actionType.ATTENDANCE.TABLE_SUCCESS:
      return attendanceTableSuccess(state, action.payload);
    case actionType.ATTENDANCE.TABLE_ERROR:
      return attendanceTableError(state, action.payload);
    case actionType.ATTENDANCE.TABLE_RESET:
      return attendanceTableReset(state, action.payload);

    case actionType.ATTENDANCE.DETAIL_REQUEST:
      return attendanceDetailRequest(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_SUCCESS:
      return attendanceDetailSuccess(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_ERROR:
      return attendanceDetailError(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_RESET:
      return attendanceDetailReset(state, action.payload);
  
    default:
      return state;
  }
}