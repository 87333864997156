import * as actionType from './actionType';

export const attendanceTableRequest = data => ({
  type: actionType.ATTENDANCE.TABLE_REQUEST,
  payload: data
})
export const attendanceTableSuccess = data => ({
  type: actionType.ATTENDANCE.TABLE_SUCCESS,
  payload: data
})
export const attendanceTableError = data => ({
  type: actionType.ATTENDANCE.TABLE_ERROR,
  payload: data
})
export const attendanceTableReset = data => ({
  type: actionType.ATTENDANCE.TABLE_RESET,
  payload: data
})

export const attendanceDetailRequest = data => ({
  type: actionType.ATTENDANCE.DETAIL_REQUEST,
  payload: data
})
export const attendanceDetailSuccess = data => ({
  type: actionType.ATTENDANCE.DETAIL_SUCCESS,
  payload: data
})
export const attendanceDetailError = data => ({
  type: actionType.ATTENDANCE.DETAIL_ERROR,
  payload: data
})
export const attendanceDetailReset = data => ({
  type: actionType.ATTENDANCE.DETAIL_RESET,
  payload: data
})