import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as peAction from './peAction';
import * as peRepo from '../../../datasource/peRepo';

function* getTable(data){
  try {
    const res = yield call(peRepo.apiPETable, data.payload);
    yield put(peAction.peTableSuccess(res));
  } catch(err) {
    yield put(peAction.peTableError(err));
  }
}

function* getDetail(data){
  try {
    const res = yield call(peRepo.apiPEDetail, data.payload);
    yield put(peAction.peDetailSuccess(res));
  } catch(err) {
    yield put(peAction.peDetailError(err))
  }
}

export default function* watchPE() {
  yield all([
    takeLatest(actionType.PROGRAMME_EVENTS.TABLE_REQUEST, getTable),
    takeLatest(actionType.PROGRAMME_EVENTS.DETAIL_REQUEST, getDetail)
  ])
}