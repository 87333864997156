export const AUTH = {
  SET_TOKEN_SETUP_PASSWORD: 'AUTH_SET_TOKEN_SETUP_PASSWORD',
  SET_CREDENTIAL: 'AUTH_SET_CREDENTIAL',
  SET_CHANGE_PROFILE: 'SET_CHANGE_PROFILE',

  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
  LOGIN_RESET: 'AUTH_LOGIN_RESET',

  FORGOT_REQUEST: 'AUTH_FORGOT_REQUEST',
  FORGOT_SUCCESS: 'AUTH_FORGOT_SUCCESS',
  FORGOT_ERROR: 'AUTH_FORGOT_ERROR',
  FORGOT_RESET: 'AUTH_FORGOT_RESET',
}