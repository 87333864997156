/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const authReducer = useSelector(state => state.auth);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {
          authReducer.user?.role === 'volunteer' ?
          <li
            className={`menu-item ${getMenuItemActive("/calendar", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/calendar">
              <span className="svg-icon menu-icon">
                <i className="menu-icon flaticon-event-calendar-symbol"></i>
              </span>
              <span className="menu-text">Calendar</span>
            </NavLink>
          </li>
          :
          <li
            className={`menu-item ${getMenuItemActive("/schedule", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/schedule">
              <span className="svg-icon menu-icon">
                <i className="menu-icon flaticon-event-calendar-symbol"></i>
              </span>
              <span className="menu-text">Schedule</span>
            </NavLink>
          </li>
        }

        {
          authReducer.user?.role === 'volunteer officer' &&
          <li
            className={`menu-item ${getMenuItemActive("/upload", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/upload">
              <span className="svg-icon menu-icon">
                <i className="menu-icon flaticon-event-calendar-symbol"></i>
              </span>
              <span className="menu-text">Upload Donation {authReducer.user?.role}</span>
            </NavLink>
          </li>
        }

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/programme-events", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/programme-events">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-customer"></i>
            </span>
            <span className="menu-text">Upcoming Activities/Events</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/volunteer-request", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/volunteer-request">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-presentation"></i>
            </span>
            <span className="menu-text">Volunteer Opportunities</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/attendance", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/attendance">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-time"></i>
            </span>
            <span className="menu-text">Attendance</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
