import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  setupPasswordToken: null,
  loginInfo: null,
  user: undefined,
  authToken: undefined,
  forgotPassword: null
}

const setTokenSetupPassword = (state, payload) => ({
  ...state,
  setupPasswordToken: payload
})
const setCredential = (state, payload) => ({
  ...state,
  authToken: payload?.authToken,
  user: payload?.user
})
const setChangeProfile = (state, payload) => {
  console.log(payload)
  return{
    ...state,
    user: payload
  }
}

const loginRequest = state => ({
  ...state,
  isLoading: true
})
const loginSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  loginInfo: payload
})
const loginError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const loginReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  loginInfo: null,
  user: undefined,
  authToken: null
})

const forgotRequest = state => ({
  ...state,
  isLoading: true
})
const forgotSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  forgotPassword: payload
})
const forgotError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const forgotReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  forgotPassword: null
})


export const authReducer = persistReducer(
  { storage, key: "ffth-auth", whitelist: ["user", "authToken"] },
  (state = initialState, action) => {
  switch (action.type ) {
    case actionType.AUTH.SET_TOKEN_SETUP_PASSWORD:
      return setTokenSetupPassword(state, action.payload);
    case actionType.AUTH.SET_CREDENTIAL:
      return setCredential(state, action.payload);
    case actionType.AUTH.SET_CHANGE_PROFILE:
      return setChangeProfile(state, action.payload);

    case actionType.AUTH.LOGIN_REQUEST:
      return loginRequest(state, action.payload);
    case actionType.AUTH.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case actionType.AUTH.LOGIN_ERROR:
      return loginError(state, action.payload);
    case actionType.AUTH.LOGIN_RESET:
      return loginReset(state, action.payload);

    case actionType.AUTH.FORGOT_REQUEST:
      return forgotRequest(state, action.payload);
    case actionType.AUTH.FORGOT_SUCCESS:
      return forgotSuccess(state, action.payload);
    case actionType.AUTH.FORGOT_ERROR:
      return forgotError(state, action.payload);
    case actionType.AUTH.FORGOT_RESET:
      return forgotReset(state, action.payload);
  
    default:
      return state;
  }
});