import React, { useEffect } from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import "./index.css";

export default function TitleImage({ image, title }) {
  useEffect(() => {
    forceVisible()
  }, [])

  return (
    <>
      <LazyLoad
        className="giform-mast"
        style={{
          backgroundImage: `url('${image}')`,
          textAlign: "center",
        }}
        height
      >
        <div className="message">
          <h1>{title}</h1>
        </div>
      </LazyLoad>
    </>
  );
}
