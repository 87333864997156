import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as scheduleAction from './scheduleAction';
import * as scheduleRepo from '../../../datasource/scheduleRepo';

function* sessionGenerate(data){
  try {
    const res = yield call(scheduleRepo.apiGenerateSession, data.payload);
    yield put(scheduleAction.sessionGenerateSuccess(res));
  } catch(err) {
    yield put(scheduleAction.sessionGenerateError(err));
  }
}

export default function* watchSchedule() {
  yield all([
    takeLatest(actionType.SCHEDULE.SESSION_GENERATE_REQUEST, sessionGenerate)
  ])
}