import React, { useState } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Accordion,
  AccordionCollapse,
} from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.css";

export default function Header() {
  const [isHover, setIsHover] = useState(0);
  const [isMouseLeave, setIsMouseLeave] = useState(true);
  const [dropdownMobile1, setDropdownMobile1] = useState(false);
  const [dropdownMobile2, setDropdownMobile2] = useState(false);
  const [dropdownMobile3, setDropdownMobile3] = useState(false);

  function handleHover(idNumber) {
    setIsHover(idNumber);
    setIsMouseLeave(false);
  }

  function handleMouseLeave() {
    setIsMouseLeave(true);
    setIsHover(0);
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        id="navigation"
        className="Navbar"
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Container
          className="container"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <Container className="Nav ">
            <Navbar.Brand
              href="#home"
              className="logo-ffth"
              onClick={() =>
                (window.location.href = "https://www.foodfromtheheart.sg/")
              }
            >
              <LazyLoadImage
                src={toAbsoluteUrl("/media/ffth/logo.png")}
                style={{ maxWidth: "100px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle className="toggle" />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="nav-list">
                <Nav.Link
                  className="link about"
                  onMouseEnter={() => handleHover(1)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <span
                    style={{
                      color:
                        (isHover === 1 && !isMouseLeave) || isHover === 0
                          ? "#1d1e23"
                          : "#00000080",
                    }}
                    className="nav-menu"
                  >
                    ABOUT
                  </span>
                  <div className="dropdown-1">
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/about/")
                      }
                    >
                      <span>Mission & History</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/about/board-and-management")
                      }
                    >
                      <span>The Board & The Management</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/about/financials-reports")
                      }
                    >
                      <span>Financial & Report</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/about/partners-stakeholders")
                      }
                    >
                      <span>Partners</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/media/")
                      }
                    >
                      <span>Media</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/faq/")
                      }
                    >
                      <span>FAQ</span>
                    </Nav.Link>
                  </div>
                </Nav.Link>
                <Nav.Link
                  className="link our-work"
                  onMouseEnter={() => handleHover(2)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <span
                    style={{
                      color:
                        (isHover === 2 && !isMouseLeave) || isHover === 0
                          ? "#1d1e23"
                          : "#00000080",
                    }}
                    className="nav-menu"
                  >
                    OUR WORK
                  </span>
                  <div className="dropdown-2">
                    <Nav.Link>
                      <span
                        style={{
                          color: "#1d1e23",
                          margin: "10px 0px",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        PROGRAMMES
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/bread-run/")
                      }
                    >
                      <span>Bread Run</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/community-food-pack/#")
                      }
                    >
                      <span>Community Food Pack</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/school-goodie-bag/")
                      }
                    >
                      <span>School Goodie Bag</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/project-belanja/")
                      }
                    >
                      <span>Project Belanja!</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/market-place/")
                      }
                    >
                      <span>Market Place</span>
                    </Nav.Link>
                    <hr />
                    <Nav.Link>
                      <span
                        style={{
                          color: "#1d1e23",
                          margin: "10px 0px",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        SIGNATURE EVENTS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/passion-ball/")
                      }
                    >
                      <span>Passion Ball</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/clean-plate-campaign/")
                      }
                    >
                      <span>Clean Plate Campaign</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/toy-buffet/")
                      }
                    >
                      <span>Toy Buffet</span>
                    </Nav.Link>
                    <hr />
                    <Nav.Link>
                      <span
                        style={{
                          color: "#1d1e23",
                          margin: "10px 0px",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        CAMPAIGNS AND INITIATIVES
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/community-shop/")
                      }
                    >
                      <span>Community Shop</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/donate-right/")
                      }
                    >
                      <span>Donate Right</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/pop-up-fresh-produce-market/")
                      }
                    >
                      <span>Pop-Up Fresh Produce Market</span>
                    </Nav.Link>
                  </div>
                </Nav.Link>
                <Nav.Link
                  className="link opportunities "
                  href="#pricing"
                  onMouseEnter={() => handleHover(3)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <span
                    style={{
                      color:
                        (isHover === 3 && !isMouseLeave) || isHover === 0
                          ? "#1d1e23"
                          : "#00000080",
                    }}
                    className="nav-menu"
                  >
                    OPPORTUNITIES TO HELP
                  </span>
                  <div className="dropdown-3">
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/opportunities-to-help/")
                      }
                    >
                      <span>Tour: Opportunities to help</span>
                    </Nav.Link>
                    <hr />
                    <Nav.Link>
                      <span
                        style={{
                          color: "#1d1e23",
                          margin: "10px 0px",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        FOR INDIVIDUALS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/donate/")
                      }
                    >
                      <span>Cash Donations</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/in-kind-donations")
                      }
                    >
                      <span>In-Kind Donations</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/tour/individual-volunteer/sign-up.php")
                      }
                    >
                      <span>Sign up as a volunteer</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/vportal/")
                      }
                    >
                      <span>Volunteer portal</span>
                    </Nav.Link>
                    <hr />
                    <Nav.Link>
                      <span
                        style={{
                          color: "#1d1e23",
                          margin: "10px 0px",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        FOR GROUPS
                      </span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/corporations-institutions")
                      }
                    >
                      <span>Corporations and institutions</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        (window.location.href =
                          "https://www.foodfromtheheart.sg/schools")
                      }
                    >
                      <span>Schools</span>
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        window.open(
                          "https://www.foodfromtheheart.sg/food-service-vendors",
                          "_blank"
                        )
                      }
                    >
                      <span>Food and service vendors</span>
                    </Nav.Link>
                  </div>
                </Nav.Link>
                <Nav.Link
                  className="link contact"
                  onMouseEnter={() => {
                    handleHover(4);
                  }}
                  onMouseLeave={() => handleMouseLeave()}
                  href="https://www.foodfromtheheart.sg/contact/"
                >
                  <span
                    style={{
                      color:
                        (isHover === 4 && !isMouseLeave) || isHover === 0
                          ? "#1d1e23"
                          : "#00000080",
                    }}
                    className="nav-menu"
                  >
                    CONTACT
                  </span>
                </Nav.Link>
              </Nav>
              <Nav className="nav-list-mobile">
                <Nav.Link className="link about  mb-5">
                  <span
                    style={{
                      color: "#1d1e23",

                      padding: "15px",
                      fontWeight: "bolder",
                    }}
                    className="nav-menu-about"
                    onClick={() => setDropdownMobile1(!dropdownMobile1)}
                  >
                    ABOUT
                  </span>
                  <div
                    style={{
                      display:
                        dropdownMobile1 && window.innerWidth < 500
                          ? "block"
                          : "none",
                    }}
                    className="dropdown-1-mobile"
                  >
                    <div className="container-link-mobile">
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/about/")
                        }
                      >
                        <span>Mission & History</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/about/board-and-management")
                        }
                      >
                        <span>The Board & The Management</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/about/financials-reports")
                        }
                      >
                        <span>Financial & Report</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/about/partners-stakeholders")
                        }
                      >
                        <span>Partners</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/media/")
                        }
                      >
                        <span>Media</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/faq/")
                        }
                      >
                        <span>FAQ</span>
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link className="link our-work mt-5 mb-5">
                  <span
                    style={{
                      color: "#1d1e23",

                      padding: "15px",
                      fontWeight: "bolder",
                    }}
                    className="nav-menu-our-work"
                    onClick={() => setDropdownMobile2(!dropdownMobile2)}
                  >
                    OUR WORK
                  </span>
                  <div
                    style={{
                      display:
                        dropdownMobile2 && window.innerWidth < 500
                          ? "block"
                          : "none",
                    }}
                    className="dropdown-2-mobile"
                  >
                    <div className="container-link-mobile">
                      <Nav.Link>
                        <span
                          style={{
                            color: "#C22900",
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          PROGRAMMES
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/bread-run/")
                        }
                      >
                        <span>Bread Run</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/community-food-pack/#")
                        }
                      >
                        <span>Community Food Pack</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/school-goodie-bag/")
                        }
                      >
                        <span>School Goodie Bag</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/project-belanja/")
                        }
                      >
                        <span>Project Belanja!</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/market-place/")
                        }
                      >
                        <span>Market Place</span>
                      </Nav.Link>
                      <hr />
                      <Nav.Link>
                        <span
                          style={{
                            color: "#C22900",
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          SIGNATURE EVENTS
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/passion-ball/")
                        }
                      >
                        <span>Passion Ball</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/clean-plate-campaign/")
                        }
                      >
                        <span>Clean Plate Campaign</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/toy-buffet/")
                        }
                      >
                        <span>Toy Buffet</span>
                      </Nav.Link>
                      <hr />
                      <Nav.Link>
                        <span
                          style={{
                            color: "#C22900",
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          CAMPAIGNS AND INITIATIVES
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/community-shop/")
                        }
                      >
                        <span>Community Shop</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/donate-right/")
                        }
                      >
                        <span>Donate Right</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/pop-up-fresh-produce-market/")
                        }
                      >
                        <span>Pop-Up Fresh Produce Market</span>
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link className="link opportunities mt-5 mb-5">
                  <span
                    style={{
                      color: "#1d1e23",
                      padding: "15px",
                      fontWeight: "bolder",
                    }}
                    className="nav-menu-opportunities"
                    onClick={() => setDropdownMobile3(!dropdownMobile3)}
                  >
                    OPPORTUNITIES TO HELP
                  </span>
                  <div
                    style={{
                      display:
                        dropdownMobile3 && window.innerWidth < 500
                          ? "block"
                          : "none",
                    }}
                    className="dropdown-2-mobile"
                  >
                    <div className="container-link-mobile">
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/opportunities-to-help/")
                        }
                      >
                        <span>Tour: Opportunities to help</span>
                      </Nav.Link>
                      <hr />
                      <Nav.Link>
                        <span
                          style={{
                            color: "#C22900",
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          FOR INDIVIDUALS
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/donate/")
                        }
                      >
                        <span>Cash Donations</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/in-kind-donations")
                        }
                      >
                        <span>In-Kind Donations</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/tour/individual-volunteer/sign-up.php")
                        }
                      >
                        <span>Sign up as a volunteer</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/vportal/")
                        }
                      >
                        <span>Volunteer portal</span>
                      </Nav.Link>
                      <hr />
                      <Nav.Link>
                        <span
                          style={{
                            color: "#C22900",
                            margin: "10px 0px",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          FOR GROUPS
                        </span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/corporations-institutions")
                        }
                      >
                        <span>Corporations and institutions</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/schools")
                        }
                      >
                        <span>Schools</span>
                      </Nav.Link>
                      <Nav.Link
                        onClick={() =>
                          (window.location.href =
                            "https://www.foodfromtheheart.sg/food-service-vendors")
                        }
                      >
                        <span>Food and service vendors</span>
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  className="link contact mt-5"
                  href="https://www.foodfromtheheart.sg/contact/"
                >
                  <span
                    style={{
                      color: "#1d1e23",
                      padding: "15px",
                      fontWeight: "bolder",
                    }}
                    className="nav-menu-contact"
                  >
                    CONTACT
                  </span>
                </Nav.Link>
              </Nav>
              <Nav className="btn-nav">
                <Button
                  className="button-nav donate"
                  variant="danger"
                  onClick={() =>
                    (window.location.href =
                      "https://helpnow.foodfromtheheart.sg/donate")
                  }
                >
                  <span>DONATE</span>
                </Button>
              </Nav>
            </Navbar.Collapse>
            <div className="d-flex container-dropdown"></div>
          </Container>
        </Container>
      </Navbar>
    </>
  );
}
