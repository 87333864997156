import React from "react";
import "./index.css";
import { Container } from "react-bootstrap";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { MdMail } from "react-icons/md";

export default function Footer() {
  return (
    <div className="footer">
      <Container className="content-footer">
        <div className="about-footer">
          <ul className="m-5 p-0">
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              ABOUT
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/about/">
                {" "}
                Mission & History
              </a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/about/board-and-management">
                The Board and The Management
              </a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/about/financials-reports">
                Financial & Reports
              </a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/about/partners-stakeholders">
                Partners
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/media/">Media</a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/faq/">FAQ</a>
            </li>
          </ul>
        </div>
        <div className="our-work-footer">
          <ul className="m-5 p-0">
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              OUR WORK
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/bread-run/">Bread Run</a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/community-food-pack/">
                Community Food Pack
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/school-goodie-bag/">
                School Goodie Bag
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/project-belanja/">
                Project Belanja!
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/market-place/">
                Market Place
              </a>
            </li>
          </ul>
        </div>

        <div className="opportunities-footer">
          <ul>
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              OPPORTUNITES TO HELP
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/opportunities-to-help/">
                Tour: Opportunities to help
              </a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/tour/individual-volunteer/sign-up.php">
                Sign up as a volunteer
              </a>
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/vportal/">
                Volunteer portal
              </a>{" "}
            </li>
            <li>
              {" "}
              <a href="https://www.foodfromtheheart.sg/corporations-institutions">
                Corporations and institutions
              </a>{" "}
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/corporations-institutions">
                School
              </a>{" "}
            </li>
            <li>
              <a href="https://www.foodfromtheheart.sg/food-service-vendors">
                Food and service vendors
              </a>{" "}
            </li>
          </ul>
        </div>

        <div className="donate-footer">
          <ul>
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
              }}
            >
              <a href="https://www.foodfromtheheart.sg/donate/">DONATE</a>{" "}
            </li>
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
              }}
            >
              <a href="https://www.foodfromtheheart.sg/highlights/">
                HIGHLIGHTS
              </a>{" "}
            </li>
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
              }}
            >
              <a href="https://www.foodfromtheheart.sg/contact/">CONTACTS</a>
            </li>
          </ul>
        </div>

        <div className="social-footer">
          <ul>
            <li
              className="li-bold"
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              SOCIAL
            </li>
            <li>{/* <a href={facebook}></a> */}</li>
            <div className="icon-social">
              <a
                style={{ margin: "0", padding: "0" }}
                href="https://www.facebook.com/foodheart/"
              >
                <FaFacebookF
                  style={{
                    color: "#ffd200",
                    width: "11px",
                    height: "21px",
                  }}
                />
              </a>
              <a
                style={{ margin: "0", padding: "0" }}
                href="https://www.instagram.com/foodfromtheheartsg/"
              >
                <FiInstagram
                  style={{ color: "#ffd200", width: "20px", height: "20px" }}
                />
              </a>
              <a
                style={{ margin: "0", padding: "0" }}
                href="https://www.youtube.com/channel/UCY4yYGZK1DMJHec_D_v7Oqg"
              >
                <FaYoutube
                  style={{ color: "#ffd200", width: "21px", height: "21px" }}
                />
              </a>
              <a
                style={{ margin: "0", padding: "0" }}
                href="mailto:info@foodheart.org"
              >
                <MdMail
                  style={{ color: "#ffd200", width: "21px", height: "21px" }}
                />
              </a>
            </div>
            <div className="address-footer mb-3">
              <b>ADDRESS</b>: 130 Joo Seng Road #03-01 <br /> Singapore 368357{" "}
              <br /> <b>PHONE</b>: 6280 4483
            </div>
            <div className="policy-footer">
              Data Protection Policy
              <br /> Terms of Use
              <br /> © Copyright 2021 Food from the Heart
            </div>
          </ul>
        </div>
      </Container>
    </div>
  );
}
