import React, { useState, useEffect } from "react";
import { Card } from "../../../../_metronic/_partials/controls";
import { useSelector, useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment-timezone";
import { Form, Row, Col, Button } from "react-bootstrap";
import Datetime from "react-datetime";
import Select from "react-select";
import "../style/event.css";
import { ModalEvent, ModalInfo } from "../modal";
import * as contactAction from "../../Contact/redux/contactAction";
import * as scheduleAction from "../redux/scheduleAction";
import * as vrAction from "../../VolunteerRequest/redux/vrAction";
import "./Schedule.css";

export function SchedulePage() {
  const dispatch = useDispatch();
  const contactReducer = useSelector((state) => state.contact);
  const scheduleReducer = useSelector((state) => state.schedule);
  const vrReducer = useSelector((state) => state.vr);
  const [isEdit, setIsEdit] = useState(false);
  const [optionDate, setOptionDate] = useState([]);
  const [optionDateEnd, setOptionDateEnd] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFirstDate, setSelectedFirstDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [maxVolunteer, setMaxVolunteer] = useState(0);
  const [scheduleType, setScheduleType] = useState("regular");
  const [scheduleSession, setScheduleSession] = useState("morning");
  const [firstDate, setFirstDate] = useState("");
  const [startTime, setStartTime] = useState(
    moment()
      .set("hour", 9)
      .set("minute", 30)
  );
  const [endTime, setEndTime] = useState(
    moment()
      .set("hour", 12)
      .set("minute", 0)
  );

  const [volunteerType, setVolunteerType] = useState("individual");
  const [firstCreate, setFirstCreate] = useState(false);
  const [volunteerLocation, setVolunteerLocation] = useState("L4");
  const [optionPostalcode, setOptionPostalcode] = useState([]);
  const [postalCode, setPostalCode] = useState(null);
  const [unitNumber, setUnitNumber] = useState();
  const [eventCalendar, setEventCalendar] = useState([]);
  const [maxEventRow, setMaxEventRow] = useState(6);
  const [eventInfo, setEventInfo] = useState({});
  const [eventClick, setEventClick] = useState({});
  const [eventDrop, setEventDrop] = useState({});
  const [isVisibleModalEvent, setIsVisibleModalEvent] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    dispatch(contactAction.postalcodeRequest());
    dispatch(vrAction.vrTableAdminRequest());
    generateDateOption();
  }, []);

  useEffect(() => {
    if (
      contactReducer.isLoading === false &&
      contactReducer.isError === false &&
      contactReducer.postalcode !== null
    ) {
      let tmpPostalcode = [...contactReducer.postalcode.data.postalcodes].map(
        (item) => {
          let _newData = {
            value: item.name,
            label: item.name,
            street: item.street,
            block: item.block,
            buildingName: item.buildingName,
          };
          return _newData;
        }
      );
      setOptionPostalcode(tmpPostalcode);
    }
  }, [contactReducer]);

  useEffect(() => {
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrSessionCalendarAdmin !== null &&
      vrReducer.vrTableAdmin !== null
    ) {
      let dataVrRegular = [...vrReducer.vrTableAdmin.data.volunteerRequests]
        .map((item) => {
          if (item.RecordType?.Name === "Regular") {
            let _newData = {
              id: moment().valueOf() + getRandomInt(0, 1000),
              sfId: item.Id,
              date: item.Start_Date__c,
              title: item.Name,
              session: null,
              numberSession: 0,
              start_time: moment.utc(
                item.Start_Date__c + " " + item.Start_Time__c
              ),
              end_time: moment.utc(item.End_Date__c + " " + item.End_Time__c),
              schedule_type: "regular",
              volunteer_type: item.Volunteer_Type__c,
              volunteer_loc: item.Volunteering_Location__c,
              assignedVolunteer: 0,
              numberVolunteer: item.Number_of_Volunteers_Required__c,
              textColor: "white",
              backgroundColor: "red",
              className: "eventBorder",
            };
            return _newData;
          }
        })
        .filter((ar) => ar !== undefined);
      let dataSession =
        vrReducer.vrSessionCalendarAdmin?.data?.volunteerSessions === null
          ? []
          : [...vrReducer.vrSessionCalendarAdmin?.data?.volunteerSessions].map(
              (item) => {
                let _newData = {
                  id: moment().valueOf() + getRandomInt(0, 1000),
                  sfId: item.Id,
                  date: item.Session_Date__c,
                  title: item.Session_Title__c,
                  session:
                    item.Session_Shift__c === "Morning"
                      ? "morning"
                      : "afternoon",
                  numberSession: `${
                    item.Session_Shift__c === "Morning" ? 0 : 1
                  }`,
                  start_time: moment.utc(
                    item.Session_Date__c + " " + item.Start_Time__c
                  ),
                  end_time: moment.utc(
                    item.Session_Date__c + " " + item.End_Time__c
                  ),
                  schedule_type:
                    item.Session_Type__c === "Regular" ? "regular" : "adhoc",
                  volunteer_type:
                    item.Volunteer_Type__c === "Individual"
                      ? "individual"
                      : "organisation",
                  volunteer_loc: item.Volunteer_Location__c,
                  textColor: "white",
                  assignedVolunteer: 0,
                  numberVolunteer: item.Max_Num_of_Volunteer__c,
                  backgroundColor: `${
                    item.Volunteer_Type__c === "Individual"
                      ? "#2a8282"
                      : "#2222ba"
                  }`,
                  className: "eventBorder",
                };
                return _newData;
              }
            );
      let finalData = dataSession.concat(dataVrRegular);
      setEventCalendar(finalData);
    }
  }, [vrReducer]);

  function getSessionCalendar(year, month) {
    dispatch(
      vrAction.vrSessionCalendarAdminRequest({
        year: year,
        // month: month
      })
    );
  }

  function handleEventChange(eventDrop) {
    let dataDropNew = {
      id: eventDrop?.id,
      date: moment(eventDrop.start).format("YYYY-MM-DD"),
      volunteer_type: eventDrop?.extendedProps?.volunteer_type,
      session: eventDrop?.extendedProps?.session,
      backgroundColor: eventDrop?.backgroundColor,
      title: eventDrop?.title,
      numberSession: eventDrop?.extendedProps?.numberSession,
      start_time: eventDrop?.extendedProps?.start_time,
      end_time: eventDrop?.extendedProps?.end_time,
      schedule_type: eventDrop?.extendedProps?.schedule_type,
      volunteer_loc: eventDrop?.extendedProps?.volunteer_loc,
      numberVolunteer: eventDrop?.extendedProps?.numberVolunteer,
    };
    setEventInfo(dataDropNew);

    setEventCalendar(
      eventCalendar.map((event) =>
        event.id == dataDropNew.id
          ? { ...event, date: moment(eventDrop.start).format("YYYY-MM-DD") }
          : event
      )
    );
  }

  function generateDateOption() {
    let arrDate = [];
    let i = 1;
    while (i < 32) {
      let dateValue;
      if (i.toString().length === 1) {
        dateValue = "0" + i.toString();
      } else {
        dateValue = "" + i;
      }
      let newDate = {
        value: dateValue,
        label: i,
      };
      arrDate.push(newDate);
      i++;
    }
    setOptionDate(arrDate);
    let arrDateEnd = [{ value: 0, label: "none" }];
    let newArrDateEnd = arrDateEnd.concat(arrDate);
    setOptionDateEnd(newArrDateEnd);
  }

  function clearEvent() {
    let tmpEventCalendar = [...eventCalendar].filter(
      (ar) => ar.sfId !== undefined
    );
    setEventCalendar(tmpEventCalendar);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function displayEvent() {
    let startDate = moment(
      moment(selectedYear).format("YYYY") +
        moment(selectedMonth).format("MM") +
        selectedFirstDate
    );
    let yesterday = moment().subtract(1, "day");
    if (startDate.isBefore(yesterday)) {
      setModalInfo({
        show: true,
        message: "Can't select old date, please select another date",
      });
      return true;
    }
    let endDate;

    if (selectedEndDate === null) {
      endDate = startDate.clone();
    } else if (selectedEndDate === 0) {
      endDate = startDate.clone();
    } else if (selectedEndDate < selectedFirstDate) {
      endDate = startDate.clone();
    } else {
      endDate = moment(
        moment(selectedYear).format("YYYY") +
          moment(selectedMonth).format("MM") +
          selectedEndDate
      );
    }
    let tmpEvent = [...eventCalendar];

    const checkSessionExist = (currentDate, session, volunteerType) => {
      let currentDateEvent = [...eventCalendar].filter(
        (ar) =>
          ar.date === currentDate &&
          ar.session === session &&
          ar.volunteer_type === volunteerType
      );
      return currentDateEvent.length + 1;
    };

    while (startDate <= endDate) {
      if (startDate.day() !== 0 && startDate.day() !== 6) {
        let tmpObj = {
          id: moment().valueOf() + getRandomInt(0, 1000),
          title: `FP Session ${
            scheduleSession === "morning" ? "M" : "A"
          }${checkSessionExist(
            startDate.format("YYYY-MM-DD"),
            scheduleSession,
            volunteerType
          )} - 0/${maxVolunteer} ${scheduleType === "regular" ? "R" : "A"}`,
          date: startDate.format("YYYY-MM-DD"),
          session: scheduleSession,
          numberSession: `${scheduleSession === "morning" ? 0 : 1}`,
          start_time: startTime,
          end_time: endTime,
          schedule_type: scheduleType,
          volunteer_type: volunteerType,
          volunteer_loc: volunteerLocation,
          textColor: "white",
          assignedVolunteer: 0,
          numberVolunteer: maxVolunteer,
          backgroundColor: `${
            volunteerType === "individual" ? "#2a8282" : "#2222ba"
          }`,
        };
        tmpEvent.push(tmpObj);
      }
      startDate.add("days", 1);
    }
    setMaxEventRow(4);
    reorderSession(tmpEvent);
    setFirstCreate(true);
  }

  function compareDate(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  //ngurutin event nya
  function bubbleSort(a, par) {
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < a.length - 1; i++) {
        if (a[i][par] > a[i + 1][par]) {
          let temp = a[i];
          a[i] = a[i + 1];
          a[i + 1] = temp;
          swapped = true;
        }
      }
    } while (swapped);
  }

  function reorderSession(tmpEvent) {
    tmpEvent.sort(compareDate);
    bubbleSort(tmpEvent, "numberSession");
    setEventCalendar(tmpEvent);
  }

  useEffect(() => {
    if (
      scheduleReducer.isLoading === false &&
      scheduleReducer.isError === false &&
      scheduleReducer.sessionGenerate !== null
    ) {
      dispatch(scheduleAction.sessionGenerateReset());
      setModalInfo({
        show: true,
        message: "Data has been generated into salesforce",
      });
      clearEvent();
      getSessionCalendar(moment().format("YYYY"));
    }
  }, [scheduleReducer]);

  function generateEvent() {
    let newEventCalendar = [...eventCalendar]
      .filter((ar) => ar.sfId === undefined)
      .map((item) => {
        item.start_time = moment(item.start_time).format("hh:mm A");
        item.end_time = moment(item.end_time).format("hh:mm A");
        item.activityType = "Food Packing";
        return item;
      });
    dispatch(
      scheduleAction.sessionGenerateRequest({ sessions: newEventCalendar })
    );
  }

  function handleTooltipOpen(props) {
    let _data = {
      id: props?.id,
      name: props?.title,
      session: props?.extendedProps?.session,
      date: moment(props?.start).format("YYYY-MM-DD"),
      schedule_type: props?.extendedProps?.schedule_type,
      start_time: moment(props?.extendedProps?.start_time).format("hh:mm a"),
      end_time: moment(props?.extendedProps?.end_time).format("hh:mm a"),
      volunteer_type: props?.extendedProps?.volunteer_type,
      volunteer_loc: props?.extendedProps?.volunteer_loc,
      assignedVolunteer: props?.extendedProps?.assignedVolunteer,
      numberVolunteer: props?.extendedProps?.numberVolunteer,
      sfId: props?.extendedProps?.sfId,
    };
    setEventInfo(_data);

    setIsVisibleModalEvent(true);
  }

  function onDeleteEvent(dataEvent) {
    let tmpDelete = [...eventCalendar].filter(
      (ar) => ar.id !== parseInt(dataEvent.id)
    );
    setEventCalendar(tmpDelete);
  }

  function handleEditEvent(eventInfo) {
    let tmpEdit = eventCalendar.filter(
      (ar) => ar.id === parseInt(eventInfo.id)
    );
    let dataEdit = tmpEdit[0];

    let dataEditNew = {
      id: dataEdit.id,
      date: moment(eventInfo.start).format("YYYY-MM-DD"),
      volunteer_type: volunteerType,
      session: scheduleSession,
      backgroundColor: `${
        volunteerType === "individual" ? "#2a8282" : "#2222ba"
      }`,
      title: `FP Session ${
        scheduleSession === "morning" ? "M" : "A"
      } - 0/${maxVolunteer}     ${scheduleType === "regular" ? "R" : "A"}`,
      numberSession: `${scheduleSession === "morning" ? 0 : 1}`,
      start_time: startTime,
      end_time: endTime,
      schedule_type: scheduleType,
      volunteer_loc: volunteerLocation,
      numberVolunteer: maxVolunteer,
    };
    setEventInfo(dataEditNew);
    setEventCalendar(
      eventCalendar.map((event) => {
        if (event.id !== dataEditNew.id) return event;
        return { ...event, ...dataEditNew };
      })
    );

    setIsEdit(false);
    setIsVisibleModalEvent(false);
  }

  const validDate = function(current) {
    let yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  return (
    <>
      <Card>
        <div className="p-10">
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => null}>
              <a
                className={`nav-link basic active`}
                data-toggle="tab"
                role="tab"
                href="#schedule"
              >
                Food Package Scheduling
              </a>
            </li>
          </ul>
          <Form>
            <Form.Group as={Row} className="mt-5">
              <Col sm="1" lg="1">
                <Form.Label>Year</Form.Label>
              </Col>
              <Col sm="2" lg="2" className="mr-15">
                <Datetime
                  dateFormat="YYYY"
                  timeFormat={false}
                  closeOnSelect
                  onChange={(val) => setSelectedYear(val)}
                  isValidDate={validDate}
                />
              </Col>
              <Col sm="1" lg="1">
                <Form.Label>Start Date</Form.Label>
              </Col>
              <Col sm="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={optionDate.length === 0}
                  onChange={(event) => {
                    setSelectedFirstDate(event.value);
                  }}
                  value={
                    optionDate.filter((ar) => ar.value === selectedFirstDate)[0]
                  }
                  options={optionDate}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-0 mb-2">
              <Col sm="1" lg="1">
                <Form.Label>Month</Form.Label>
              </Col>
              <Col sm="2" lg="2" className="mr-15">
                <Datetime
                  dateFormat="MMMM"
                  timeFormat={false}
                  closeOnSelect
                  onChange={(val) => setSelectedMonth(val)}
                  isValidDate={validDate}
                />
              </Col>
              <Col sm="1" lg="1">
                <Form.Label>End Date</Form.Label>
              </Col>
              <Col sm="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={optionDateEnd.length === 0}
                  onChange={(event) => {
                    setSelectedEndDate(event.value);
                  }}
                  value={
                    optionDateEnd.filter(
                      (ar) => ar.value === selectedEndDate
                    )[0]
                  }
                  options={optionDateEnd}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-0 mb-2 ">
              <Col sm="2" lg="2" className="pt-2">
                <Form.Label>Type</Form.Label>
              </Col>
              <Col sm="8" lg="8">
                <Row>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerType === "individual"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className="mt-2"
                    onClick={() => setVolunteerType("individual")}
                  >
                    Individual
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerType === "organisation"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className="mt-2"
                    onClick={() => setVolunteerType("organisation")}
                  >
                    Organisation
                  </Button>
                  <Col sm="3" lg="3" className="pt-1">
                    <Form.Label className="mb-0">
                      Maximum Number of Volunteer
                    </Form.Label>
                  </Col>
                  <Col lg="2" sm="2" className="pl-0 pr-1 mr-0 pt-1">
                    <Form.Control
                      type="number"
                      name="numberVolunteer"
                      placeholder="0"
                      value={maxVolunteer}
                      onChange={(event) => {
                        setMaxVolunteer(event.target.value);
                      }}
                      style={{
                        maxWidth: "80px",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-0 mb-0">
              <Col sm="2" lg="2">
                <Form.Label>Schedule Type</Form.Label>
              </Col>
              <Col sm="8" lg="8" className="mr-15">
                <Row>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        scheduleType === "regular"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    onClick={() => setScheduleType("regular")}
                  >
                    Regular
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        scheduleType === "adhoc"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    onClick={() => setScheduleType("adhoc")}
                  >
                    Ad-Hoc
                  </Button>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-0 mb-0">
              <Col sm="2" lg="2" className="pt-2">
                <Form.Label>Session</Form.Label>
              </Col>
              <Col sm="8" lg="8">
                <Row>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        scheduleSession === "morning"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className="mt-2"
                    onClick={() => {
                      setScheduleSession("morning");
                      setStartTime(
                        moment()
                          .set("hour", 9)
                          .set("minute", 30)
                      );
                      setEndTime(
                        moment()
                          .set("hour", 12)
                          .set("minute", 0)
                      );
                    }}
                  >
                    Morning
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        scheduleSession === "afternoon"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className="mt-2"
                    onClick={() => {
                      setScheduleSession("afternoon");
                      setStartTime(
                        moment()
                          .set("hour", 14)
                          .set("minute", 30)
                      );
                      setEndTime(
                        moment()
                          .set("hour", 17)
                          .set("minute", 0)
                      );
                    }}
                  >
                    Afternoon
                  </Button>
                  <Col sm="1" lg="1" className="pt-1 mr-6">
                    <Form.Label>Time</Form.Label>
                  </Col>
                  <Col lg="2" sm="2" className="pl-0 pr-1 mr-0 pt-1">
                    <Datetime
                      dateFormat={false}
                      timeFormat={"hh:mm a"}
                      closeOnSelect
                      value={startTime}
                      onChange={(val) => {
                        setStartTime(val);
                      }}
                    />
                  </Col>
                  <div className="mt-3">-</div>
                  <Col lg="2" sm="2" className="pl-0 pr-1 mr-0 ml-1 pt-1">
                    <Datetime
                      dateFormat={false}
                      timeFormat={"hh:mm a"}
                      closeOnSelect
                      value={endTime}
                      onChange={(val) => {
                        setEndTime(val);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-0 mb-2">
              <Col sm="2" lg="2">
                <Form.Label>Volunteer Location</Form.Label>
              </Col>
              <Col sm="9" lg="9" className="mr-15">
                <Row>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerLocation === "L4"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className={"mt-2"}
                    onClick={() => setVolunteerLocation("L4")}
                  >
                    L4
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerLocation === "PR"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className={"mt-2"}
                    onClick={() => setVolunteerLocation("PR")}
                  >
                    PR
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerLocation === "MP"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className={"mt-2"}
                    onClick={() => setVolunteerLocation("MP")}
                  >
                    MP
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      fontSize: "14px",
                      background: `${
                        volunteerLocation === "OTHER"
                          ? `#FFD200`
                          : "rgb(221, 221, 221)"
                      }`,
                      border: "none",
                      marginLeft: "10px",
                    }}
                    className={"mt-2"}
                    onClick={() => setVolunteerLocation("OTHER")}
                  >
                    Other
                  </Button>
                </Row>
              </Col>
            </Form.Group>
            {volunteerLocation === "OTHER" && (
              <Form.Group as={Row} className="mt-0 mb-2">
                <Col sm="2" lg="2">
                  <Form.Label>Other Location</Form.Label>
                </Col>
                <Col sm="6" lg="6">
                  <Row className="ml-0">
                    <Col lg="4" sm="4" className="pl-0 pr-1 mr-0">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={"Postal Code"}
                        options={optionPostalcode}
                        value={
                          optionPostalcode.filter(
                            (ar) => ar.value === postalCode
                          )[0]
                        }
                        onInputChange={(item) => {
                          dispatch(
                            contactAction.postalcodeRequest({ param: item })
                          );
                        }}
                        onChange={(event) => {
                          if (event?.value) {
                            setPostalCode(event.value);
                          } else {
                            setPostalCode(null);
                          }
                        }}
                        isSearchable
                        isClearable
                      />
                    </Col>
                    <Col lg="4" sm="4" className="pl-0 pr-1 mr-0 ml-2">
                      <Form.Control
                        type="text"
                        name="unitNumber"
                        placeholder="Unit Number"
                        style={{
                          maxWidth: "150px",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} className="mt-10 mb-0">
              <Col sm="2" lg="2" />
              <Col
                sm="8"
                lg="8"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  onClick={clearEvent}
                  variant="secondary"
                  size="sm"
                  disabled={scheduleReducer.isLoading}
                >
                  Clear
                </Button>
                <Button
                  onClick={displayEvent}
                  className="ml-5 mr-5"
                  disabled={scheduleReducer.isLoading}
                >
                  Display Event
                </Button>
                <Button
                  onClick={generateEvent}
                  variant="success"
                  disabled={scheduleReducer.isLoading}
                >
                  Generate Event
                  {scheduleReducer.isLoading && (
                    <span className="ml-3 spinner spinner-white" />
                  )}
                </Button>
              </Col>
              <Col sm="2" lg="2" />
            </Form.Group>
          </Form>
        </div>
        <div className="pl-10 pr-10 pb-10">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            editable={true}
            eventStartEditable={true}
            eventDurationEditable={true}
            eventResourceEditable={true}
            dayMaxEventRows={true}
            views={{
              dayGridMonth: {
                dayMaxEventRows: maxEventRow,
              },
            }}
            datesSet={(d) => {
              getSessionCalendar(
                moment(d.start).format("YYYY"),
                parseInt(moment(d.start).format("M")) + 1
              );
            }}
            firstDay={1}
            events={eventCalendar}
            eventClick={(arg) => {
              handleTooltipOpen(arg.event);
              setEventClick(arg.event.start);
              setEventInfo(arg.event);
            }}
            eventDrop={(info) => {
              setFirstDate(info.event.start);
              setEventDrop(info.event);
              handleEventChange(info.event);
              setEventInfo(info.event);
            }}
            eventOrder={["numberSession", "start_time"]}
          />
          <ModalEvent
            onHide={() => setIsVisibleModalEvent(false)}
            open={isVisibleModalEvent}
            eventInfo={eventInfo}
            actionDelete={() => {
              setIsVisibleModalEvent(false);
              onDeleteEvent(eventInfo);
            }}
            optionDate={optionDate}
            setSelectedFirstDate={setSelectedFirstDate}
            volunteerType={volunteerType}
            setVolunteerType={setVolunteerType}
            scheduleType={scheduleType}
            setScheduleType={setScheduleType}
            handleEditEvent={handleEditEvent}
            scheduleSession={scheduleSession}
            startTime={startTime}
            setStartTime={setStartTime}
            setScheduleSession={setScheduleSession}
            endTime={endTime}
            setEndTime={setEndTime}
            volunteerLocation={volunteerLocation}
            setVolunteerLocation={setVolunteerLocation}
            maxVolunteer={maxVolunteer}
            setMaxVolunteer={setMaxVolunteer}
            displayEvent={displayEvent}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            unitNumber={unitNumber}
            setUnitNumber={setUnitNumber}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            moment={moment}
          />
          <ModalInfo
            open={modalInfo.show}
            message={modalInfo.message}
            onHide={() => setModalInfo({ ...modalInfo, show: false })}
          />
        </div>
      </Card>
    </>
  );
}
