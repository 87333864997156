import React from "react";
import { Header } from "../components/Navbar/Navbar";
import { FormUpload } from "../components/FormUpload/FormUpload";

export function UploadPage() {
  return (
    <>
      <Header />
      <FormUpload />
    </>
  );
}
