import React from 'react'
import {
    Route,
    Redirect
  } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";
import { ContentRoute } from "../../_metronic/layout";

  
function AuthRoute({ children, content = false, ...rest }) {
    const { isAuthorized } = useSelector(
      ({ auth }) => ({
        isAuthorized: auth.user != null,
      }),
      shallowEqual
    );
    return (
      <>
        {isAuthorized
           ? (
               content ? <ContentRoute {...rest}/> : <Route {...rest} />
           ) : (
            <Redirect
              to={{
                pathname: '/auth/login',
                // state: { from: location }
              }}
            />
           )
        }
      </>
    );
  }

  export default AuthRoute;