import { all } from "redux-saga/effects";
import watchAttendance from "../app/modules/Attendance/redux/attendanceSaga";
import watchAuth from "../app/modules/Auth/redux/authSaga";
import watchContact from "../app/modules/Contact/redux/contactSaga";
import watchDashboard from "../app/modules/Dashboard/redux/dashboardSaga";
import watchPE from "../app/modules/ProgrammeEvents/redux/peSaga";
import watchSchedule from "../app/modules/Schedule/redux/scheduleSaga";
import watchTimesheet from "../app/modules/Timesheet/redux/timesheetSaga";
import watchVR from "../app/modules/VolunteerRequest/redux/vrSaga";

export function* rootSaga(){
  yield all([
    watchAttendance(),
    watchAuth(),
    watchContact(),
    watchDashboard(),
    watchPE(),
    watchSchedule(),
    watchTimesheet(),
    watchVR()
  ]);
}