import React from "react";
import Header from "../../Donation/component/Navbar";
import TitleImage from "../../Donation/component/TitleImage";
import { CostumizeStepper } from "../component/Stepper";
import { CardSignUp } from "../component/Card";
import Footer from "../../Donation/component/Footer";

export function SignupPage() {
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <Header />
        <TitleImage
          image={
            "http://foodfromtheheart.sg/assets/getinvolved/form-mast/summary.jpg"
          }
          title={"Volunteer Sign-Up"}
        />
        <CostumizeStepper steps={5} />
        <CardSignUp />
        <Footer />
      </div>
    </>
  );
}
