export const CONTACT = {
  POSTALCODE_REQUEST: 'CONTACT_POSTALCODE_REQUEST',
  POSTALCODE_SUCCESS: 'CONTACT_POSTALCODE_SUCCESS',
  POSTALCODE_ERROR: 'CONTACT_POSTALCODE_ERROR',
  POSTALCODE_RESET: 'CONTACT_POSTALCODE_RESET',

  COUNTRY_REQUEST: 'CONTACT_COUNTRY_REQUEST',
  COUNTRY_SUCCESS: 'CONTACT_COUNTRY_SUCCESS',
  COUNTRY_ERROR: 'CONTACT_COUNTRY_ERROR',
  COUNTRY_RESET: 'CONTACT_COUNTRY_RESET',
}