import React, { lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { SignupPage } from "./modules/Signup/pages/SignupPage";
import { YourDetailsPage } from "./modules/Your-Details/pages/YourDetailsPage";
import { SubmissionSuccessPage } from "./modules/Your-Details/pages/SubmissionSuccessPage";
import { UploadPage } from "./modules/Upload/pages/UploadPage";
import { DonationListPage } from "./modules/DonationList/Pages/DonationList";
import { DonationDetailPage } from "./modules/DonationDetail/Pages/DonationDetail";
import { TimesheetAttendanceVr } from "./modules/Timesheet/pages/AttendanceVrPage";
import SSORoute from "./component/ssoRoute";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const DonationPage = lazy(() =>
    import("./modules/Donation/pages/DonationPage")
  );
  const DonationReviewPage = lazy(() =>
    import("./modules/Donation/pages/DonationReviewPage")
  );
  const DonationThanksPage = lazy(() =>
    import("./modules/Donation/pages/DonationThanksPage")
  );

  return (
    <Switch>
      <Route exact path="/" component={DonationPage} />
      <Route path="/donate-review" component={DonationReviewPage} />
      <Route path="/donate" component={DonationPage} />
      <Route path="/donate-result" component={DonationThanksPage} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/your-details" component={YourDetailsPage} />
      <Route path="/submission-success" component={SubmissionSuccessPage} />
      <SSORoute path="/upload" component={UploadPage} />
      <Route
        path="/donation-list/detail/:uploadId"
        component={DonationDetailPage}
      />
      <Route path="/donation-list" component={DonationListPage} />
      <Route path="/vems/timesheet" component={TimesheetAttendanceVr} />
      <Route path="/auth/login" component={AuthPage} />
      <Route path="/auth/forgot-password" component={AuthPage} />
      <Route path="/auth/login-admin" component={AuthPage} />
      <Route path="/auth/reset-password/:token" component={AuthPage} />
      {/* {!isAuthorized ? (
        <Route>
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )} */}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {/* {!isAuthorized ? (
        <Redirect to="/auth/login" />
        // <Route path="/" exact component={DonationPage} />
      ) : (
      )} */}
        <Layout isAuthorized={isAuthorized}>
          <BasePage />
        </Layout>

    </Switch>
  );
}
