import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import { Card, Form, Button } from "react-bootstrap";
import "../../style/FormUpload.css";
import * as donationRepo from "../../../../datasource/donationRepo";
import { useHistory } from "react-router-dom";

export function FormUpload() {
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(null);
  const [channel, setChannel] = useState('Give Asia');
  const [loading, setLoading] = useState(false);
  const [delimiter, setDelimiter] = useState(',')

  function convertCSV(){
    const payload = new FormData();
    payload.append('file', uploadFile)
    payload.append('fileType', channel)
    payload.append('delimiter', delimiter)
    setLoading(true)
    donationRepo
      .apiUploadCsv(payload)
      .then(({data}) => {
        Swal.fire(
          'Data Uploaded!','',
          'success'
        )
        setUploadFile(null)
        setChannel('Give Asia')
        setDelimiter(',')
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setLoading(false))
  }

  return (
    <div className="container-form-upload">
      <h2>Upload Data</h2>
      <hr />
      <Card style={{ borderColor: "1px solid #333" }}>
        <Card.Body style={{ padding: "15px" }}>
          <p>Choose File *)</p>
          <div style={{ marginBottom: "10px" }}>
            <Form.File id="formcheck-api-regular">
              <Form.File.Input 
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                onChange={(e) => setUploadFile(e.target.files[0])}
              />
            </Form.File>
          </div>
          <p style={{ margin: "2px" }}>Channel</p>
          <Form.Group
            style={{ width: "50%" }}
            controlId="exampleForm.SelectCustomSizeSm"
          >
            <Form.Control as="select" size="md" custom onChange={(e) => setChannel(e.target.value)}>
              <option value="Give Asia">Give Asia</option>
              <option value="Giving SG">Giving SG</option>
              <option value="Saleswork">Saleswork</option>
              {/* <option value="UK Online">UK Online</option>
              <option value="Your Cause">Your Cause</option>
              <option value="FFTH Website">FFTH Website</option>
              <option value="3rd Party Fundraiser">3rd Party Fundraiser</option> */}
            </Form.Control>
          </Form.Group>

          <p style={{ margin: "2px" }}>Csv File Delimiter</p>
          <Form.Group
            style={{ width: "50%" }}
            controlId="exampleForm.SelectCustomSizeSm"
          >
            <Form.Control as="select" size="md" custom onChange={(e) => setDelimiter(e.target.value)}>
              <option value=","> , (Comma)</option>
              <option value=";"> ; (Semicolon)</option>
            </Form.Control>
          </Form.Group>
          <div>
            {" "}
            <span className="info-upload">
              *) Only accept CSV(Comma Separated Value)
            </span>
          </div>
          <Button
            style={{
              backgroundColor: "#337ab7",
              borderColor: "#2e6da4",
              width: "70px",
              marginTop: "15px",
              fontFamily: "Helvetica Neue",
            }}
            className="submit-upload"
            onClick={convertCSV}
          >
            {loading && <span className="ml-3 spinner spinner-white"></span>}
             Submit
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
