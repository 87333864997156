import React, { useEffect } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import LoginSso from "./LoginSso";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  const history = useHistory();
  const { isAuthorized, role } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      role: auth.user?.role
    }),
    shallowEqual
  );

  useEffect(() => {
    const location = history.location.pathname
    if(location === '/auth/login-admin' && role !== "volunteer officer"){
    }else{
      if (isAuthorized) {
        history.push("/dashboard");
      }
    }
  }, [isAuthorized]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/ffth/login-bg.jpeg"
            )})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            filter: "blur(4px)",
            position: "absolute",
          }}
        />
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute path="/auth/login-admin" component={LoginSso} />
              <ContentRoute
                path="/auth/registration"
                component={Registration}
              />
              <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <ContentRoute
                path="/auth/reset-password/:token"
                component={ResetPassword}
              />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2 text-center">
              &copy; 2021 NKF. Developed by Interaktiv Technology. All rights
              reserved
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
