/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { ModalContactUs } from "./ModalContactUs";

export function StickyToolbar() {
  const authReducer = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {
        authReducer?.user?.role !== "volunteer officer" &&
        <>
          <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="layout-tooltip">Contact Us</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <Link
                  onClick={()=>setShowModal(true)}
                  className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                >
                  <i className="flaticon2-telegram-logo"></i>
                </Link>
              </li>
            </OverlayTrigger>
          </ul>

          <ModalContactUs 
            show={showModal} 
            handleClose={()=>setShowModal(false)} 
          />
        </>
      }
    </>
  );
}