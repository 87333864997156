import moment from 'moment';
import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function BasicInfoForm({Assignment_Status__c}){
  const dataPE = useSelector(state => state.pe.peDetail?.data?.programmeEvent);
  // const dataParticipant = useSelector(state => state.pe.peDetail?.data?.participant);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Programme/Event Name</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={dataPE?.Name} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Participant Status</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={Assignment_Status__c ? Assignment_Status__c : 'Waiting for Response'} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Venue of Event or Mobilisation</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={dataPE?.Venue_of_Event_or_Mobilisation__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Frequency</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={dataPE?.Frequency__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Programme Description</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={dataPE?.Programme_Description__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Start Date/Time</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={moment(dataPE?.Start_Date_Time__c).format('DD/MM/YYYY, hh:MM A')} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Event Attire</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={dataPE?.Event_Attire__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>End Date/Time</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={moment(dataPE?.End_Date_Time__c).format('DD/MM/YYYY, hh:MM A')} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}