import { methodService, apiService } from "./apiService";

export const URL = {
  BASE_CONTACT: "/contacts",
  BASE_POSTALCODE: "/postalcodes",
};

export function apiRegisterContact(data, query) {
  return apiService(
    URL.BASE_CONTACT + `${query}`,
    methodService.POST,
    data,
    null
  );
}

export function apiChangePassword(data) {
  return apiService(
    URL.BASE_CONTACT + "/password",
    methodService.PATCH,
    data,
    null
  );
}

export function apiChangeProfile(data) {
  return apiService(
    URL.BASE_CONTACT + "/update-profile",
    methodService.POST,
    data,
    null
  );
}

export function apiForgotPassword(data) {
  return apiService(
    URL.BASE_CONTACT + "/forget-password",
    methodService.POST,
    data,
    null
  );
}

export function apiResetPassword(data, token) {
  return apiService(
    URL.BASE_CONTACT + `/forget-password/verifyToken/${token}`,
    methodService.PATCH,
    data,
    null
  );
}

export function apiPostalcode(data) {
  return apiService(
    URL.BASE_POSTALCODE + `/${data?.param}`,
    methodService.GET,
    null,
    null
  );
}

export function apiCountry() {
  return apiService(
    URL.BASE_CONTACT + `/countries`,
    methodService.GET,
    null,
    null
  );
}
