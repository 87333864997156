export const PROGRAMME_EVENTS = {
  TABLE_REQUEST: "PROGRAMME_EVENTS_TABLE_REQUEST",
  TABLE_SUCCESS: "PROGRAMME_EVENTS_TABLE_SUCCESS",
  TABLE_ERROR: "PROGRAMME_EVENTS_TABLE_ERROR",
  TABLE_RESET: "PROGRAMME_EVENTS_TABLE_RESET",

  DETAIL_REQUEST: "PROGRAMME_EVENTS_DETAIL_REQUEST",
  DETAIL_SUCCESS: "PROGRAMME_EVENTS_DETAIL_SUCCESS",
  DETAIL_ERROR: "PROGRAMME_EVENTS_DETAIL_ERROR",
  DETAIL_RESET: "PROGRAMME_EVENTS_DETAIL_RESET"
}