import { methodService, apiService } from './apiService';

export const URL = {
  BASE_SCHEDULE_SESSION: '/volunteerSessions',
}

export function apiGenerateSession(data) {
  return apiService(
    URL.BASE_SCHEDULE_SESSION+'/bulkInsert',
    methodService.POST,
    data,
    null
  );
}