import React from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BiCaretDown, BiCoinStack } from "react-icons/bi";
import { FaCloud, FaSignOutAlt } from "react-icons/fa";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { GoDatabase } from "react-icons/go";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../style/Navbar.css";

export function Header() {
  const history = useHistory();
  return (
    <>
      <Navbar
        bg="light"
        variant="light"
        className="container-navbar"
        style={{ paddingLeft: "25px" }}
      >
        <Navbar.Brand className="ffth-logo" href="/dashboard">
          <LazyLoadImage
            src={toAbsoluteUrl("/media/ffth/logo.png")}
            style={{ maxWidth: "150px" }}
          />
        </Navbar.Brand>
        <Nav className="mr-auto container-link d-flex">
          <Nav.Link className="link-navbar" href="/upload">
            <FaCloud style={{ marginRight: "3px" }} />
            Importation Page
          </Nav.Link>
          <BiCoinStack style={{ marginTop: "10px" }} />
          <NavDropdown
            style={{ fontFamily: "Helvetica Neue" }}
            title="Temporary Data"
          >
            {/* <NavDropdown.Item href="/donation-list">
              Temporary Data - All
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/donation-list#give_asia">
              Temporary Data - Give Asia
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#giving_sg">
              Temporary Data - Giving Sg
            </NavDropdown.Item>
            <NavDropdown.Item href="/donation-list#saleswork">
              Temporary Data - Saleswork
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link className="link-navbar" href="#features">
            <BiCoinStack style={{ marginRight: "3px" }} />
            Temporary Data
            <BiCaretDown />
          </Nav.Link> */}
          <Nav.Link className="link-navbar" href="#pricing">
            <FaSignOutAlt style={{ marginRight: "3px" }} />
            Logout
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}
