import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as vrAction from './vrAction';
import * as vrRepo from '../../../datasource/vrRepo';

function* getTable(data){
  try {
    const res = yield call(vrRepo.apiVRTable, data.payload);
    yield put(vrAction.vrTableSuccess(res));
  } catch(err) {
    yield put(vrAction.vrTableError(err));
  }
}

function* getTableAdmin(data){
  try {
    const res = yield call(vrRepo.apiVRTableAdmin, data.payload);
    yield put(vrAction.vrTableAdminSuccess(res));
  } catch(err) {
    yield put(vrAction.vrTableAdminError(err));
  }
}

function* getDetail(data){
  try {
    const res = yield call(vrRepo.apiVRDetail, data.payload);
    yield put(vrAction.vrDetailSuccess(res));
  } catch(err) {
    yield put(vrAction.vrDetailError(err))
  }
}

function* vrRegister(data){
  try {
    const res = yield call(vrRepo.apiVRRegister, data.payload);
    yield put(vrAction.vrRegisterSuccess(res));
  } catch(err) {
    yield put(vrAction.vrRegisterError(err))
  }
}

function* vrUploadAssignedOrg(data){
  try {
    const res = yield call(vrRepo.apiVrUploadAssignedOrg, data.payload);
    yield put(vrAction.vrUploadAssignedOrgSuccess(res));
  } catch(err) {
    yield put(vrAction.vrUploadAssignedOrgError(err))
  }
}

function* vrDeleteAssignedOrg(data){
  try {
    const res = yield call(vrRepo.apiVrDeleteAssignedOrg, data.payload);
    yield put(vrAction.vrDeleteAssignedOrgSuccess(res));
  } catch(err) {
    yield put(vrAction.vrDeleteAssignedOrgError(err))
  }
}

function* vrWithdraw(data){
  try {
    const res = yield call(vrRepo.apiVRWithdraw, data.payload);
    yield put(vrAction.vrWithdrawSuccess(res));
  } catch(err) {
    yield put(vrAction.vrWithdrawError(err))
  }
}

function* getSession(data){
  try {
    const res = yield call(vrRepo.apiVrSession, data.payload);
    yield put(vrAction.vrSessionSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionError(err))
  }
}

function* getSessionCalendar(data){
  try {
    const res = yield call(vrRepo.apiVrSessionCalendar, data.payload);
    yield put(vrAction.vrSessionCalendarSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionCalendarError(err))
  }
}

function* getSessionCalendarAdmin(data){
  try {
    const res = yield call(vrRepo.apiVrSessionCalendarAdmin, data.payload);
    yield put(vrAction.vrSessionCalendarAdminSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionCalendarAdminError(err))
  }
}

function* getSessionDetail(data){
  try {
    const res = yield call(vrRepo.apiVrSessionDetail, data.payload);
    yield put(vrAction.vrSessionDetailSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionDetailError(err))
  }
}

function* sessionRegister(data){
  try {
    const res = yield call(vrRepo.apiVrSessionRegister, data.payload);
    yield put(vrAction.vrSessionRegisterSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionRegisterError(err))
  }
}

function* sessionUploadAssignedOrg(data){
  try {
    const res = yield call(vrRepo.apiVrSessionUploadAssignedOrg, data.payload);
    yield put(vrAction.vrSessionUploadAssignedOrgSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionUploadAssignedOrgError(err))
  }
}

function* sessionDeleteAssignedOrg(data){
  try {
    const res = yield call(vrRepo.apiVrSessionDeleteAssignedOrg, data.payload);
    yield put(vrAction.vrSessionDeleteAssignedOrgSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionDeleteAssignedOrgError(err))
  }
}

function* sessionWithdraw(data){
  try {
    const res = yield call(vrRepo.apiVrSessionWithdraw, data.payload);
    yield put(vrAction.vrSessionWithdrawSuccess(res));
  } catch(err) {
    yield put(vrAction.vrSessionWithdrawError(err))
  }
}

export default function* watchVR() {
  yield all([
    takeLatest(actionType.VR.TABLE_REQUEST, getTable),
    takeLatest(actionType.VR.TABLE_ADMIN_REQUEST, getTableAdmin),
    takeLatest(actionType.VR.DETAIL_REQUEST, getDetail),
    takeLatest(actionType.VR.REGISTER_REQUEST, vrRegister),
    takeLatest(actionType.VR.UPLOAD_ASSIGNED_ORG_REQUEST, vrUploadAssignedOrg),
    takeLatest(actionType.VR.DELETE_ASSIGNED_ORG_REQUEST, vrDeleteAssignedOrg),
    takeLatest(actionType.VR.WITHDRAW_REQUEST, vrWithdraw),
    takeLatest(actionType.VR.SESSION_REQUEST, getSession),
    takeLatest(actionType.VR.SESSION_CALENDAR_REQUEST, getSessionCalendar),
    takeLatest(actionType.VR.SESSION_CALENDAR_ADMIN_REQUEST, getSessionCalendarAdmin),
    takeLatest(actionType.VR.SESSION_DETAIL_REQUEST, getSessionDetail),
    takeLatest(actionType.VR.SESSION_REGISTER_REQUEST, sessionRegister),
    takeLatest(actionType.VR.SESSION_UPLOAD_ASSIGNED_ORG_REQUEST, sessionUploadAssignedOrg),
    takeLatest(actionType.VR.SESSION_DELETE_ASSIGNED_ORG_REQUEST, sessionDeleteAssignedOrg),
    takeLatest(actionType.VR.SESSION_WITHDRAW_REQUEST, sessionWithdraw),
  ])
}