export const VR = {
  TABLE_REQUEST: "VR_TABLE_REQUEST",
  TABLE_SUCCESS: "VR_TABLE_SUCCESS",
  TABLE_ERROR: "VR_TABLE_ERROR",
  TABLE_RESET: "VR_TABLE_RESET",

  TABLE_ADMIN_REQUEST: "VR_TABLE_ADMIN_REQUEST",
  TABLE_ADMIN_SUCCESS: "VR_TABLE_ADMIN_SUCCESS",
  TABLE_ADMIN_ERROR: "VR_TABLE_ADMIN_ERROR",
  TABLE_ADMIN_RESET: "VR_TABLE_ADMIN_RESET",

  DETAIL_REQUEST: "VR_DETAIL_REQUEST",
  DETAIL_SUCCESS: "VR_DETAIL_SUCCESS",
  DETAIL_ERROR: "VR_DETAIL_ERROR",
  DETAIL_RESET: "VR_DETAIL_RESET",

  REGISTER_REQUEST: "VR_REGISTER_REQUEST",
  REGISTER_SUCCESS: "VR_REGISTER_SUCCESS",
  REGISTER_ERROR: "VR_REGISTER_ERROR",
  REGISTER_RESET: "VR_REGISTER_RESET",

  UPLOAD_ASSIGNED_ORG_REQUEST: "VR_UPLOAD_ASSIGNED_ORG_REQUEST",
  UPLOAD_ASSIGNED_ORG_SUCCESS: "VR_UPLOAD_ASSIGNED_ORG_SUCCESS",
  UPLOAD_ASSIGNED_ORG_ERROR: "VR_UPLOAD_ASSIGNED_ORG_ERROR",
  UPLOAD_ASSIGNED_ORG_RESET: "VR_UPLOAD_ASSIGNED_ORG_RESET",

  DELETE_ASSIGNED_ORG_REQUEST: "VR_DELETE_ASSIGNED_ORG_REQUEST",
  DELETE_ASSIGNED_ORG_SUCCESS: "VR_DELETE_ASSIGNED_ORG_SUCCESS",
  DELETE_ASSIGNED_ORG_ERROR: "VR_DELETE_ASSIGNED_ORG_ERROR",
  DELETE_ASSIGNED_ORG_RESET: "VR_DELETE_ASSIGNED_ORG_RESET",

  WITHDRAW_REQUEST: "VR_WITHDRAW_REQUEST",
  WITHDRAW_SUCCESS: "VR_WITHDRAW_SUCCESS",
  WITHDRAW_ERROR: "VR_WITHDRAW_ERROR",
  WITHDRAW_RESET: "VR_WITHDRAW_RESET",

  SESSION_REQUEST: "VR_SESSION_REQUEST",
  SESSION_SUCCESS: "VR_SESSION_SUCCESS",
  SESSION_ERROR: "VR_SESSION_ERROR",
  SESSION_RESET: "VR_SESSION_RESET",
  
  SESSION_CALENDAR_REQUEST: "VR_SESSION_CALENDAR_REQUEST",
  SESSION_CALENDAR_SUCCESS: "VR_SESSION_CALENDAR_SUCCESS",
  SESSION_CALENDAR_ERROR: "VR_SESSION_CALENDAR_ERROR",
  SESSION_CALENDAR_RESET: "VR_SESSION_CALENDAR_RESET",

  SESSION_CALENDAR_ADMIN_REQUEST: "VR_SESSION_CALENDAR_ADMIN_REQUEST",
  SESSION_CALENDAR_ADMIN_SUCCESS: "VR_SESSION_CALENDAR_ADMIN_SUCCESS",
  SESSION_CALENDAR_ADMIN_ERROR: "VR_SESSION_CALENDAR_ADMIN_ERROR",
  SESSION_CALENDAR_ADMIN_RESET: "VR_SESSION_CALENDAR_ADMIN_RESET",

  SESSION_DETAIL_REQUEST: "VR_SESSION_DETAIL_REQUEST",
  SESSION_DETAIL_SUCCESS: "VR_SESSION_DETAIL_SUCCESS",
  SESSION_DETAIL_ERROR: "VR_SESSION_DETAIL_ERROR",
  SESSION_DETAIL_RESET: "VR_SESSION_DETAIL_RESET",

  SESSION_REGISTER_REQUEST: "VR_SESSION_REGISTER_REQUEST",
  SESSION_REGISTER_SUCCESS: "VR_SESSION_REGISTER_SUCCESS",
  SESSION_REGISTER_ERROR: "VR_SESSION_REGISTER_ERROR",
  SESSION_REGISTER_RESET: "VR_SESSION_REGISTER_RESET",

  SESSION_UPLOAD_ASSIGNED_ORG_REQUEST: "VR_SESSION_UPLOAD_ASSIGNED_ORG_REQUEST",
  SESSION_UPLOAD_ASSIGNED_ORG_SUCCESS: "VR_SESSION_UPLOAD_ASSIGNED_ORG_SUCCESS",
  SESSION_UPLOAD_ASSIGNED_ORG_ERROR: "VR_SESSION_UPLOAD_ASSIGNED_ORG_ERROR",
  SESSION_UPLOAD_ASSIGNED_ORG_RESET: "VR_SESSION_UPLOAD_ASSIGNED_ORG_RESET",

  SESSION_DELETE_ASSIGNED_ORG_REQUEST: "VR_SESSION_DELETE_ASSIGNED_ORG_REQUEST",
  SESSION_DELETE_ASSIGNED_ORG_SUCCESS: "VR_SESSION_DELETE_ASSIGNED_ORG_SUCCESS",
  SESSION_DELETE_ASSIGNED_ORG_ERROR: "VR_SESSION_DELETE_ASSIGNED_ORG_ERROR",
  SESSION_DELETE_ASSIGNED_ORG_RESET: "VR_SESSION_DELETE_ASSIGNED_ORG_RESET",

  SESSION_WITHDRAW_REQUEST: "VR_SESSION_WITHDRAW_REQUEST",
  SESSION_WITHDRAW_SUCCESS: "VR_SESSION_WITHDRAW_SUCCESS",
  SESSION_WITHDRAW_ERROR: "VR_SESSION_WITHDRAW_ERROR",
  SESSION_WITHDRAW_RESET: "VR_SESSION_WITHDRAW_RESET",
}