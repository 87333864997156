import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: null,
  peDetail: null
}

const peTableRequest = state => ({
  ...state,
  isLoading: true
})
const peTableSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: payload
})
const peTableError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peTableReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: null
})

const peDetailRequest = state => ({
  ...state,
  isLoading: true
})
const peDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peDetail: payload
})
const peDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peDetail: null
})

export const peReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROGRAMME_EVENTS.TABLE_REQUEST:
      return peTableRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_SUCCESS:
      return peTableSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_ERROR:
      return peTableError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_RESET:
      return peTableReset(state, action.payload);

    case actionType.PROGRAMME_EVENTS.DETAIL_REQUEST:
      return peDetailRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_SUCCESS:
      return peDetailSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_ERROR:
      return peDetailError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_RESET:
      return peDetailReset(state, action.payload);
  
    default:
      return state;
  }
}