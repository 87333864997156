import {combineReducers} from "redux";

import { attendanceReducer } from "../app/modules/Attendance/redux/attendanceReducer";
import { authReducer } from "../app/modules/Auth/redux/authReducer";
import { contactReducer } from "../app/modules/Contact/redux/contactReducer";
import { dashboardReducer } from "../app/modules/Dashboard/redux/dashboardReducer";
import { peReducer } from "../app/modules/ProgrammeEvents/redux/peReducer";
import { scheduleReducer } from "../app/modules/Schedule/redux/scheduleReducer";
import { timesheetReducer } from "../app/modules/Timesheet/redux/timesheetReducer";
import { vrReducer } from "../app/modules/VolunteerRequest/redux/vrReducer";

export const rootReducer = combineReducers({
  attendance: attendanceReducer,
  auth: authReducer,
  contact: contactReducer,
  dashboard: dashboardReducer,
  pe: peReducer,
  schedule: scheduleReducer,
  timesheet: timesheetReducer,
  vr: vrReducer,
});