import { methodService, apiService } from './apiService';
import { apiServiceDonation } from './apiDonationService';

export const URL = {
  BASE_DONATION: '/donations',
}

export const apiGetDetailUpload = async (id) => {
  const { data } = await apiService(
    URL.BASE_DONATION + `/detail/${id}`,
    methodService.GET,
  );

  return data;
};

export function apiCreateDonationIndividual(data) {
  return apiServiceDonation(
    URL.BASE_DONATION,
    methodService.POST,
    data,
    null
  );
}

export function apiUpdateDonationStatus(data) {
  return apiServiceDonation(
    URL.BASE_DONATION+`/${data.donationID}`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiUpdateDonation(data) {
  return apiServiceDonation(
    URL.BASE_DONATION+`/${data.id}`,
    methodService.PUT,
    data,
    null
  )
}

export function apiUploadCsv(data) {
  return apiService(
    URL.BASE_DONATION+`/upload`,
    methodService.POST,
    data,
    null
  )
}

export function apiUploadToSalesforce(data) {
  return apiService(
    URL.BASE_DONATION+`/uploadToSalesforce`,
    methodService.POST,
    data,
    null
  )
}

export function apiUploadList(data) {
  return apiService(
    URL.BASE_DONATION+`/upload`,
    methodService.GET,
    null,
    data
  );
}
export function apiDeleteUpload(id) {
  return apiService(
    URL.BASE_DONATION+`/upload/${id}`,
    methodService.DELETE,
  );
}

export const apiDeleteSelectedUpload = async (dataDelete) => {
  const { data } = await apiService(
    URL.BASE_DONATION + `/upload`,
    methodService.DELETE,
    dataDelete,
  );
  return data;
};

export function apiDetailUpload(id) {
  return apiService(
    URL.BASE_DONATION+`/upload/${id}`,
    methodService.GET,
  );
}

export function apiUpdateDonationUpload(data, id) {
  return apiService(
    URL.BASE_DONATION+`/upload/${id}`,
    methodService.PUT,
    data,
    null
  )
}

export function apiDeleteUploadSelected(data) {
  return apiService(
    URL.BASE_DONATION+`/upload`,
    methodService.DELETE,
    data
  );
}

export function apiUploadCreate(data) {
  return apiService(
    URL.BASE_DONATION+`/upload/create`,
    methodService.POST,
    data,
    null
  )
}