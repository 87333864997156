import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as dashboardAction from './dashboardAction';
import * as dashboardRepo from '../../../datasource/dashboardRepo';

function* getChatter(){
  try {
    const res = yield call(dashboardRepo.apiChatter);
    yield put(dashboardAction.chatterSuccess(res));
  } catch(err){
    yield put(dashboardAction.chatterError(err));
  }
}

function* myActivity(data){
  try {
    const res = yield call(dashboardRepo.apiMyActivity, data.payload);
    yield put(dashboardAction.myActivitySuccess(res));
  } catch(err){
    yield put(dashboardAction.myActivityError(err));
  }
}

function* contactUs(data){
  try {
    const res = yield call(dashboardRepo.apiContactUs, data.payload);
    yield put(dashboardAction.contactUsSuccess(res));
  } catch(err){
    yield put(dashboardAction.contactUsError(err));
  }
}

export default function* watchDashboard() {
  yield all([
    takeLatest(actionType.DASHBOARD.CHATTER_REQUEST, getChatter),
    takeLatest(actionType.DASHBOARD.MY_ACTIVITY_REQUEST, myActivity),
    takeLatest(actionType.DASHBOARD.CONTACT_US_REQUEST, contactUs)
  ])
}