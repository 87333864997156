import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import { AttendanceTable } from './AttendanceTable';
// import { AttendanceFilter } from './AttendanceFilter';

export function AttendanceCard({UIEvent}){
  const [searchName, setSearchName] = useState('');
  return(
    <>
      <Card>
        <CardHeader>
          <div className="card-title">
            <h3 className="card-label" style={{minWidth: '9vw'}}>List Data</h3>
          </div>
        </CardHeader>
        {/* <AttendanceFilter searchName={searchName} setSearchName={(val)=>setSearchName(val)}/> */}
        <CardBody>
          <AttendanceTable UIEvent={UIEvent} searchName={searchName}/>
        </CardBody>
      </Card>
    </>
  )
}