import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { IoMdCloudUpload, IoMdTrash } from "react-icons/io";
import { get } from "lodash"
import * as donationRepo from "../../../../datasource/donationRepo";
import { ImBin } from "react-icons/im";
import Swal from 'sweetalert2'
import { useLocation, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import "./TableDonationList.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import patchFilterFactory from '../helpers/patchFilter'

import { ModalFilter } from "../ModalFilter/ModalFilter";
import { ModalSort } from "../ModalSort/ModalSort";
import { ModalCreate } from "../ModalCreate/ModalCreate";

const customAmount = {
  give_asia: "donation",
  axs: 'donation_amount'
};

export function TableDonationList() {
  const [dataTable, setDataTable] = useState([]);
  const [columns, setColumns] = useState([])
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalSort, setShowModalSort] = useState(false);
  const [toggleChecked, setToggleChecked] = useState();
  const [modalIsHidden, setModalIsHidden] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [dataKeys, setDataKeys] = useState([])
  const [formValue, setFormValue] = useState({})
  const location = useLocation();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [loadingSalesforce, setLoadingSalesforce] = useState(false);

  const { ToggleList } = ColumnToggle;

  useEffect(() => {
    fetchData()
  }, [location]);

  function sortColumns(object, type, cache){
    const keys = Object.keys(object)
    const priority = {
      // giving_sg: ['salesforce_id','donation_date','salutation','donor_name','nric_fin','email','amount','payment_method']
    }
    let appliedPriority = priority[type] || []
    if(cache && cache.length > 0){
      appliedPriority = cache.filter(x => keys.includes(x));
    }

    if(appliedPriority.length > 0){
      let newList = keys.filter(x => !appliedPriority.includes(x))
      for(let i = appliedPriority.length - 1 ; i >= 0 ; i--){
        newList.unshift(appliedPriority[i])
      }
      return newList
    }else{
      return keys
    }
  }

  const factory = patchFilterFactory(filterFactory, (data) => {
    setFilterData(prevData => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return data
      }

      return prevData
    })
  })

  const sortCaret = (order, column) => {
    if (!order) return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
        <SVG src={`${process.env.PUBLIC_URL}/svg/Down-2.svg`}/>
      </span>
    );
    else if (order === "asc")
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={`${process.env.PUBLIC_URL}/svg/Up-2.svg`}/>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={`${process.env.PUBLIC_URL}/svg/Down-2.svg`}/>
        </span>
      );
    return null;
  };

  const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
    (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
  );

  async function fetchData(){
    const file_type = get(location,'hash',' ').substr(1)
    try {
      const { data } = await donationRepo.apiUploadList({file_type, limit: 999})
      let columns = [];
      if(Array.isArray(data.data)){
        data.data.forEach(x => {
          let keys = sortColumns(x, file_type, JSON.parse(localStorage.getItem(`${file_type}-column-list`)))
          setDataKeys(keys.filter(key => !['_id','__v','file_type'].includes(key)))
          keys.forEach(key => {
            if (columns.filter(x => x.dataField === key).length < 1 && (!['_id','__v','file_type'].includes(key))) {
              let columnName = key.split('_')
              let capital = columnName.map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ')
              columns.push({
                dataField: key,
                text: capital,
                sort: true,
                filter: textFilter(),
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                  onClick: (e, column, columnIndex, row, rowIndex) => { 
                    history.push({
                      pathname: `/donation-list/detail/${row._id}`,
                    })
                   },
                }
              })
            }
          })
        })
      }

      columns.push({
        text: "Delete",
        formatter: (cell, row) => (
          <div style={{ width: "25%" }}>
            <Button
              variant="danger"
              style={{
                width: "40px",
                height: "35px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999
              }}
              onClick={() =>
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteRow(row._id)
                  }
                })
              }
            >
              <ImBin />
            </Button>
          </div>
        ),
        // sortCaret: sortCaret,
      })
      if(data.data.length > 0)
      {
        setColumns(columns)
        setDataTable(data.data)
      }else{
        setDataTable([])
        setColumns([])
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteRow(id){
    try {
      await donationRepo.apiDeleteUpload(id)
      fetchData()
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function saveToSalesforce(){
    try {
      setLoadingSalesforce(true)
      const request = {
        data: selectedRows,
        file_type: get(location,'hash',' ').substr(1).split('_').join(' ')
      }
      const {data : {response}} = await donationRepo.apiUploadToSalesforce(request)

      let errMsg = '';
      response.forEach(x => {
        if (!x.success) {
          errMsg += '<li class="response-error">Error:' + x.errors + '</li>'
        } else {
          errMsg += '<li class="response-success">Data Uploaded!</li>'
        }
      });
      if (response.filter(x => !x.success).length > 0) {
        Swal.fire({
          title: `oops there is some error from salesforce`,
          icon: 'error',
          html:
            'Here is the report: <br/>' +
            '<ul> ' +
            errMsg +
            '</ul>',
        })
      } else {
        Swal.fire(
          'Saved!',
          'All Data has been saved to salesforce.',
          'success'
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      fetchData()
      setLoadingSalesforce(false)
    }
  }

  async function deleteSelected() {
    try {
      setLoadingSalesforce(true)
      const request = {
        data: selectedRows,
        file_type: get(location,'hash',' ').substr(1).split('_').join(' ')
      }
      const { response } = await donationRepo.apiDeleteUploadSelected(request)
      setSelectedRows([])

    } catch (error) {
      console.log(error)
    } finally {
      fetchData()
      setLoadingSalesforce(false)
    }
  }

  const handleOnSelect = (row, isSelect) => {
    if(isSelect){
      if(row.salesforce_id){
        setSelectedRows(prevState => (prevState.filter(x => x._id !== row._id)));
        setSelectedIndex(prevState => (prevState.filter(x => x !== row._id)));
      }else{
        setSelectedRows(prevState => ([...prevState, row]));
        setSelectedIndex(prevState => ([...prevState, row._id]));
      }
    }else{
      setSelectedRows(prevState => (prevState.filter(x => x._id !== row._id)));
      setSelectedIndex(prevState => (prevState.filter(x => x !== row._id)));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r._id);
    if (isSelect) {
      setSelectedIndex(ids)
      setSelectedRows(rows)
    } else {
      setSelectedIndex([])
      setSelectedRows([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    // clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selectedIndex,
  };

  const rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "transparent";
    } else {
      style.backgroundColor = "#f9f9f9";
    }
    style.borderTop = "none";
    style.whiteSpace = "nowrap";
    style.cursor = "pointer";

    return style;
  };

  const options = {
    custom: true,
    totalSize: dataTable.length,
  };

  // useEffect(() => {
  //   console.log(columnToggleProps.toggles);
  // }, [columnToggleProps.toggles]);

  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <div>
      {columns.map((column) => (
        <div className="mb-3">
          <Form.Check
            type="checkbox"
            defaultChecked={toggles ? "true" : "false"}
            label={column.text}
            key={column.dataField}
            onChange={() => onColumnToggle(column.dataField)}
          />
        </div>
      ))}
    </div>
  );

  function viewDetail() {
    let dataTable = {};
  }

  const handleShowModalFilter = () => {
    setShowModalFilter(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalFilter = () => {
    setShowModalFilter(false);
    // setModalIsHidden(true);
  };

  const handleShowModalSort = () => {
    setShowModalSort(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalSort = () => {
    setShowModalSort(false);
    // setModalIsHidden(true);
  };

  const handleShowModalCreate = () => {
    setShowModalCreate(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalCreate = () => {
    setShowModalCreate(false);
    // setModalIsHidden(true);
  };

  function changeValue(value, key){
    setFormValue(prevState => ({...prevState, [key]: value}))
  }

  async function submitCreate(){
    try {
      const file_type = get(location,'hash',' ').substr(1);
      let formData = {...formValue, file_type};
      await donationRepo.apiUploadCreate({data: formData})
      Swal.fire(
        'Data Created!',
        'Data Success Created!',
        'success'
      )
      setFormValue({})
      fetchData()
    } catch (error) {
      Swal.fire(
        'Error',
        `${error.message}`,
        'error'
      )
    }
  }

  function dollarToNumber(dollar){
    if(dollar && dollar.includes('$')){
      const result = dollar.replace(/\$/g, '')
      return Number(result)
    }else{
      return 0
    }
  }

  function totalAmount(data, param = "amount"){
    let amount = 0
    if(data.length > 0){
      amount = data.map(item => item[param]).reduce((prev, next) => {
        let firstVal = !isNaN(Number(prev)) ? Number(prev) : dollarToNumber(prev)
        let secondVal = !isNaN(Number(next)) ? Number(next) : dollarToNumber(next)
        return firstVal + secondVal
      });
    }
    return amount
  }

  return (
    <div className="container-donation-list">
    <div style={{ padding: '20px', backgroundColor: 'white'}}>
      <div style={{display: 'flex'}}>
        <h1 className="mb-5">Donation</h1>
        <div style={{
          display:'flex',
          flexDirection:'row',
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          visibility: (get(location,'hash',' ').substr(1) === '' || !selectedRows.length > 0 || !dataTable.length > 0) && 'hidden'
        }}>
          <Button
            style={{
              backgroundColor: "#5cb85c",
              borderColor: "#4cae4c",
              width: "205px",
              height: "35px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              visibility: (get(location,'hash',' ').substr(1) === '' || !selectedRows.length > 0 || !dataTable.length > 0) && 'hidden'
            }}
            disabled={loadingSalesforce}
            onClick={saveToSalesforce}
          >
              {loadingSalesforce && <span className="ml-1 spinner spinner-white"></span>}
            <div>
              <IoMdCloudUpload
                style={{
                  height: "18px",
                  width: "18px",
                  marginRight: "4px",
                }}
              />
              <span
                style={{
                  fontFamily: "Helvetica Neue",
                  margin: "0",
                  lineHeight: "1.5",
                }}
              >
                 Upload to Salesforce
              </span>
            </div>
          </Button>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#b85c5c",
                borderColor: "#ae4c4c",
                width: "170px",
                height: "35px",
                borderRadius: "4px",
                marginLeft: "5px",
                display:'flex',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={loadingSalesforce}
              onClick={() => Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this! (uploaded to sf data wont be delete)",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteSelected();
                }
              })}
            >
              {loadingSalesforce && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
              <div>
                <IoMdTrash
                  style={{
                    height: "18px",
                    width: "18px",
                    marginRight: "4px",
                  }}
                />
                <span
                  style={{
                    fontFamily: "Helvetica Neue",
                    margin: "0",
                    lineHeight: "1.5",
                  }}
                >
                  Delete Selected
                </span>
              </div>
            </Button>
          </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="success"
            style={{
              width: "130px",
              height: "35px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "right",
              visibility: (!dataTable.length > 0 ) && 'hidden'

            }}
            onClick={handleShowModalCreate}
          >
            <div>
              <span
                style={{
                  fontFamily: "Helvetica Neue",
                  margin: "0",
                  lineHeight: "1.5",
                }}
              >
                Add Record
              </span>
            </div>
          </Button> &nbsp;
          <Button
            style={{
              backgroundColor: "primary",
              width: "160px",
              height: "35px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "right",
              visibility: (!dataTable.length > 0 ) && 'hidden'
            }}
            onClick={handleShowModalSort}
          >
            <div>
              <span
                style={{
                  fontFamily: "Helvetica Neue",
                  margin: "0",
                  lineHeight: "1.5",
                }}
              >
                Reorder Columns
              </span>
            </div>
          </Button>
        </div>
      </div>

      {
        dataTable.length > 0 ?
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <Row>
                <Col lg={12} className="mb-3">
                  <SizePerPageDropdownStandalone className='size-per-page-button' {...paginationProps} />
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </Col>
              </Row>
              <ToolkitProvider
                keyField="_id"
                data={dataTable}
                columns={columns}
                columnToggle
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      keyField="_id"
                      data={dataTable}
                      columns={columns}
                      filter={filterFactory()}
                      filterPosition="top"
                      striped
                      condensed
                      rowStyle={rowStyle}
                      selectRow={selectRow}
                      pagination={paginationFactory(options)}
                      filter={factory()}
                      {...paginationTableProps}
                      // {...props.baseProps}
                    />
                    <ModalFilter
                      onHide={handleCloseModalFilter}
                      show={showModalFilter}
                      CustomToggleList={CustomToggleList}
                      columnToggleProps={props.columnToggleProps}
                      modalIsHidden={modalIsHidden}
                      setModalIsHidden={setModalIsHidden}
                      setToggleChecked={setToggleChecked}
                      toggleChecked={toggleChecked}
                    />
                    <ModalSort
                      onHide={handleCloseModalSort}
                      show={showModalSort}
                      sortList={{data: columns, dataSetter: setColumns}}
                      file_type={get(location,'hash',' ').substr(1)}
                      setDataKeys={setDataKeys}
                    />
                    <ModalCreate
                      onHide={handleCloseModalCreate}
                      show={showModalCreate}
                      data={dataKeys}
                      file_type={get(location,'hash',' ').substr(1)}
                      afterSubmit={() => fetchData()}
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Row className="total-record-container">
                <Col lg={4} className="items">
                  {`Record count (Total) = ${dataTable.length}`}<br/>
                  {`Record count (Filtered) =  ${filterData.length > 0 ? filterData.length : dataTable.length}`}
                </Col>
                <Col lg={4} className="items">
                  {`Amount (Total) = $${totalAmount(dataTable, customAmount[get(location, "hash", " ").substr(1)])}`}<br/>
                  {`Amount (Filtered) = $${filterData.length > 0 ? totalAmount(filterData, customAmount[get(location, "hash", " ").substr(1)]) : 
                  totalAmount(dataTable, customAmount[get(location, "hash", " ").substr(1)])}`}
                </Col>
              </Row>
            </div>
          )}
        </PaginationProvider>
        :
        <div className="empty-placeholder">
          <h1>No Donation</h1>
          <span>Please add data from importation page!</span>
        </div>
      }
    </div>
    </div>
  );
}
