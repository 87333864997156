import React, { useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as peAction from "../redux/peAction";

export function ProgrammeEventsTable({ searchName }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const peReducer = useSelector(state => state.pe, shallowEqual);

  const requestDataTableCallback = useCallback((page, per_page) => {
    dispatch(peAction.peTableRequest({
      name: searchName
    }));
  }, [dispatch, searchName])

  useEffect(() => {
    requestDataTableCallback();
  }, [requestDataTableCallback, searchName])

  const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const activityColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span role="button">
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const datetimeColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell !== null ? moment(cell).format('DD/MM/YYYY, hh:MM A') : 'N/A'}
      </span>
    )
  }
  
  const columns = [
    {
      dataField: "Name",
      text: "Programme/Event Name",
      formatter: activityColumnFormatter
    },
    {
      dataField: "Frequency__c",
      text: "Frequency",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Programme_Stage__c",
      text: "Status",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Start_Date_Time__c",
      text: "Start Date/Time",
      formatter: datetimeColumnFormatter
    }
  ];
  
  const paginationOptions = {
    custom: true,
    totalSize: 12,
    sizePerPageList: [
      { text: "10", value: 10 }
    ],
    sizePerPage: 10,
    page: 1,
  };

  function onTableChange(type, newState){
    console.log(type);
    console.log(newState);
    // let tmpMeta = {...tableContext.table.meta};
    // tmpMeta.page = newState.page;
    // tmpMeta.per_page = newState.sizePerPage;
    // tableContext.handleChangeField('meta', '', tmpMeta);
  }

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      history.push({
        pathname: `/programme-events/detail/${row.Id}`,
        data: row
      })
    }
  };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={peReducer.isLoading}
              paginationProps={paginationProps}
            > */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={peReducer.peTable === null ? [] : peReducer.peTable?.data?.programmeEvents}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={onTableChange}
                selectRow={selectRow}
                hover
                // {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
              </BootstrapTable>
            {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}
