import React from "react";
import { Header } from "../../Upload/components/Navbar/Navbar";
import { TableDonationList } from "../Components/TableDonationList/TableDonationList";
import "bootstrap/dist/css/bootstrap.min.css";

export function DonationListPage() {
  return (
    <>
      <Header />
      <TableDonationList />
    </>
  );
}
