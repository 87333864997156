import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { SchedulePage } from "./modules/Schedule/pages/SchedulePage";
import { CalendarPages } from "./modules/Calendar/pages/CalendarPages";
import { AttendancePage } from "./modules/Attendance/page/AttendancePage";
import { AttendanceDetailPage } from "./modules/Attendance/page/AttendanceDetailPage";
import { ProgrammeEventsPage } from "./modules/ProgrammeEvents/pages/ProgrammeEventsPage";
import { ProgrammeEventsDetailPage } from "./modules/ProgrammeEvents/pages/ProgrammeEventsDetail";
import { VolunteerRequestPage } from "./modules/VolunteerRequest/pages/VolunteerRequestPage";
import { VolunteerRequestDetailPage } from "./modules/VolunteerRequest/pages/VolunteerRequestDetail";
import { VolunteerSessionDetail } from "./modules/VolunteerRequest/pages/VolunteerSessionDetail";
import AuthRoute from './component/authRoute'
import { Layout } from "../_metronic/layout";


const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <AuthRoute path="/dashboard" component={DashboardPage} content />
        <AuthRoute path="/schedule" component={SchedulePage} content />
        <AuthRoute path="/calendar" component={CalendarPages} />
        <AuthRoute path="/attendance/detail" component={AttendanceDetailPage} />
        <AuthRoute path="/attendance" component={AttendancePage} />
        <AuthRoute
          path="/programme-events/detail"
          component={ProgrammeEventsDetailPage}
        />
        <AuthRoute path="/programme-events" component={ProgrammeEventsPage} />
        <AuthRoute
          path="/volunteer-request/detail/fp/session"
          component={VolunteerSessionDetail}
        />
        <AuthRoute
          path="/volunteer-request/detail"
          component={VolunteerRequestDetailPage}
        />
        <AuthRoute path="/volunteer-request" component={VolunteerRequestPage} />
        <AuthRoute path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
