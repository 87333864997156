import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as timesheetAction from './timesheetAction';
import * as timesheetRepo from '../../../datasource/timesheetRepo';

function* timesheetDetailVr(data) {
  try {
    const res = yield call(timesheetRepo.apiTimesheetVrDetail, data.payload);
    yield put(timesheetAction.detailVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.detailVrError(err.response.data));
    } else {
      yield put(timesheetAction.detailVrError(err));
    }
  }
}

function* timesheetValidateVr(data) {
  try {
    const res = yield call(timesheetRepo.apiTimesheetVrValidate, data.payload);
    yield put(timesheetAction.validateVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.validateVrError(err.response.data));
    } else {
      yield put(timesheetAction.validateVrError(err));
    }
  }
}

function* timesheetCheckinVr(data) {
  try {
    const res = yield call(timesheetRepo.apiTimesheetVrCheckin, data.payload);
    yield put(timesheetAction.checkinVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkinVrError(err.response.data));
    } else {
      yield put(timesheetAction.checkinVrError(err));
    }
  }
}

function* timesheetCheckoutVr(data) {
  try {
    const res = yield call(timesheetRepo.apiTimesheetVrCheckout, data.payload);
    yield put(timesheetAction.checkoutVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkoutVrError(err.response.data));
    } else {
      yield put(timesheetAction.checkoutVrError(err));
    }
  }
}


export default function* watchTimesheet() {
  yield all([
    takeLatest(actionType.TIMESHEET.DETAIL_VR_REQUEST, timesheetDetailVr),
    takeLatest(actionType.TIMESHEET.VALIDATE_VR_REQUEST, timesheetValidateVr),
    takeLatest(actionType.TIMESHEET.CHECKIN_VR_REQUEST, timesheetCheckinVr),
    takeLatest(actionType.TIMESHEET.CHECKOUT_VR_REQUEST, timesheetCheckoutVr)
  ])
}