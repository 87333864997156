import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";

export function BasicInfoForm(){
  const vrReducer = useSelector(state => state.vr);
  const [dataVr, setDataVr] = useState(null);
  const [isRegister, setIsRegister] = useState(null);
  
  useEffect(() => {
    if(vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrDetail !== null){
      setDataVr(vrReducer.vrDetail.data.volunteerRequest[0]);
      setIsRegister(vrReducer.vrDetail?.data?.isRegistred);
    }
    if(vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrSessionDetail !== null){
      setDataVr(vrReducer.vrSessionDetail.data.volunteerSession);
    }
  }, [vrReducer])

  function createQR(){
    return {
      __html: dataVr?.QR_Code__c
    }
  }

  return(
    <>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={()=>null}>
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#basic-info"
          >
            Basic Information
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <Form>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Assignee Status</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={isRegister ? "Accepted" : "Waiting for response"}
              />
            </Form.Group>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>VR Name</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={dataVr?.Name} 
              />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={moment(dataVr?.Start_Date__c).format("DD-MM-YYYY")}
              />
            </Form.Group>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Start Time</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={moment.utc(dataVr?.Start_Date__c+" "+dataVr?.Start_Time__c?.substring(0,5)).format("HH:mm A")} 
              />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>End Date</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={moment(dataVr?.End_Date__c).format("DD-MM-YYYY")}
              />
            </Form.Group>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>End Time</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={moment.utc(dataVr?.End_Date__c+" "+dataVr?.End_Time__c?.substring(0,5)).format("HH:mm A")} 
              />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Frequency Type</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={dataVr?.Frequency_Type__c}
              />
            </Form.Group>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>Number of Volunteer Required</Form.Label>
              <Form.Control 
                type="text"
                disabled
                value={dataVr?.Number_of_Volunteers_Required__c} 
              />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Row} className={'mb-0 mt-0'}>
            <Form.Group className={'col-lg-6'}>
              <Form.Label>QR Code</Form.Label>
              <br/>
              {
                <div dangerouslySetInnerHTML={createQR()}/>
              }
            </Form.Group>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}