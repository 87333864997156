import React, { useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { FaTrash, FaSalesforce } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import "./Modal.css";
import Select from "react-select";
import Datetime from "react-datetime";
import { useForm, Controller } from "react-hook-form";
import { toUpper } from "lodash-es";
import { ModalConfirm } from "./ModalConfirm";

export function ModalEvent({
  onHide,
  open,
  eventInfo,
  actionDelete,
  setSelectedFirstDate,
  selectedFirstDate,
  handleEditEvent,
  optionDate,
  setVolunteerType,
  volunteerType,
  scheduleType,
  setScheduleType,
  scheduleSession,
  startTime,
  setStartTime,
  setScheduleSession,
  endTime,
  setEndTime,
  volunteerLocation,
  setVolunteerLocation,
  maxVolunteer,
  setMaxVolunteer,
  postalCode,
  setPostalCode,
  unitNumber,
  setUnitNumber,
  isEdit,
  setIsEdit,
  moment,
}) {
  const [modalConfirm, setModalConfirm] = useState({
    isShow: false,
    title: "",
    onConfirm: () => null,
  });
  const [sfId, setSfId] = useState(null);
  const [volunteerTypeEdit, setVolunteerTypeEdit] = useState("");
  const [endTimeEdit, setEndTimeEdit] = useState();

  const volunteerTypeOptions = [
    { value: "individual", label: "Individual" },
    { value: "organisation", label: "Organisation" },
  ];

  const scheduleTypeOptions = [
    { value: "regular", label: "regular" },
    { value: "adhoc", label: "adhoc" },
  ];

  const sessionOptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
  ];

  const volunteerLocationOptions = [
    { value: "MP", label: "MP" },
    { value: "PR", label: "PR" },
    { value: "L4", label: "L4" },
    { value: "OTHER", label: "OTHER" },
  ];

  function closeModal() {
    onHide();
    setIsEdit(false);
  }

  React.useEffect(() => {
    if(open){
      if(eventInfo.sfId){
        setSfId(eventInfo.sfId);
      } else {
        setSfId(null);
      }
    }
  }, [open])

  return (
    <div>
      <Modal
        show={open}
        onHide={onHide}
        centered
        aria-labelledby="modal-event"
        className="modal-event"
      >
        <Modal.Body>
          <div
            className="container-header"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {
              sfId === null ?
              <>
                <GoPencil
                  className="edit-button"
                  onClick={() => setIsEdit(true)}
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    height: "25px",
                    width: "25px",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                />
                <FaTrash
                  className="delete-button"
                  onClick={() =>
                    setModalConfirm({
                      ...modalConfirm,
                      isShow: true,
                      title: "Confirm Delete Event",
                    })
                  }
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    height: "25px",
                    width: "25px",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                />
              </>
              :
              <FaSalesforce
                className="edit-button"
                onClick={() => {
                  window.open(`https://na117.salesforce.com/${sfId}`, "_blank");
                }}
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  height: "25px",
                  width: "25px",
                  marginLeft: "15px",
                  marginRight: "10px",
                }}
              />
            }
            <h1
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                marginLeft: "15px",
                marginRight: "10px",
                opacity: "0.3",
              }}
            >
              |
            </h1>
            <IoCloseSharp
              onClick={closeModal}
              className="buttonClose"
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                height: "30px",
                width: "30px",
              }}
            />
          </div>
          <div className="container-content" style={{ paddingLeft: "0px" }}>
            <Row>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: `${eventInfo.backgroundColor}`,
                  borderRadius: "50%",
                  marginRight: "20px",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "white" }}>
                  {eventInfo?.extendedProps?.volunteer_type
                    .substring(0, 1)
                    .toUpperCase()}
                </span>
              </div>
              <h4>{toUpper(eventInfo?.title)}</h4>
            </Row>
            <div style={{ marginLeft: "49px", marginTop: "10px" }}>
              {isEdit ? (
                <Row>
                  <p>Volunteer Type :</p>{" "}
                  <Col sm="5" lg="5" xl="5" className="mb-3 ml-1">
                    {" "}
                    <Select
                      className="basic-single"
                      styles={{ color: "black", width: "50%" }}
                      classNamePrefix="select"
                      isLoading={volunteerTypeOptions.length === 0}
                      onChange={(event) => {
                        setVolunteerType(event.value);
                      }}
                      defaultValue={
                        volunteerTypeOptions.filter(
                          (ar) =>
                            ar.value ===
                            eventInfo?.extendedProps?.volunteer_type
                        )[0]
                      }
                      options={volunteerTypeOptions}
                    />
                  </Col>
                </Row>
              ) : (
                <p>{`Volunteer Type : ${eventInfo?.extendedProps?.volunteer_type}`}</p>
              )}
              {isEdit ? (
                <Row>
                  <p>Schedule Type :</p> (
                  <Col sm="5" lg="5" xl="5" className="mb-3">
                    {" "}
                    <Select
                      className="basic-single"
                      styles={{ color: "black", width: "50%" }}
                      classNamePrefix="select"
                      isLoading={scheduleTypeOptions.length === 0}
                      onChange={(event) => {
                        setScheduleType(event.value);
                      }}
                      defaultValue={
                        scheduleTypeOptions.filter(
                          (ar) =>
                            ar.value === eventInfo?.extendedProps?.schedule_type
                        )[0]
                      }
                      options={scheduleTypeOptions}
                    />
                  </Col>
                </Row>
              ) : (
                <p>{`Schedule Type : ${eventInfo?.extendedProps?.schedule_type}`}</p>
              )}
              {isEdit ? (
                <Row className="mr-0 pr-0">
                  <p>Session :</p>{" "}
                  <div className="ml-20  mb-3 mr-2" style={{ width: "120px" }}>
                    {" "}
                    <Select
                      className="basic-single"
                      styles={{ color: "black", width: "50%" }}
                      classNamePrefix="select"
                      isLoading={sessionOptions.length === 0}
                      onChange={(event) => {
                        if (event.value === "morning") {
                          setScheduleSession("morning");
                          setStartTime(
                            moment()
                              .set("hour", 9)
                              .set("minute", 30)
                          );
                          setEndTime(
                            moment()
                              .set("hour", 12)
                              .set("minute", 0)
                          );
                        } else {
                          setScheduleSession("afternoon");
                          setStartTime(
                            moment()
                              .set("hour", 12)
                              .set("minute", 30)
                          );
                          setEndTime(
                            moment()
                              .set("hour", 17)
                              .set("minute", 0)
                          );
                        }
                      }}
                      defaultValue={
                        sessionOptions.filter(
                          (ar) => ar.value === eventInfo?.extendedProps?.session
                        )[0]
                      }
                      options={sessionOptions}
                    />
                  </div>
                  <div style={{ padding: "0", width: "90px" }}>
                    <Datetime
                      dateFormat={false}
                      timeFormat={"hh:mm a"}
                      closeOnSelect
                      initialValue={eventInfo?.extendedProps?.start_time}
                      onChange={(val) => {
                        setStartTime(val);
                      }}
                    />
                  </div>
                  <Form.Label
                    style={{
                      color: "#FFFFFF",
                      margin: "0px 5px",
                      paddingTop: "10px",
                    }}
                  >
                    <span>-</span>
                  </Form.Label>
                  <div
                    className=" mr-2"
                    style={{ padding: "0", width: "90px" }}
                  >
                    <Datetime
                      dateFormat={false}
                      timeFormat={"hh:mm a"}
                      closeOnSelect
                      initialValue={eventInfo?.extendedProps?.end_time}
                      onChange={(val) => {
                        setEndTime(val);
                      }}
                    />
                  </div>
                </Row>
              ) : (
                <p>{`Session : ${eventInfo?.extendedProps?.session === null ? '' : eventInfo?.extendedProps?.session} ${moment(
                  eventInfo?.extendedProps?.start_time
                ).format("hh:mm a")} - ${moment(
                  eventInfo?.extendedProps?.end_time
                ).format("hh:mm a")} `}</p>
              )}
              {isEdit ? (
                <Row>
                  <p>
                    Volunteer <br /> Location :
                  </p>{" "}
                  <Col sm="5" lg="5" xl="5" className="mb-3 ml-13">
                    {" "}
                    <Select
                      className="basic-single"
                      styles={{ color: "black", width: "50%" }}
                      classNamePrefix="select"
                      isLoading={volunteerLocationOptions.length === 0}
                      onChange={(event) => {
                        setVolunteerLocation(event.value);
                      }}
                      defaultValue={
                        volunteerLocationOptions.filter(
                          (ar) =>
                            ar.value === eventInfo?.extendedProps?.volunteer_loc
                        )[0]
                      }
                      options={volunteerLocationOptions}
                    />
                  </Col>
                </Row>
              ) : (
                <p>
                  Volunteer Location : {eventInfo?.extendedProps?.volunteer_loc}
                </p>
              )}

              {isEdit && volunteerLocation === "OTHER" && (
                <Row>
                  <p>Other Location :</p>{" "}
                  <Col sm="4" lg="4" xl="4" className="mb-3 ml-2">
                    {" "}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder={"Postal Code"}
                      onChange={(event) => setPostalCode(event.target.value)}
                    />
                  </Col>
                  <Col sm="4" lg="4" xl="4" className="mb-3 ml-0">
                    {" "}
                    <Form.Control
                      type="number"
                      name="unitNumber"
                      placeholder="Unit Number"
                      onChange={(event) => setUnitNumber(event.target.value)}
                    />
                  </Col>
                </Row>
              )}

              {isEdit ? null : (
                <p>{`Assigned Volunteer : ${eventInfo?.extendedProps?.assignedVolunteer}`}</p>
              )}
              {isEdit ? (
                <Row>
                  <p>
                    Max Number <br /> Volunteer :
                  </p>{" "}
                  <Col sm="5" lg="5" xl="5" className="mb-3 ml-7">
                    {" "}
                    <Form.Control
                      type="number"
                      name="numberVolunteer"
                      placeholder="0"
                      defaultValue={eventInfo?.extendedProps?.numberVolunteer}
                      onChange={(event) => {
                        setMaxVolunteer(event.target.value);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <p>{`Max Number Volunteer : ${eventInfo?.extendedProps?.numberVolunteer}`}</p>
              )}
            </div>
          </div>
          {isEdit ? (
            <div style={{ marginLeft: "60px", marginTop: "10px" }}>
              <Row>
                <Button
                  style={{ margin: "0 30px" }}
                  variant="danger"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{ margin: "0 30px" }}
                  variant="success"
                  onClick={() => handleEditEvent(eventInfo)}
                >
                  Save
                </Button>
              </Row>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <ModalConfirm
        open={modalConfirm.isShow}
        data={modalConfirm}
        onHide={() => setModalConfirm({ ...modalConfirm, isShow: false })}
        onConfirm={() => {
          setModalConfirm({ ...modalConfirm, isShow: false });
          actionDelete();
        }}
      />
    </div>
  );
}
