import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import { ProgrammeEventsTable } from './ProgrammeEventsTable';
// import { ProgrammeEventsFilter } from './ProgrammeEventsFilter';

export function ProgrammeEventsCard(){
  const [searchName, setSearchName] = useState('');
  return(
    <>
      <Card>
        <CardHeader>
          <div className="card-title">
            <h3 className="card-label" style={{minWidth: '9vw'}}>List Data</h3>
          </div>
        </CardHeader>
        {/* <ProgrammeEventsFilter searchName={searchName} setSearchName={(val)=>setSearchName(val)}/> */}
        <CardBody>
          <ProgrammeEventsTable searchName={searchName}/>
        </CardBody>
      </Card>
    </>
  )
}