import React, { useEffect } from "react";
import Header from "../../Donation/component/Navbar";
import TitleImage from "../../Donation/component/TitleImage";
import { CostumizeStepper } from "../../Signup/component/Stepper";
import Footer from "../../Donation/component/Footer";
import { FormDetailSignUp } from "../component/FormDetailSignUp";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function YourDetailsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <Header />
      <TitleImage
        image={toAbsoluteUrl("/media/ffth/banner-summary.jpeg")}
        title={"Your Details"}
      />
      <CostumizeStepper steps={6} />
      <FormDetailSignUp />
      <Footer />
    </div>
  );
}
