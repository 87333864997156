import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as authAction from './authAction';
import * as authRepo from '../../../datasource/authRepo';

function* authLogin(data) {
  try {
    const res = yield call(authRepo.apiLogin, data.payload);
    yield put(authAction.loginSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.loginError(err.response.data));
    } else {
      yield put(authAction.loginError(err));
    }
  }
}

function* forgotPassword(data) {
  try {
    const res = yield call(authRepo.apiForgot, data.payload);
    yield put(authAction.forgotSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.forgotError(err.response.data));
    } else {
      yield put(authAction.forgotError(err));
    }
  }
}

export default function* watchAuth() {
  yield all([
    takeLatest(actionType.AUTH.LOGIN_REQUEST, authLogin),
    takeLatest(actionType.AUTH.FORGOT_REQUEST, forgotPassword)
  ])
}