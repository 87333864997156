import React, { useState, useEffect } from "react";
import { Form, Button, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CSVReader from "react-csv-reader";
import BootstrapTable from "react-bootstrap-table-next";
import { ModalInfoVr } from "../modal/ModalInfoVr";
import { ModalConfirmVr } from "../modal/ModalConfirmVr";
import * as vrAction from "../redux/vrAction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Delete } from "@material-ui/icons";

export function UploadAssignedVolunteerForm({ idSession, vrType }) {
  const dispatch = useDispatch();
  const vrReducer = useSelector((state) => state.vr);
  const [uploadFile, setUploadFile] = useState(null);
  const [dataAssignedVolunteer, setDataAssignedVolunteer] = useState([]);
  const [actionButton, setActionButton] = useState(null);
  const [inputKey, setInputKey] = useState("");
  const [modalInfoVr, setModalInfoVr] = useState({
    show: false,
    type: null, //register || cancel || upload,
    message: "",
  });
  const [modalConfirmVr, setModalConfirmVr] = useState({
    show: false,
    message: "",
    type: null,
    onConfirm: () => null,
  });

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  useEffect(() => {
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrSessionUploadAssignedOrg !== null
    ) {
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "upload",
        message: "",
      });
      setTimeout(() => {
        resetFileInput();
        dispatch(vrAction.vrSessionUploadAssignedOrgReset());
        dispatch(vrAction.vrSessionDetailRequest({ Id: idSession }));
      }, 2000);
    }
    if (
      vrReducer.isLoading === false &&
      vrReducer.isError === false &&
      vrReducer.vrUploadAssignedOrg !== null
    ) {
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "upload",
        message: "",
      });
      setTimeout(() => {
        resetFileInput();
        dispatch(vrAction.vrUploadAssignedOrgReset());
        dispatch(vrAction.vrDetailRequest({ Id: idSession }));
      }, 2000);
    }

    if (vrType === "fp") {
      if (
        vrReducer.isLoading === false &&
        vrReducer.isError === false &&
        vrReducer.vrSessionDetail !== null
      ) {
        if (vrReducer?.vrSessionDetail?.data?.isRegistred) {
          if (vrReducer?.vrSessionDetail?.data?.assignedVolunteers.length > 0) {
            let tmpData = [
              ...vrReducer?.vrSessionDetail?.data?.assignedVolunteers,
            ]
              .map((item) => {
                if (item.Assignee_Status__c === "Accepted") {
                  return item;
                }
              })
              .filter((ar) => ar !== undefined);
            setDataAssignedVolunteer(tmpData);
          }
        }
      }
    } else if (vrType === "regular") {
      if (
        vrReducer.isLoading === false &&
        vrReducer.isError === false &&
        vrReducer.vrDetail !== null
      ) {
        if (vrReducer?.vrDetail?.data?.isRegistred) {
          if (vrReducer?.vrDetail?.data?.assignedVolunteer?.length > 0) {
            let tmpData = [...vrReducer?.vrDetail?.data?.assignedVolunteer]
              .map((item) => {
                if (item.Assignee_Status__c === "Accepted") {
                  return item;
                }
              })
              .filter((ar) => ar !== undefined);
            setDataAssignedVolunteer(tmpData);
          }
        }
      }
    }

    if (vrType === "fp") {
      if (
        vrReducer.isLoading === false &&
        vrReducer.isError === false &&
        vrReducer.vrSessionDeleteAssignedOrg !== null
      ) {
        dispatch(vrAction.vrSessionDeleteAssignedOrgReset());
        dispatch(vrAction.vrSessionDetailRequest({ Id: idSession }));
      }
    } else if (vrType === "regular") {
      if (
        vrReducer.isLoading === false &&
        vrReducer.isError === false &&
        vrReducer.vrDeleteAssignedOrg !== null
      ) {
        dispatch(vrAction.vrDeleteAssignedOrgReset());
        dispatch(vrAction.vrDetailRequest({ Id: idSession }));
      }
    }
  }, [vrReducer, vrType]);

  function onUploadFile() {
    if (uploadFile === null) {
      setModalInfoVr({
        ...modalInfoVr,
        show: true,
        type: "error",
        message: "Please select file first",
      });
    } else {
      let maxNumVolunteer;
      let numberVolunteer;
      let allDataAv;
      if (vrType === "fp") {
        maxNumVolunteer =
          vrReducer.vrSessionDetail?.data?.volunteerSession
            ?.Max_Num_of_Volunteer__c;
        numberVolunteer =
          vrReducer.vrSessionDetail?.data?.volunteerSession
            ?.Number_of_Sign_ups__c;
        allDataAv = [...vrReducer?.vrSessionDetail?.data?.assignedVolunteers];
      } else if (vrType === "regular") {
        maxNumVolunteer =
          vrReducer.vrDetail?.data?.volunteerRequest[0]
            ?.Number_of_Volunteers_Required__c;
        numberVolunteer = vrReducer.vrDetail?.data?.assignedVolunteer?.length;
        allDataAv = [...vrReducer?.vrDetail?.data?.assignedVolunteer];
      }
      let _uploadAv = uploadFile
        .map((item) => {
          if (
            allDataAv.filter(
              (ar) => ar?.Volunteer_Name__r?.Email === item.email
            ).length === 0
          ) {
            let _newData = {
              Fullname: item.fullname,
              Email: item.email,
              Mobile: item.mobile.toString(),
            };
            return _newData;
          }
        })
        .filter((ar) => ar !== undefined);
      let _updateAv = uploadFile
        .map((item) => {
          if (
            allDataAv.filter(
              (ar) => ar?.Volunteer_Name__r?.Email === item.email
            ).length > 0
          ) {
            let _newData = {
              Id: allDataAv.filter(
                (ar) => ar?.Volunteer_Name__r?.Email === item.email
              )[0].Id,
              contactId: allDataAv.filter(
                (ar) => ar?.Volunteer_Name__r?.Email === item.email
              )[0].Volunteer_Name__r.Id,
              Fullname: item.fullname,
              Mobile: item.mobile,
            };
            return _newData;
          }
        })
        .filter((ar) => ar !== undefined);
      let payload = {
        Id: idSession,
        upload: _uploadAv,
        update: _updateAv,
      };
      if (
        _uploadAv.length + _updateAv.length <=
        maxNumVolunteer - numberVolunteer
      ) {
        if (vrType === "fp") {
          dispatch(vrAction.vrSessionUploadAssignedOrgRequest(payload));
        } else if (vrType === "regular") {
          dispatch(vrAction.vrUploadAssignedOrgRequest(payload));
        }
      } else {
        setModalInfoVr({
          ...modalInfoVr,
          show: true,
          type: "error",
          message: "Your data exceed the maximum number of volunteer",
        });
      }
    }
  }

  function onDeleteVolunteer(idAv) {
    let payload;
    if (idAv !== "all") {
      payload = {
        assignedVolunteers: [idAv],
      };
    } else if (idAv === "all") {
      let arrDataAllAv = [...dataAssignedVolunteer].map((item) => {
        return item.Id;
      });
      payload = {
        assignedVolunteers: arrDataAllAv,
      };
    }
    if (vrType === "fp") {
      dispatch(vrAction.vrSessionDeleteAssignedOrgRequest(payload));
    } else if (vrType === "regular") {
      dispatch(vrAction.vrDeleteAssignedOrgRequest(payload));
    }
    resetFileInput();
  }

  const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <span>{cell === null ? "N/A" : cell}</span>;
  };

  const actionsColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <OverlayTrigger
          overlay={
            <Tooltip id="products-edit-tooltip">Delete Volunteer</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              setModalConfirmVr({
                ...modalConfirmVr,
                show: true,
                message: `Are you sure you want to remove this volunteer?`,
                onConfirm: () => {
                  onDeleteVolunteer(row.Id);
                },
              });
            }}
          >
            <Delete style={{ fill: "red" }} />
          </a>
        </OverlayTrigger>
      </div>
    );
  };

  const columns = [
    {
      dataField: vrType === "fp" ? "Volunteer_Name__r.Name" : "Contact_Name__c",
      text: "Name",
    },
    {
      dataField: vrType === "fp" ? "Volunteer_Name__r.Email" : "Email__c",
      text: "Email",
      formatter: nullColumnFormatter,
    },
    {
      dataField:
        vrType === "fp" ? "Volunteer_Name__r.MobilePhone" : "Mobile__c",
      text: "MobilePhone",
      formatter: nullColumnFormatter,
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      formatter: actionsColumnFormatter,
    },
  ];

  function resetFileInput() {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  }

  return (
    <>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={() => null}>
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#assigned-volunteer"
          >
            Assigned Volunteer
          </a>
        </li>
      </ul>
      <Form>
        <Form.Group as={Row} className={"mb-5 mt-0"}>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={false}
            remote
            keyField="Id"
            data={dataAssignedVolunteer}
            columns={columns}
            hover
          />
        </Form.Group>
        <Form.Group as={Row} className={"mb-5 mt-0"}>
          <Form.Label>
            Please kindly upload the participants attendance list 1 week before
            your session
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row} className={"mb-5 mt-0"}>
          <Form.Group className={"col-lg-12 mt-5 mb-0"}>
            <a
              href={toAbsoluteUrl("/media/ffth/ParticipantDatav2.csv")}
              style={{ maxWidth: 100, maxHeight: 50, fontSize: "15px" }}
            >
              Download Template
            </a>
          </Form.Group>
          <Form.Group className={"col-lg-12 mt-1"}>
            <CSVReader
              key={inputKey}
              cssClass="csv-reader-input"
              onFileLoaded={(data, fileInfo) => setUploadFile(data)}
              parserOptions={papaparseOptions}
              inputStyle={{ color: "black" }}
            />
          </Form.Group>
        </Form.Group>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          {dataAssignedVolunteer.length > 0 && (
            <Button
              onClick={() => {
                setModalConfirmVr({
                  ...modalConfirmVr,
                  show: true,
                  message: `Are you sure you want to remove all volunteer?`,
                  onConfirm: () => {
                    setActionButton("remove");
                    onDeleteVolunteer("all");
                  },
                });
              }}
              disabled={vrReducer.isLoading}
              variant="danger"
              className="mr-5"
            >
              Remove All
              {vrReducer.isLoading && actionButton === "remove" && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </Button>
          )}
          <Button
            onClick={() => {
              setActionButton("upload");
              onUploadFile();
            }}
            disabled={vrReducer.isLoading}
          >
            Upload
            {vrReducer.isLoading && actionButton === "upload" && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </div>
      </Form>
      <ModalInfoVr
        show={modalInfoVr.show}
        type={modalInfoVr.type}
        onHide={() =>
          setModalInfoVr({ ...modalInfoVr, show: false, message: "" })
        }
        message={modalInfoVr.message}
      />
      <ModalConfirmVr
        show={modalConfirmVr.show}
        message={modalConfirmVr.message}
        type={modalConfirmVr.type}
        onHide={() => setModalConfirmVr({ ...modalConfirmVr, show: false })}
        onConfirm={(val) => {
          setModalConfirmVr({ ...modalConfirmVr, show: false });
          modalConfirmVr.onConfirm(val);
        }}
      />
    </>
  );
}
