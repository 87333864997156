import React from "react";
import { Header } from "../../Upload/components/Navbar/Navbar";
import { InfoDetail } from "../Components/InfoDetail";
import "bootstrap/dist/css/bootstrap.min.css";

export function DonationDetailPage(props) {
  return (
    <>
      <Header />
      <InfoDetail />
    </>
  );
}
