import React, { useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as attendanceAction from "../redux/attendanceAction";

export function AttendanceTable({ UIEvent, searchName }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const attendanceReducer = useSelector(state => state.attendance, shallowEqual);

  const requestDataTableCallback = useCallback((page, per_page) => {
    dispatch(attendanceAction.attendanceTableRequest({
      name: searchName,
    }));
  }, [dispatch, searchName])

  useEffect(() => {
    requestDataTableCallback();
  }, [ requestDataTableCallback, searchName ])

  const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const datetimeColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell !== null ? moment(cell).tz("Asia/Singapore").format('DD/MM/YYYY, HH:mm') : 'N/A'}
      </span>
    )
  }
  
  const columns = [
    {
      dataField: "Name",
      text: "Attendance",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Request__r.Name",
      text: "Volunteer Request",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Request__r.RecordType.Name",
      text: "Volunteer Type",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Session__r.Session_Title__c",
      text: "Volunteer Session",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Checked_In__c",
      text: "Checked In",
      formatter: datetimeColumnFormatter
    },
    {
      dataField: "Checked_Out__c",
      text: "Checked Out",
      formatter: datetimeColumnFormatter
    },
    {
      dataField: "Checked_IN_OUT_Status__c",
      text: "Check In/Out Status",
      formatter: nullColumnFormatter
    }
  ];
  
  const paginationOptions = {
    custom: true,
    totalSize: 12,
    sizePerPageList: [
      { text: "10", value: 10 }
    ],
    sizePerPage: 10,
    page: 1,
  };

  function onTableChange(type, newState){
    console.log(type);
    console.log(newState);
    // let tmpMeta = {...tableContext.table.meta};
    // tmpMeta.page = newState.page;
    // tmpMeta.per_page = newState.sizePerPage;
    // tableContext.handleChangeField('meta', '', tmpMeta);
  }

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      history.push({
        pathname: `/attendance/detail/${row.Id}`,
        data: row
      })
    }
  };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={attendanceReducer.isLoading}
              paginationProps={paginationProps}
            > */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={attendanceReducer.attendanceTable === null ? [] : attendanceReducer.attendanceTable?.data?.attendances}
                columns={columns}
                hover
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={onTableChange}
                selectRow={selectRow}
                // {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
              </BootstrapTable>
              {
                attendanceReducer.attendanceTable === null &&
                <div>
                  Please wait...
                </div>  
              }
            {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}
