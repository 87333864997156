import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: null,
  validateVr: null,
  checkinVr: null,
  checkoutVr: null,
}

const detailVrRequest = state => ({
  ...state,
  isLoading: true
})
const detailVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: payload
})
const detailVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const detailVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: null
})

const validateVrRequest = state => ({
  ...state,
  isLoading: true
})
const validateVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateVr: payload
})
const validateVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const validateVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateVr: null
})

const checkinVrRequest = state => ({
  ...state,
  isLoading: true
})
const checkinVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinVr: payload
})
const checkinVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkinVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinVr: null
})

const checkoutVrRequest = state => ({
  ...state,
  isLoading: true
})
const checkoutVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutVr: payload
})
const checkoutVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkoutVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutVr: null
})

export const timesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TIMESHEET.DETAIL_VR_REQUEST:
      return detailVrRequest(state, action.payload);
    case actionType.TIMESHEET.DETAIL_VR_SUCCESS:
      return detailVrSuccess(state, action.payload);
    case actionType.TIMESHEET.DETAIL_VR_ERROR:
      return detailVrError(state, action.payload);
    case actionType.TIMESHEET.DETAIL_VR_RESET:
      return detailVrReset(state, action.payload);

    case actionType.TIMESHEET.VALIDATE_VR_REQUEST:
      return validateVrRequest(state, action.payload);
    case actionType.TIMESHEET.VALIDATE_VR_SUCCESS:
      return validateVrSuccess(state, action.payload);
    case actionType.TIMESHEET.VALIDATE_VR_ERROR:
      return validateVrError(state, action.payload);
    case actionType.TIMESHEET.VALIDATE_VR_RESET:
      return validateVrReset(state, action.payload);

    case actionType.TIMESHEET.CHECKIN_VR_REQUEST:
      return checkinVrRequest(state, action.payload);
    case actionType.TIMESHEET.CHECKIN_VR_SUCCESS:
      return checkinVrSuccess(state, action.payload);
    case actionType.TIMESHEET.CHECKIN_VR_ERROR:
      return checkinVrError(state, action.payload);
    case actionType.TIMESHEET.CHECKIN_VR_RESET:
      return checkinVrReset(state, action.payload);

    case actionType.TIMESHEET.CHECKOUT_VR_REQUEST:
      return checkoutVrRequest(state, action.payload);
    case actionType.TIMESHEET.CHECKOUT_VR_SUCCESS:
      return checkoutVrSuccess(state, action.payload);
    case actionType.TIMESHEET.CHECKOUT_VR_ERROR:
      return checkoutVrError(state, action.payload);
    case actionType.TIMESHEET.CHECKOUT_VR_RESET:
      return checkoutVrReset(state, action.payload);

    default:
      return state;
  }
}