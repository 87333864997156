import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl as FormControlMaterial,
  FormHelperText,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaArrowLeft } from "react-icons/fa";
import "./FormDetailSignUp.css";
import * as contactRepo from "../../../datasource/contactRepo";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily: "Museo Sans 700",
    fontSize: "12px",
    src: `
    url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
  `,
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "#FAFAFA",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#EEEEEE",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#FFD200",
    },
    "& .Mui-error": {
      color: "red",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontSize: "16px",
    },
  },
}));

const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    background: "#FAFAFA",
    width: "100%",
    fontFamily: "Museo Sans 700",
    fontSize: "12px",
    fontWeight: "bold",
    src: `
    url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
  `,
  },
  inputLabel: {
    fontFamily: "Museo Sans 700",
    src: `
    url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
  `,
    color: "#EEEEEE",
    fontSize: "12px",
    "&.Mui-focused": {
      color: "black",
    },
  },
  select: {
    color: "black",
    "&:before": {
      borderBottomColor: "#EEEEEE",
    },
    "&:hover": {
      borderBottomColor: "#EEEEEE",
    },
    "&:after": {
      borderBottomColor: "#FFD200",
    },
  },
  selectError: {
    color: "red",
    "&:before": {
      borderColor: "#FFD200",
      borderWidth: 2,
    },
    "&:hover": {
      borderColor: "#FFD200",
      borderWidth: 2,
    },
    "&:after": {
      borderColor: "#FFD200",
      borderWidth: 2,
    },
  },
}));

const helperTextStyles = makeStyles((theme) => ({
  root: {
    "&$error": {
      color: "red",
      fontSize: "16px",
      fontFamily: "Museo Sans 700",
    },
  },
  error: {}, //<--this is required to make it work
}));

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  fullname: Yup.string()
    .required("Field is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
  surname: Yup.string()
    .required("Field is required")
    .when("fullname", (fullname) => {
      if (fullname !== "") {
        return Yup.string()
          .required("Field is required")
          .test("surname", null, (val) => {
            if (fullname.includes(val)) {
              return true;
            } else {
              return new Yup.ValidationError(
                "Surname did not match with Full Name",
                null,
                "surname"
              );
            }
          });
      }
    }),
  orgName: Yup.string().when("volunteerType", {
    is: "org",
    then: Yup.string().required("Field is required"),
  }),
  email: Yup.string()
    .email()
    .required("Please provide a valid email."),
  phone: Yup.string()
    .matches(/^[689]\d{7}$/, "Please provide a valid phone number")
    .required("Field is required"),
  aboutUs: Yup.string().required("Field is required"),
  aboutUsDetail: Yup.string().when("aboutUs", {
    is: "Other",
    then: Yup.string().required("Field is required"),
  }),
  termsCondition: Yup.bool().oneOf([true], "Field must be checked"),
  codeOfConduct: Yup.bool().oneOf([true], "Field must be checked"),
});

export function FormDetailSignUp() {
  const history = useHistory();
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const classesHelperText = helperTextStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      volunteerType: "ind",
      orgName: "",
      title: "",
      fullname: "",
      surname: "",
      email: "",
      gender: "",
      dob: "",
      phone: "",
      address: "",
      postalCode: "",
      aboutUs: "",
      aboutUsDetail: "",
      remarks: "",
      isFoodPacking: false,
      isBreadRun: false,
      receiveUpdate: false,
      termsCondition: false,
      codeOfConduct: false,
    },
  });

  useEffect(() => {
    if (
      history.location.search.includes(
        "volunteer-area=prog%3Awarehouse-logistics"
      )
    ) {
      setValue("isFoodPacking", true);
    }
    if (
      history.location.search.includes(
        "volunteer-area=prog%3Abread+programme"
      ) &&
      (history.location.search.includes(
        "bread-programme-details=detail%3Abread+programme%3Aweekly"
      ) ||
        history.location.search.includes(
          "bread-programme-details=detail%3Abread+programme%3Astandby"
        ))
    ) {
      setValue("isBreadRun", true);
    }
  }, []);

  useEffect(() => {
    let title = watch("title");
    if (title === "Dr") {
      setValue("gender", "");
    } else if (title === "Mr") {
      setValue("gender", "Male");
    } else if (title === "Ms" || title === "Mdm") {
      setValue("gender", "Female");
    }
  }, [watch("title")]);

  function onSubmit(data) {
    let payload = {
      volunteerType:
        data.volunteerType === "ind" ? "individual" : "organisation",
      title: data.title,
      fullname: data.fullname,
      surname: data.surname,
      gender: data.gender,
      birthdate: data.dob,
      email: data.email,
      phone: data.phone,
      address: data.address,
      knowAboutUs: data.aboutUs === "Other" ? data.aboutUsDetail : data.aboutUs,
      remarks: data.remarks,
      receiveMonthlyNewsletter: data.receiveUpdate,
      agreeVolunteerCoC: data.codeOfConduct,
      agreeVolunteerTC: data.termsCondition,
      postalcode: data.postalCode,
      isBreadRun: data.isBreadRun,
      isFoodPacking: data.isFoodPacking,
    };
    if (data.volunteerType === "org") {
      payload.orgName = data.orgName;
    }
    console.log(payload);
    setIsLoading(true);
    contactRepo
      .apiRegisterContact(payload, history.location.search)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        history.push("/submission-success");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  return (
    <LoadingOverlay active={isLoading} spinner text="Connecting with server">
      <div className="container-form-detail">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-detail-signUp">
            <Row className="mb-10 ml-4">
              {" "}
              <h5
                className="donation-type"
                style={{
                  marginBottom: "20px",
                  fontWeight: "bold",
                  width: 150,
                }}
              >
                Volunteer Type:
              </h5>{" "}
              <Button
                className="buttonDetails"
                style={{
                  background: `${
                    watch("volunteerType") === "ind"
                      ? `#FFD200`
                      : "rgb(221, 221, 221)"
                  }`,
                  color: `#000000`,
                  border: "none",
                  marginLeft: "10px",
                }}
                onClick={() => setValue("volunteerType", "ind")}
              >
                <p>Individual</p>
              </Button>
              <Button
                className="buttonDetails"
                style={{
                  background: `${
                    watch("volunteerType") === "org"
                      ? `#FFD200`
                      : "rgb(221, 221, 221)"
                  }`,
                  color: `#000000`,
                  border: "none",
                  marginLeft: "10px",
                }}
                onClick={() => setValue("volunteerType", "org")}
              >
                <p>Organisation</p>
              </Button>
            </Row>
            <Row className="mb-10 ml-4">
              {" "}
              <h5
                className="donation-type"
                style={{
                  marginBottom: "20px",
                  fontWeight: "bold",
                  width: 150,
                  marginRight: "10px",
                }}
              >
                Event Type:
              </h5>{" "}
              {watch("volunteerType") === "ind" && (
                <Form.Group
                  className="mt-0 mb-0 ml-5 event-type"
                  controlId="formBasicCheckboxBreadRun"
                >
                  <Controller
                    name="isBreadRun"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Form.Check.Input
                          type={"checkbox"}
                          isValid
                          {...field}
                        />
                        <Form.Check.Label>
                          <span className="message-checkbox"> Bread Run</span>
                        </Form.Check.Label>
                      </>
                    )}
                  />
                </Form.Group>
              )}
              <Form.Group
                className="mt-0 mb-0 ml-10 event-type"
                controlId="formBasicCheckboxFoodPacking"
              >
                <Controller
                  name="isFoodPacking"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Form.Check.Input type={"checkbox"} isValid {...field} />
                      <Form.Check.Label>
                        <span className="message-checkbox"> Food Packing</span>
                      </Form.Check.Label>
                    </>
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col lg={4} xs={12} xl={4} className="mb-3">
                <FormControlMaterial className={classesSelect.formControl}>
                  <InputLabel
                    className={classesSelect.inputLabel}
                    style={{
                      color: errors.title ? "red" : "#AAAAAA",
                      fontFamily: "Museo Sans 700",
                      fontWeight: "500",
                    }}
                  >
                    TITLE *
                  </InputLabel>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors?.title
                            ? classesSelect.selectError
                            : classesSelect.select
                        }
                        fullWidth
                      >
                        <MenuItem value="Dr">Dr</MenuItem>
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Mdm">Mdm</MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText
                    classes={classesHelperText}
                    error={errors.title ? true : false}
                  >
                    {errors?.title?.message}
                  </FormHelperText>
                </FormControlMaterial>
              </Col>

              <Col lg={4} xs={12} xl={4} className="mb-3">
                <Controller
                  name="fullname"
                  style={{
                    fontWeight: "700",
                    color: "#aaaaaa",
                  }}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="filled-basic"
                      label="FULL NAME*"
                      variant="filled"
                      classes={classes}
                      error={errors.fullname ? true : false}
                      helperText={errors?.fullname?.message}
                      fullWidth
                    />
                  )}
                />
              </Col>

              <Col lg={4} xs={12} xl={4} className="mb-3">
                <Controller
                  name="surname"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      id="filled-basic"
                      label="SURNAME*"
                      variant="filled"
                      classes={classes}
                      error={errors.surname ? true : false}
                      helperText={errors?.surname?.message}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row className="mt-6 ">
              {watch("volunteerType") === "ind" ? (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        id="filled-basic"
                        label="EMAIL*"
                        variant="filled"
                        classes={classes}
                        error={errors.email ? true : false}
                        helperText={errors?.email?.message}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              ) : null}
              {watch("volunteerType") === "ind" ? (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <FormControlMaterial className={classesSelect.formControl}>
                    <InputLabel
                      className={classesSelect.inputLabel}
                      style={{ color: "#666666" }}
                    >
                      GENDER
                    </InputLabel>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={classesSelect.select}
                          fullWidth
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControlMaterial>
                </Col>
              ) : null}
              {watch("volunteerType") === "ind" ? (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="dob"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="filled-basic"
                        label="BIRTHDATE"
                        type="date"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        classes={classes}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              ) : null}
              {watch("volunteerType") === "org" && (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="orgName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        error={errors.orgName ? true : false}
                        id="filled-basic"
                        label="ORGANISATION NAME*"
                        variant="filled"
                        classes={classes}
                        helperText={errors?.orgName?.message}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              )}
              {watch("volunteerType") === "org" && (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        id="filled-basic"
                        label="EMAIL*"
                        variant="filled"
                        classes={classes}
                        error={errors.email ? true : false}
                        helperText={errors?.email?.message}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              )}
              {watch("volunteerType") === "org" && (
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        error={errors.phone ? true : false}
                        id="filled-basic"
                        label="PHONE*"
                        variant="filled"
                        classes={classes}
                        helperText={errors?.phone?.message}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              )}
            </Row>
            {watch("volunteerType") === "ind" ? (
              <Row className="mt-6">
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        error={errors.phone ? true : false}
                        id="filled-basic"
                        label="PHONE*"
                        variant="filled"
                        classes={classes}
                        helperText={errors?.phone?.message}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="filled-basic"
                        label="ADDRESS"
                        variant="filled"
                        classes={classes}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>

                <Col lg={4} xs={12} xl={4} className="mb-3">
                  <Controller
                    name="postalCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        id="filled-basic"
                        label="POSTAL CODE"
                        variant="filled"
                        classes={classes}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>
            ) : null}

            <Row className="mb-0 mt-3">
              <Col lg={6} xs={12} className="mb-2 mt-2 mb-3">
                <FormControlMaterial className={classesSelect.formControl}>
                  <InputLabel
                    className={classesSelect.inputLabel}
                    style={{ color: errors.aboutUs ? "red" : "#666666" }}
                  >
                    <span className="message-select">
                      {" "}
                      HOW DID YOU KNOW ABOUT US?*
                    </span>
                  </InputLabel>
                  <Controller
                    name="aboutUs"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.aboutUs
                            ? classesSelect.selectError
                            : classesSelect.select
                        }
                        fullWidth
                      >
                        <MenuItem value="Friends and Family">
                          Friends and Family
                        </MenuItem>
                        <MenuItem value="Social Media">Social Media</MenuItem>
                        <MenuItem value="News Paper,Online Article,Magazine,TV or Radio">
                          News Paper,Online Article,Magazine,TV or Radio
                        </MenuItem>
                        <MenuItem value="Roadshow">Roadshow</MenuItem>
                        <MenuItem value="Flyer">Flyer</MenuItem>
                        <MenuItem value="Online Search">Online Search</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText
                    classes={classesHelperText}
                    error={errors.aboutUs ? true : false}
                  >
                    {errors?.aboutUs?.message}
                  </FormHelperText>
                </FormControlMaterial>
              </Col>
              <Col lg={6} xs={12} className="mb-2 mt-2 mb-3">
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="filled-basic"
                      label="REMARKS"
                      variant="filled"
                      classes={classes}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
            {watch("aboutUs") === "Other" && (
              <Row className="mb-0 mt-3">
                <Col lg={6} xs={12} className="mb-2 mt-2 mb-3">
                  <Controller
                    name="aboutUsDetail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="filled-basic"
                        label="OTHER"
                        variant="filled"
                        classes={classes}
                        error={errors.aboutUsDetail ? true : false}
                        helperText={errors?.aboutUsDetail?.message}
                        fullWidth
                      />
                    )}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center"
              >
                <Form className="mt-5 mb-0">
                  {["checkbox"].map((newsletter) => (
                    <div key={newsletter}>
                      <Form.Check type={newsletter} id={`${newsletter}`}>
                        <Controller
                          name="receiveUpdate"
                          control={control}
                          render={({ field }) => (
                            <Form.Check.Input
                              type={newsletter}
                              isValid
                              {...field}
                            />
                          )}
                        />
                        <Form.Check.Label
                          style={{ color: " #666666" }}
                          className="message-checkbox"
                        >
                          I would like to receive Food from the Heart's monthly
                          newsletter.
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  ))}
                </Form>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center"
              >
                <Form className="mt-5 mb-0">
                  {["checkbox"].map((conduct) => (
                    <div key={conduct}>
                      <Form.Check type={conduct} id={`agree-${conduct}`}>
                        <Controller
                          name="codeOfConduct"
                          control={control}
                          render={({ field }) => (
                            <Form.Check.Input
                              type={conduct}
                              isValid
                              {...field}
                            />
                          )}
                        />
                        <Form.Check.Label
                          style={{ color: "#666666" }}
                          className="message-checkbox"
                        >
                          I agree to the Volunteer's Code of Conduct.
                          <a
                            href="https://www.foodfromtheheart.sg/assets/downloads/Code%20of%20Conduct%20Vetted%20310719.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#1d1e23",
                              borderBottom: "2px solid #ffd200",
                            }}
                          >
                            (review)
                          </a>
                        </Form.Check.Label>
                      </Form.Check>
                      {errors?.codeOfConduct && (
                        <Form.Label className="text-danger">
                          {errors?.codeOfConduct?.message}
                        </Form.Label>
                      )}
                    </div>
                  ))}
                </Form>
              </Col>
            </Row>
            <Row className="mb-20">
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center"
              >
                <Form className="mt-5 mb-0">
                  {["checkbox"].map((type) => (
                    <div key={type}>
                      <Form.Check type={type} id={`check-api-${type}`}>
                        <Controller
                          name="termsCondition"
                          control={control}
                          render={({ field }) => (
                            <Form.Check.Input type={type} isValid {...field} />
                          )}
                        />
                        <Form.Check.Label
                          style={{ color: "#666666" }}
                          className="message-checkbox"
                        >
                          I agree to the Terms & Conditions.
                          <a
                            href="https://www.foodfromtheheart.sg/assets/downloads/Terms%20&%20Conditions%20Vetted%20050819.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#1d1e23",
                              borderBottom: "2px solid #ffd200",
                            }}
                          >
                            (review)
                          </a>
                        </Form.Check.Label>
                      </Form.Check>
                      {errors?.termsCondition && (
                        <Form.Label className="text-danger">
                          {errors?.termsCondition?.message}
                        </Form.Label>
                      )}
                    </div>
                  ))}
                </Form>
              </Col>
            </Row>
            <Row className="mb-25">
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center"
              >
                <div
                  style={{
                    border: "2px solid #ffd200",
                    width: "46px",
                    height: "44px",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => history.goBack()}
                >
                  <FaArrowLeft
                    style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  />
                </div>
                <Button
                  type="submit"
                  className="button-submit"
                  style={{
                    border: "2px solid black",
                    width: "133px",
                    height: "44px",
                    borderRadius: "5px",
                    backgroundColor: "#ffd200",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    SUBMIT
                  </span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center cash-info"
              >
                <p className="cash-contribution">
                  {" "}
                  Unable to contribute your time? Make a{" "}
                  <a
                    href="https://helpnow.foodfromtheheart.sg/donate"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#1d1e23",
                      borderBottom: "2px solid #ffd200",
                    }}
                  >
                    cash contribution
                  </a>{" "}
                  instead.
                </p>
              </Col>
            </Row>
            <Row className="mt-10">
              <Col
                lg={12}
                xs={12}
                className="mb-2 mt-2 d-flex justify-content-center"
              >
                <Button
                  style={{
                    width: "86px",
                    height: "43px",
                    background: "#d62d00",
                  }}
                  variant="danger"
                  className="button-donate-btm"
                  onClick={() => history.push("/donate")}
                >
                  <span>DONATE</span>
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </LoadingOverlay>
  );
}
