import * as actionType from './actionType';

export const postalcodeRequest = data => ({
  type: actionType.CONTACT.POSTALCODE_REQUEST,
  payload: data
})
export const postalcodeSuccess = data => ({
  type: actionType.CONTACT.POSTALCODE_SUCCESS,
  payload: data
})
export const postalcodeError = data => ({
  type: actionType.CONTACT.POSTALCODE_ERROR,
  payload: data
})
export const postalcodeReset = data => ({
  type: actionType.CONTACT.POSTALCODE_RESET,
  payload: data
})

export const countryRequest = data => ({
  type: actionType.CONTACT.COUNTRY_REQUEST,
  payload: data
})
export const countrySuccess = data => ({
  type: actionType.CONTACT.COUNTRY_SUCCESS,
  payload: data
})
export const countryError = data => ({
  type: actionType.CONTACT.COUNTRY_ERROR,
  payload: data
})
export const countryReset = data => ({
  type: actionType.CONTACT.COUNTRY_RESET,
  payload: data
})