import React, {useState, useEffect} from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {get} from 'lodash'
import Swal from 'sweetalert2'
import * as donationRepo from "../../../../datasource/donationRepo";

export function ModalFilter({
  onHide,
  show,
  data,
  afterSubmit = () => {}
}) {
  const [formValue, setFormValue] = useState({})

  useEffect(() => {
    if(typeof data === 'object'){
      setFormValue(data)
    }
  }, [data])

  function changeValue(value, key){
    setFormValue(prevState => ({...prevState, [key]: value}))
  }

  function submitData(){
    let formData = {...formValue}
    let emptyValue = false
    let emptyObject = ''
    Object.keys(formData).forEach(key => {
      if(formData[key] === ''){
        emptyValue = true;
        emptyObject = key.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ');
        return
      }
    })

    if(emptyValue){
      Swal.fire(
        'Validation Error!',
        emptyObject+' Cant be Empty',
        'error'
      )
      return
    }else{
      let Id = formData._id;
      delete formData._id;
      delete formData.__v;
      updateData(Id, formData)
    }
  }

  async function updateData(id, data){
    try {
      await donationRepo.apiUpdateDonationUpload({data: data}, id)
      Swal.fire(
        'Data Updated!',
        'Data Success Updated!',
        'success'
      )
      afterSubmit()
      onHide()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {Object.keys(formValue).length > 0 && 
        <Row>
          {Object.keys(data).map((key, index) => (
            ['_id','__v','file_type'].includes(key) ? <></> :
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{key.split('_').map(x => x.charAt(0).toUpperCase() + x.substr(1)).join(' ')}</Form.Label>
                <Form.Control type="Text" placeholder="name@example.com" value={get(formValue, key,'')} onChange={(e) => changeValue(e.target.value, key)}/>
              </Form.Group>
            </Col>
          ))}
        </Row>
      }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submitData}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
