import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

export function ModalConfirmVr(props){
  const [reason, setReason] = useState('');

  function handleSubmit(event){
    event.preventDefault();
    props.onConfirm(reason)
  }

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header 
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <label style={{textAlign: 'center', display: 'flex'}}>Confirmation</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.message.split("\n").map(str => <p className={'text-center'}>{str}</p>)}
          {
            props.type === "organisation" &&
            <Form.Control 
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              placeholder="reason"
              required
            />
          }
        </Modal.Body>
        <Modal.Footer>
          <Row className={'col-lg-12 d-flex justify-content-center'}>
            <Col md='auto'>
              <Button type="submit" style={{minWidth: '10rem'}} variant="primary">Confirm</Button>
            </Col>
            <Col md='auto'>
              <Button onClick={props.onHide} style={{minWidth: '10rem'}} variant="danger">Cancel</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}