import React, { useEffect } from "react";
import {Modal, Button, Form} from "react-bootstrap";
import Select from "react-select";
import styled from 'styled-components';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import * as dashboardAction from "../../../../app/modules/Dashboard/redux/dashboardAction";
import { useDispatch, useSelector } from "react-redux";

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const validationSchema = Yup.object().shape({
  subject: Yup.string()
    .required('Field is required'),
  comment: Yup.string()
    .required('Field is required')
})

const styleSelect = {
  control: base => ({
    ...base,
    borderColor: 'red'
  })
};

export function ModalContactUs({show, handleClose}){
  const dispatch = useDispatch();
  const dashboardReducer = useSelector(state => state.dashboard);
  const optionContactUs = [
    { value: "Contact Us", label: "Contact Us"},
    { value: "Feedback", label: "Feedback"}
  ]
  const { register, handleSubmit, setError, setValue, formState: { errors }, control, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subject: '',
      comment: ''
    }
  });

  function onSubmit(value){
    dispatch(dashboardAction.contactUsRequest({
      subject: value.subject,
      comment: value.comment
    }))
  }
  

  useEffect(() => {
    if(dashboardReducer.isLoading === false && dashboardReducer.isError === false && dashboardReducer.contactUs !== null){
      dispatch(dashboardAction.contactUsReset());
      setValue("comment", "");
      setValue("subject", "");
      handleClose();
    }
  }, [dashboardReducer])

  return(
    <Modal 
      show={show}
      onHide={handleClose}
    >
      <CONTAINER>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => 
                  <Select 
                    onChange={(event) => {
                      if(event?.value){
                        setValue("subject", event.value)
                      }
                    }}
                    options={optionContactUs}
                    styles={errors.subject && styleSelect}
                    className="basic-single"
                    classNamePrefix="select"
                    value={optionContactUs.filter(ar => ar.label === watch("subject"))[0]}
                  />
                }
              />
              {
                errors.subject &&
                <Form.Text className={'text-danger'}>
                  {
                    errors.subject?.message
                  }
                </Form.Text>
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control 
                as="textarea"
                {...register("comment")}
                className={(errors.comment) && "error"}
              />
              {
                errors.comment &&
                <Form.Text className={'text-danger'}>
                  {
                    errors.comment?.message
                  }
                </Form.Text>
              }
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} style={{minWidth: 100}}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={dashboardReducer.isLoading} style={{minWidth: 100}}>
              Send
              {
                dashboardReducer.isLoading &&
                <span className="ml-3 spinner spinner-white"/>
              }
            </Button>
          </Modal.Footer>
        </Form>
      </CONTAINER>
    </Modal>
  )
}