import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from "react-redux";
import * as authAction from "../redux/authAction";
import jwt_decode from "jwt-decode";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Field is required')
    .email('Wrong email format'),
  password: Yup.string()
    .required('Field is required')
})

export default function Login() {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const { register, handleSubmit, setError, formState: { errors }, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    if(authReducer.isLoading === false && authReducer.isError === false && authReducer.loginInfo !== null){
      let accessToken = authReducer.loginInfo?.data?.accessToken;
      let decodeToken = jwt_decode(accessToken);
      let user = {
        Id: decodeToken.Id,
        email: decodeToken.Email,
        firstname: decodeToken.Name,
        lastname: '',
        role: decodeToken.userType,
        mobilephone: decodeToken.MobilePhone,
        volunteerType: decodeToken.volunteerType === "Individual" ? 'individual' : 'organisation'
      }
      dispatch(authAction.setCredential({
        authToken: accessToken,
        user: user
      }))
    } else if(authReducer.isLoading === false && authReducer.isError === true){
      dispatch(authAction.loginReset());
      setError("password", {
        type: "manual",
        message: authReducer.errorMsg.error
      });
    }
  }, [authReducer])

  function onSubmit(values){
    dispatch(authAction.loginRequest({
      Email: values.email,
      Password: values.password
    }))
  }

  return (
    <div
      className="login-form login-signin shadow-sm p-3 mb-5 bg-body rounded"
      id="kt_login_signin_form"
      style={{
        width: "350px",
        background: "#dddd",
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      <div className="text-center mb-5 mb-lg-0">
        <Image
          src={`${toAbsoluteUrl("/media/ffth/logo.png")}`}
          fluid
          style={{ height: 75, marginTop: 20, maxHeight: '50px', marginBottom: 20 }}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-300p py-5 px-6 ${errors?.email && 'is-invalid'}`}
            name="email"
            {...register("email")}
          />
          { errors?.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors?.email?.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-300p py-5 px-6 ${errors?.password && 'is-invalid'}`}
            name="password"
            {...register("password")}
          />
          { errors?.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors?.password?.message}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-0">
          <div>
            <Link
              to="/auth/forgot-password"
              className="text-black-50 text-hover-danger my-3 mr-2"
              id="kt_login_forgot"
            >
              Forgot Password
            </Link>
            <br/>
          </div>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={authReducer.isLoading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {authReducer.isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}
