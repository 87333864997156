import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as contactAction from './contactAction';
import * as contactRepo from '../../../datasource/contactRepo';

function* getPostalcode(data){
  try {
    const res = yield call(contactRepo.apiPostalcode, data.payload);
    yield put(contactAction.postalcodeSuccess(res));
  } catch(err){
    yield put(contactAction.postalcodeError(err));
  }
}

function* getCountry(data){
  try {
    const res = yield call(contactRepo.apiCountry, data.payload);
    yield put(contactAction.countrySuccess(res));
  } catch(err){
    yield put(contactAction.countryError(err));
  }
}

export default function* watchContact() {
  yield all([
    takeLatest(actionType.CONTACT.POSTALCODE_REQUEST, getPostalcode),
    takeLatest(actionType.CONTACT.COUNTRY_REQUEST, getCountry)
  ])
}